import React from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
// import mhz from '../assets/bid.png';
import gh from "../assets/godhand.jpg";
// import nova from '../assets/nova.png';
// import erdem from '../assets/erdem.svg';
// import tmll from '../assets/tmll.svg';
// import available from '../assets/bolomjtoi.svg';
// import unavailable from '../assets/bolomjgui.svg';
// import availableb from '../assets/bolomjtoib.svg';
// import unavailableb from '../assets/bolomjguib.svg';
// import sbdbg from '../assets/sbd.png';
// import sat from '../assets/sat.png';
// import ielts from '../assets/ielts.png';
// import topik from '../assets/topik.png';
import todo from "../assets/todo.svg";
import { useContext } from "react";
export const Todolist = () => {
  const mergejil = [];

  useEffect(() => {
    document.title = "Tmull ・ To Do List";
    const favicon = document.getElementById("favicon");
    favicon.setAttribute("href", todo);
  }, []);
  return (
    <div className="mt-24 lg:mt-28 dark:text-white">
      <div className="flex flex-col items-center justify-center my-16 ">
        <div className="font-semibold flex flex-row items-center justify-center mb-4">
          <div className="absolute">
            <svg
              width="268"
              height="102"
              viewBox="0 0 268 102"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M226.911 42.2033C227.325 42.354 227.783 42.1406 227.934 41.7267C228.084 41.3128 227.871 40.8551 227.457 40.7045C224.639 39.6787 223.186 36.5625 224.211 33.7442L224.231 33.6906C224.381 33.2767 224.168 32.8191 223.754 32.6684C223.34 32.5178 222.883 32.7312 222.732 33.1451C221.695 35.993 218.502 37.4451 215.654 36.4086C215.24 36.2579 214.782 36.4713 214.632 36.8852C214.481 37.2991 214.695 37.7568 215.109 37.9074L215.187 37.936C218.016 38.9657 219.475 42.0939 218.445 44.923C218.295 45.3369 218.508 45.7946 218.922 45.9452C219.336 46.0959 219.793 45.8824 219.944 45.4685C220.97 42.6503 224.093 41.1776 226.911 42.2033Z"
                fill="#4BAB4D"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M71.9715 25.7442C72.4591 25.4849 72.6442 24.8794 72.385 24.3918C72.1257 23.9042 71.5202 23.719 71.0325 23.9783C67.7122 25.7438 63.5894 24.4833 61.824 21.163L61.7904 21.0998C61.5311 20.6122 60.9256 20.427 60.438 20.6863C59.9504 20.9456 59.7652 21.5511 60.0245 22.0387C61.8085 25.3939 60.4821 29.5881 57.1269 31.3721C56.6392 31.6314 56.4541 32.2369 56.7134 32.7245C56.9727 33.2122 57.5782 33.3973 58.0658 33.138L58.1584 33.0887C61.4916 31.3165 65.6304 32.5818 67.4026 35.915C67.6619 36.4026 68.2674 36.5877 68.7551 36.3285C69.2427 36.0692 69.4278 35.4637 69.1685 34.976C67.4031 31.6558 68.6512 27.5097 71.9715 25.7442Z"
                fill="#4BAB4D"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M44.2909 84.1501C44.5777 84.983 45.4854 85.4258 46.3183 85.139C47.1512 84.8522 47.594 83.9444 47.3072 83.1115L46.3758 80.4066C44.9373 76.229 47.1578 71.6764 51.3354 70.2379L54.1481 69.2694C54.981 68.9826 55.4237 68.0749 55.1369 67.242C54.8501 66.4091 53.9424 65.9663 53.1095 66.2531L50.2968 67.2216C46.1192 68.6601 41.5666 66.4396 40.1281 62.262L39.1287 59.3594C38.8419 58.5265 37.9341 58.0837 37.1012 58.3705C36.2683 58.6573 35.8255 59.5651 36.1123 60.398L37.1118 63.3006C38.5502 67.4782 36.3298 72.0308 32.1522 73.4693L29.4076 74.4143C28.5747 74.7011 28.1319 75.6089 28.4187 76.4418C28.7055 77.2747 29.6133 77.7174 30.4462 77.4306L33.1908 76.4856C37.3684 75.0472 41.921 77.2676 43.3595 81.4452L44.2909 84.1501Z"
                fill="#4BAB4D"
              />
            </svg>
          </div>
          <div className="text-xl md:text-2xl lg:text-3xl transition-all ease-in-out duration-300 delay-0 transform">
            To Do List
          </div>
        </div>
        <div class="text-lg lg:text-xl text-[#7c7c7c] dark:text-[#c6c6c6] text-center transition-all ease-in-out duration-300 delay-0 transform font-semibold">
          Cанал болгосон
        </div>
        <div className="flex flex-wrap w-full lg:flex-row justify-evenly m-4 transition-all ease-in-out duration-300 delay-0 transform">
          <a
            href="/noots/hiih_zuils/0"
            className="w-full h-16 rounded-lg border-2 flex justify-center items-center transition delay-150 duration-500 ease-in-out bg-repeat todo hover:bg-white hover:text-black bg-black text-white cursor-pointer border-black my-2"
          >
            <div className="text-md lg:text-xl font-bold xl:text-2xl text text-center drop-shadow-lg">
              10 ба түүнээс доош
            </div>
          </a>
          <a
            href="/noots/hiih_zuils/1"
            className="w-full h-16 rounded-lg border-2 flex justify-center items-center transition delay-150 duration-500 ease-in-out bg-repeat todo1 hover:bg-white hover:text-black bg-black text-white cursor-pointer border-black my-2"
          >
            <div className="text-md lg:text-xl font-bold xl:text-2xl text-center drop-shadow-lg">
              11-р анги
            </div>
          </a>
          <a
            href="/noots/hiih_zuils/2"
            className="w-full h-16 rounded-lg border-2 flex justify-center items-center transition delay-150 duration-500 ease-in-out bg-repeat todo hover:bg-white hover:text-black bg-black text-white cursor-pointer border-black my-2"
          >
            <div className="text-md lg:text-xl  font-bold xl:text-2xl text-center drop-shadow-lg">
              12-р анги
            </div>
          </a>
          <a
            href="/noots/hiih_zuils/3"
            className="w-full h-16 rounded-lg border-2 flex justify-center items-center transition delay-150 duration-500 ease-in-out bg-repeat todo1 hover:bg-white hover:text-black bg-black text-white cursor-pointer border-black my-2"
          >
            <div className="text-md lg:text-xl  font-bold xl:text-2xl text-center drop-shadow-lg">
              Завсар жил
            </div>
          </a>
        </div>
      </div>
      <div>
        <div className="text-xl md:text-2xl lg:text-3xl transition-all ease-in-out duration-300 delay-0 transform text-center">
          Хувийн менежмент болон хийх <br />
          зүйлсийн жагсаалтыг яг одоо бүтээх
        </div>
        <div class="text-lg lg:text-xl text-[#7c7c7c] dark:text-[#c6c6c6] text-center transition-all ease-in-out duration-300 delay-0 transform font-semibold">
          Таны ажил амжилтыг хялбар аргаар удирдахад загварчлагдсан
        </div>
      </div>
      {/* <div className="space-y-3 font-semibold flex justify-center flex-col items-center w-full mt-16 md:text-lg lg:text-xls">
        <div className="font-bold text-xl md:text-2xl lg:text-3xl ">
          Таны хийх зүйлсийн жагсаалт
        </div>

        <div className="w-full space-y-1 lg:space-y-2 flex flex-col items-center">
          <div className="flex flex-row justify-between items-center gap-3 w-full lg:w-[60%]">
            <div className="peer flex items-center gap-3 group/item rounded-md px-2 hover:bg-[#4BAB4D] h-12 w-full transition delay-0 duration-300 ease-in">
              <div class="relative flex items-center rounded-full cursor-pointer peer">
                <input
                  type="checkbox"
                  class="before:content[''] peer relative h-8 w-8 lg:h-10 lg:w-10 cursor-pointer appearance-none rounded-full border border-gray-900/20 bg-gray-900/10 dark:bg-gray-100/30 transition-all before:absolute before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#4BAB4D] checked:bg-[#4BAB4D] dark:checked:bg-[#4BAB4D] checked:before:bg-[#4BAB4D] hover:scale-105 hover:before:opacity-0"
                />
                <div class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 lg:h-8"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>
              <div className="select-none group-hover/item:!text-white transition delay-0 duration-300 ease-in peer-has-[:checked]:text-[#4BAB4D] peer-has-[:checked]:line-through">
                Check off first item
              </div>
            </div>
            <div className="rounded-md p-1 hover:bg-red-50 dark:hover:bg-red-50/10 hover:text-red-500 peer-has-[:checked]:hidden transition delay-0 duration-300 ease-in">
              <svg
                className="h-8 w-8 lg:h-10 lg:w-10"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </div>
          </div>
          <div className="flex flex-row justify-between items-center gap-3 w-full lg:w-[60%]">
            <div className="peer flex items-center gap-3 group/item rounded-md px-2 hover:bg-[#4BAB4D] h-12 w-full transition delay-0 duration-300 ease-in">
              <div class="relative flex items-center rounded-full cursor-pointer peer">
                <input
                  type="checkbox"
                  class="before:content[''] peer relative h-8 w-8 lg:h-10 lg:w-10 cursor-pointer appearance-none rounded-full border border-gray-900/20 bg-gray-900/10 dark:bg-gray-100/30 transition-all before:absolute before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#4BAB4D] checked:bg-[#4BAB4D] dark:checked:bg-[#4BAB4D] checked:before:bg-[#4BAB4D] hover:scale-105 hover:before:opacity-0"
                />
                <div class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 lg:h-8"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>
              <div className="select-none group-hover/item:!text-white transition delay-0 duration-300 ease-in peer-has-[:checked]:text-[#4BAB4D] peer-has-[:checked]:line-through">
                Check off first item
              </div>
            </div>
            <div className="rounded-md p-1 hover:bg-red-50 dark:hover:bg-red-50/10 hover:text-red-500 peer-has-[:checked]:hidden transition delay-0 duration-300 ease-in">
              <svg
                className="h-8 w-8 lg:h-10 lg:w-10"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </div>
          </div>
          <div className="flex flex-row justify-between items-center gap-3 w-full lg:w-[60%]">
            <div className="peer flex items-center gap-3 group/item rounded-md px-2 hover:bg-[#4BAB4D] h-12 w-full transition delay-0 duration-300 ease-in">
              <div class="relative flex items-center rounded-full cursor-pointer peer">
                <input
                  type="checkbox"
                  class="before:content[''] peer relative h-8 w-8 lg:h-10 lg:w-10 cursor-pointer appearance-none rounded-full border border-gray-900/20 bg-gray-900/10 dark:bg-gray-100/30 transition-all before:absolute before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#4BAB4D] checked:bg-[#4BAB4D] dark:checked:bg-[#4BAB4D] checked:before:bg-[#4BAB4D] hover:scale-105 hover:before:opacity-0"
                />
                <div class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 lg:h-8"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>
              <div className="select-none group-hover/item:!text-white transition delay-0 duration-300 ease-in peer-has-[:checked]:text-[#4BAB4D] peer-has-[:checked]:line-through">
                Check off first item
              </div>
            </div>
            <div className="rounded-md p-1 hover:bg-red-50 dark:hover:bg-red-50/10 hover:text-red-500 peer-has-[:checked]:hidden transition delay-0 duration-300 ease-in">
              <svg
                className="h-8 w-8 lg:h-10 lg:w-10"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </div>
          </div>
          <div className="flex flex-row justify-between items-center gap-3 w-full lg:w-[60%]">
            <div className="peer flex items-center gap-3 group/item rounded-md px-2 hover:bg-[#4BAB4D] h-12 w-full transition delay-0 duration-300 ease-in">
              <div class="relative flex items-center rounded-full cursor-pointer peer">
                <input
                  type="checkbox"
                  class="before:content[''] peer relative h-8 w-8 lg:h-10 lg:w-10 cursor-pointer appearance-none rounded-full border border-gray-900/20 bg-gray-900/10 dark:bg-gray-100/30 transition-all before:absolute before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#4BAB4D] checked:bg-[#4BAB4D] dark:checked:bg-[#4BAB4D] checked:before:bg-[#4BAB4D] hover:scale-105 hover:before:opacity-0"
                />
                <div class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 lg:h-8"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>
              <div className="select-none group-hover/item:!text-white transition delay-0 duration-300 ease-in peer-has-[:checked]:text-[#4BAB4D] peer-has-[:checked]:line-through">
                Check off first item
              </div>
            </div>
            <div className="rounded-md p-1 hover:bg-red-50 dark:hover:bg-red-50/10 hover:text-red-500 peer-has-[:checked]:hidden transition delay-0 duration-300 ease-in">
              <svg
                className="h-8 w-8 lg:h-10 lg:w-10"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </div>
          </div>
          <div className="flex flex-row justify-between items-center gap-3 w-full lg:w-[60%]">
            <div className="peer flex items-center gap-3 group/item rounded-md px-2 hover:bg-[#4BAB4D] h-12 w-full transition delay-0 duration-300 ease-in">
              <div class="relative flex items-center rounded-full cursor-pointer peer">
                <input
                  type="checkbox"
                  class="before:content[''] peer relative h-8 w-8 lg:h-10 lg:w-10 cursor-pointer appearance-none rounded-full border border-gray-900/20 bg-gray-900/10 dark:bg-gray-100/30 transition-all before:absolute before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#4BAB4D] checked:bg-[#4BAB4D] dark:checked:bg-[#4BAB4D] checked:before:bg-[#4BAB4D] hover:scale-105 hover:before:opacity-0"
                />
                <div class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 lg:h-8"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>
              <div className="select-none group-hover/item:!text-white transition delay-0 duration-300 ease-in peer-has-[:checked]:text-[#4BAB4D] peer-has-[:checked]:line-through">
                Check off first item
              </div>
            </div>
            <div className="rounded-md p-1 hover:bg-red-50 dark:hover:bg-red-50/10 hover:text-red-500 peer-has-[:checked]:hidden transition delay-0 duration-300 ease-in">
              <svg
                className="h-8 w-8 lg:h-10 lg:w-10"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </div> */}
      <div className="w-full hidden mt-16 justify-center items-center flex-col rounded-lg bg-[#54545428] py-2">
        <div className="font-semibold flex flex-row items-center justify-center p-2 lg:w-[60%] text-center">
          <div className="text-xl md:text-2xl lg:text-3xl">
            Хийх зүйлсийн жагсаалттай холбоотой мэдээллүүд
          </div>
        </div>
        <div className="flex flex-row overflow-x-auto gap-4 p-2 w-full">
          <div>
            <div className="w-80 h-80 lg:w-96 lg:h-96 rounded-lg relative ring-2 ring-black m-2 bg-white dark:bg-black">
              <img
                className="w-80 h-60 lg:w-96 lg:h-72 object-cover rounded-lg absolute"
                src={gh}
                alt=""
              />
              <div className="absolute bottom-0 p-2 w-full rounded-lg">
                <div className="font-bold text-xl md:text-2xl">
                  Title/Heading
                </div>
                <div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
                  Date | Author
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="w-80 h-80 lg:w-96 lg:h-96 rounded-lg relative ring-2 ring-black m-2 bg-white dark:bg-black">
              <img
                className="w-80 h-60 lg:w-96 lg:h-72 object-cover rounded-lg absolute"
                src={gh}
                alt=""
              />
              <div className="absolute bottom-0 p-2 w-full  rounded-lg">
                <div className="font-bold text-xl md:text-2xl">
                  Title/Heading
                </div>
                <div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
                  Date | Author
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="w-80 h-80 lg:w-96 lg:h-96 rounded-lg relative ring-2 ring-black m-2 bg-white dark:bg-black">
              <img
                className="w-80 h-60 lg:w-96 lg:h-72 object-cover rounded-lg absolute"
                src={gh}
                alt=""
              />
              <div className="absolute bottom-0 p-2 w-full  rounded-lg">
                <div className="font-bold text-xl md:text-2xl">
                  Title/Heading
                </div>
                <div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
                  Date | Author
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="w-80 h-80 lg:w-96 lg:h-96 rounded-lg relative ring-2 ring-black m-2 bg-white dark:bg-black">
              <img
                className="w-80 h-60 lg:w-96 lg:h-72 object-cover rounded-lg absolute"
                src={gh}
                alt=""
              />
              <div className="absolute bottom-0 p-2 w-full  rounded-lg">
                <div className="font-bold text-xl md:text-2xl">
                  Title/Heading
                </div>
                <div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
                  Date | Author
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="w-80 h-80 lg:w-96 lg:h-96 rounded-lg relative ring-2 ring-black m-2 bg-white dark:bg-black">
              <img
                className="w-80 h-60 lg:w-96 lg:h-72 object-cover rounded-lg absolute"
                src={gh}
                alt=""
              />
              <div className="absolute bottom-0 p-2 w-full  rounded-lg">
                <div className="font-bold text-xl md:text-2xl">
                  Title/Heading
                </div>
                <div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
                  Date | Author
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="w-80 h-80 lg:w-96 lg:h-96 rounded-lg relative ring-2 ring-black m-2 bg-white dark:bg-black">
              <img
                className="w-80 h-60 lg:w-96 lg:h-72 object-cover rounded-lg absolute"
                src={gh}
                alt=""
              />
              <div className="absolute bottom-0 p-2 w-full  rounded-lg">
                <div className="font-bold text-xl md:text-2xl">
                  Title/Heading
                </div>
                <div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
                  Date | Author
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="w-80 h-80 lg:w-96 lg:h-96 rounded-lg relative ring-2 ring-black m-2 bg-white dark:bg-black">
              <img
                className="w-80 h-60 lg:w-96 lg:h-72 object-cover rounded-lg absolute"
                src={gh}
                alt=""
              />
              <div className="absolute bottom-0 p-2 w-full  rounded-lg">
                <div className="font-bold text-xl md:text-2xl">
                  Title/Heading
                </div>
                <div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
                  Date | Author
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
