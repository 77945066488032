import React from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
// import mhz from '../assets/bid.png';
import gh from "../assets/godhand.jpg";
import nova from "../assets/nova.png";
// import erdem from '../assets/erdem.svg';
// import tmll from '../assets/tmll.svg';
// import available from '../assets/bolomjtoi.svg';
// import unavailable from '../assets/bolomjgui.svg';
// import availableb from '../assets/bolomjtoib.svg';
// import unavailableb from '../assets/bolomjguib.svg';
// import sbdbg from '../assets/sbd.png';
// import sat from '../assets/sat.png';
// import ielts from '../assets/ielts.png';
// import topik from '../assets/topik.png';
import axios from "axios";

export const Extracurricular = () => {
  const mergejil = [
    {
      id: 1,
      ner: "./career/careers_people_man_accountant_finance_skin3.svg",
      ajil: "нягтлан бодогч",
    },
    {
      id: 2,
      ner: "./career/careers_people_man_dentist_skin3.svg",
      ajil: "шүдний эмч",
    },
    {
      id: 3,
      ner: "./career/careers_people_man_lab_tech_laboratory_technician_skin3.svg",
      ajil: "лабораторийн техникч",
    },
    {
      id: 4,
      ner: "./career/careers_people_man_marketing_manager_skin3.svg",
      ajil: "маркетинг",
    },
    {
      id: 5,
      ner: "./career/careers_people_man_medical_nurse_skin3.svg",
      ajil: "сувилагч",
    },
    {
      id: 6,
      ner: "./career/careers_people_man_paramedic_skin3.svg",
      ajil: "түргэн тусламж",
    },
    {
      id: 7,
      ner: "./career/careers_people_man_sales_finance_manager_skin3.svg",
      ajil: "Санхүүгийн менежер",
    },
    {
      id: 8,
      ner: "./career/careers_people_man_senior_librarian_skin3.svg",
      ajil: "номын санч",
    },
    {
      id: 9,
      ner: "./career/careers_people_man_software_developer_skin3.svg",
      ajil: "хөгжүүлэгч",
    },
    {
      id: 10,
      ner: "./career/careers_people_man_teacher_professor_skin3.svg",
      ajil: "багш",
    },
    {
      id: 11,
      ner: "./career/careers_people_woman_art_director_skin3.svg",
      ajil: "Урлан бүтээгч",
    },
    {
      id: 12,
      ner: "./career/careers_people_woman_engineer_architect_skin3.svg",
      ajil: "архитекторч",
    },
    {
      id: 13,
      ner: "./career/careers_people_woman_executive_director_manager_skin3.svg",
      ajil: "менежер",
    },
    {
      id: 14,
      ner: "./career/careers_people_woman_hr_human_resources_manager_skin3.svg",
      ajil: "Хүний нөөцийн менежер",
    },
    {
      id: 15,
      ner: "./career/careers_people_woman_lawyer_skin3.svg",
      ajil: "хуульч",
    },
    {
      id: 16,
      ner: "./career/careers_people_woman_medical_doctor_skin3.svg",
      ajil: "эмч",
    },
    {
      id: 17,
      ner: "./career/careers_people_woman_professor_teacher_skin3.svg",
      ajil: "профессор",
    },
    {
      id: 18,
      ner: "./career/careers_people_woman_public_relations_specialist_skin3.svg",
      ajil: "олон нийтийн харилцааны мэргэжилтэн",
    },
    {
      id: 19,
      ner: "./career/careers_people_woman_senior_psychologist_skin3.svg",
      ajil: "сэтгэл зүйч",
    },
    {
      id: 20,
      ner: "./career/careers_people_woman_vet_veterinarian_skin3.svg",
      ajil: "малын эмч",
    },
    {
      id: 21,
      ner: "./career/und.svg",
      ajil: "шийдэгдээгүй",
    },
  ];
  const colors = [
    "#994C8E",
    "#E09626",
    "#4BAB4D",
    "#5A9FDE",
    "#556EB0",
    "#6FAEA9",
  ];
  const [event, setEvent] = useState([]);
  const [prof, setProf] = useState([]);
  useEffect(() => {
    document.title = "Tmull ・ Хичээлээс гадуурх үйл ажиллагаанууд";
    const event = async () => {
      await axios.get(`https://tmull-be.vercel.app/event`).then((response) => {
        console.log(response?.data);
        setEvent(response?.data);
      });
    };
    const prof = async () => {
      await axios.get(`https://tmull-be.vercel.app/prof`).then((response) => {
        console.log(response?.data);
        setProf(response?.data);
      });
    };
    event();
    prof();
  }, []);
  const right = () => {
    document.getElementById("content").scrollBy(1500, 0);
  };
  const left = () => {
    document.getElementById("content").scrollBy(-1500, 0);
  };
  return (
    <div className="font-semibold flex justify-center items-center flex-col mt-24 lg:mt-28 dark:text-white">
      <div className="font-semibold flex flex-row items-center justify-center p-2 lg:w-[60%] text-center">
        <div className="text-xl md:text-2xl lg:text-3xl dark:text-white">
          Чиглэл болгонтой холбоотой хичээлээс гадуурх үйл ажиллагаанууд
        </div>
      </div>
      <div className="flex flex-row w-full xl:flex-wrap xl:justify-center items-center my-4 overflow-y-auto relative xl:h-[50vh] 2xl:h-full dark:text-white">
        {prof.map((el, key) => {
          // console.log(el);
          return (
            <div
              onClick={() => {
                window.location.pathname = `/sain_duriin_ajil/${el._id}`;
              }}
              className="flex justify-center items-center group/item cursor-pointer p-8"
            >
              <div
                key={key}
                className="rounded-2xl w-32 h-32 ring-4 ring-[#656366] rotate-45 group-hover/item:rotate-0 group-hover/item:ring-[#4BAB4D] transition delay-150 duration-500 ease-in-out"
              ></div>
              <div className="h-32 w-32 flex items-end justify-end capitalize font-semibold transition-all ease-in-out duration-500 absolute opacity-25 group-hover/item:rotate-0 rotate-45 z-0 delay-150 group-hover/item:z-10 text-right group-hover/item:opacity-100 drop-shadow">
                <div class="rounded-2xl px-2 backdrop-blur-sm bg-[#ffffff60] dark:bg-[#00000060] w-full">
                  {el.na}
                </div>
              </div>
              <img
                src={el.img}
                className="w-24 h-24 absolute mb-6 group-hover/item:mb-0 group-hover/item:grayscale-0 transition-all delay-150 duration-500 ease-in-out grayscale "
                alt=""
              />
            </div>
          );
        })}
      </div>
      <div class="flex flex-col items-center justify-center my-8 lg:my-16 w-full dark:text-white">
        <div
          onClick={() => {
            window.location.pathname = "/noots/uil_ajillagaa";
          }}
          class="font-semibold flex flex-col items-center justify-center mb-4 w-full relative"
        >
          <div class="text-xl md:text-2xl lg:text-3xl text-center transition-all ease-in-out duration-300 delay-0 transform cursor-pointer">
            Ойртож буй арга хэмжээ болон <br /> сайн дурын үйл ажиллагаанууд
          </div>
          <div class="text-lg lg:text-xl text-[#7c7c7c] dark:text-[#c6c6c6] text-center transition-all ease-in-out duration-300 delay-0 transform">
            Эдгээр үйл ажиллагаанд идэвхтэй оролцоорой
          </div>
          <div
            onClick={() => {
              window.location.pathname = "/noots/uil_ajillagaa";
            }}
            className="sm:text-lg text-center items-center hidden md:flex absolute right-0 font-semibold md:text-xl group/item flex-row gap-2 hover:gap-0 transition-all ease-in-out duration-300 delay-0 transform text-neutral-600 dark:text-neutral-300 dark:hover:text-[#98B851]  hover:text-[#4BAB4D] cursor-pointer"
          >
            Дэлгэрэнгүй{" "}
            <svg
              className="h-4"
              viewBox="0 0 366 359"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1639_2733)">
                <path
                  d="M186.935 358.886L145.741 318.048L253.874 209.916H0.5V149.902H253.874L145.741 41.9472L186.935 0.931641L365.912 179.909L186.935 358.886Z"
                  className="group-hover/item:fill-[#4BAB4D] dark:group-hover/item:fill-[#98B851] fill-[#525252] dark:fill-neutral-300 transition-all ease-in-out duration-300 delay-0 transform"
                />
              </g>
              <defs>
                <clipPath id="clip0_1639_2733">
                  <rect width="366" height="359" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div
            onClick={() => {
              window.location.pathname = "/sain_duriin_ajil";
            }}
            className="sm:text-lg text-center items-center md:hidden flex font-semibold md:text-xl group/item flex-row gap-2 hover:gap-0 transition-all ease-in-out duration-300 delay-0 transform text-neutral-600 dark:text-neutral-300 dark:hover:text-[#98B851]  hover:text-[#4BAB4D] cursor-pointer"
          >
            Дэлгэрэнгүй{" "}
            <svg
              className="h-4"
              viewBox="0 0 366 359"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1639_2733)">
                <path
                  d="M186.935 358.886L145.741 318.048L253.874 209.916H0.5V149.902H253.874L145.741 41.9472L186.935 0.931641L365.912 179.909L186.935 358.886Z"
                  className="group-hover/item:fill-[#4BAB4D] dark:group-hover/item:fill-[#98B851] fill-[#525252] dark:fill-neutral-300 transition-all ease-in-out duration-300 delay-0 transform"
                />
              </g>
              <defs>
                <clipPath id="clip0_1639_2733">
                  <rect width="366" height="359" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        <div class="w-full flex items-center justify-center relative">
          <div class="hidden lg:flex w-[95%] 2xl:w-[85%] pointer-events-none flex-row justify-between absolute items-center z-30 h-full">
            <button onClick={left}>
              <svg
                class="h-16 backdrop-blur-sm rounded-full drop-shadow-sm shadow-xl pointer-events-auto hover:backdrop-blur-2xl transition-all ease-in-out duration-500 delay-0 transform"
                viewBox="0 0 170 170"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M126 90C128.761 90 131 87.7614 131 85C131 82.2386 128.761 80 126 80V90ZM40.4645 81.4645C38.5118 83.4171 38.5118 86.5829 40.4645 88.5355L72.2843 120.355C74.2369 122.308 77.4027 122.308 79.3553 120.355C81.308 118.403 81.308 115.237 79.3553 113.284L51.0711 85L79.3553 56.7157C81.308 54.7631 81.308 51.5973 79.3553 49.6447C77.4027 47.692 74.2369 47.692 72.2843 49.6447L40.4645 81.4645ZM126 80L44 80V90L126 90V80Z"
                  fill="#4BAB4D"
                ></path>
              </svg>
            </button>
            <button onClick={right}>
              <svg
                class="h-16 backdrop-blur-sm rounded-full drop-shadow-sm shadow-xl pointer-events-auto hover:backdrop-blur-2xl transition-all ease-in-out duration-500 delay-0 transform"
                viewBox="0 0 170 170"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M44 90C41.2386 90 39 87.7614 39 85C39 82.2386 41.2386 80 44 80V90ZM129.536 81.4645C131.488 83.4171 131.488 86.5829 129.536 88.5355L97.7157 120.355C95.7631 122.308 92.5973 122.308 90.6447 120.355C88.692 118.403 88.692 115.237 90.6447 113.284L118.929 85L90.6447 56.7157C88.692 54.7631 88.692 51.5973 90.6447 49.6447C92.5973 47.692 95.7631 47.692 97.7157 49.6447L129.536 81.4645ZM44 80L126 80V90L44 90V80Z"
                  fill="#4BAB4D"
                ></path>
              </svg>
            </button>
          </div>
          <div
            id="content"
            class="relative w-full flex gap-4 snap-x snap-mandatory overflow-x-auto scroll-smooth pb-4"
          >
            {event.length > 0 ? (
              <>
                {event.map((el) => (
                  <a
                    href={"/noots/uil_ajillagaa/" + el._id}
                    class="snap-always snap-center shrink-0 last:sm:mr-[10%] first:sm:ml-[10%] first:md:ml-[14%] last:md:mr-[14%] first:lg:ml-[20%] last:lg:mr-[20%]"
                  >
                    <div>
                      <div class="md:border-2 dark:border-zinc-800 rounded-lg">
                        <div class="rounded-lg flex flex-col lg:flex-row cursor-pointer md:p-4 lg:gap-4 gap-1">
                          <div class="flex flex-col items-center justify-between">
                            <div class="flex flex-col xl:flex-row items-center font-semibold">
                              <div class="w-80 lg:w-72 2xl:w-80">
                                <img
                                  class="w-80 lg:w-72 2xl:w-80 h-48 xl:h-52 2xl:h-56 object-cover rounded-lg"
                                  src={
                                    "https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/Events%2F" +
                                    el.i
                                  }
                                  alt=""
                                />
                              </div>
                              <div class="lg:ml-2 xl:ml-4 w-80 lg:w-72 2xl:w-80 text-justify">
                                <div class="text-lg xl:text-xl lg:hidden xl:block">
                                  {el.n}
                                </div>
                                <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex lg:hidden xl:flex">
                                  <div>
                                    {new Date(el.sT).getUTCDay() === 1
                                      ? "Дав, "
                                      : new Date(el.sT).getUTCDay() === 2
                                      ? "Мяг, "
                                      : new Date(el.sT).getUTCDay() === 3
                                      ? "Лха, "
                                      : new Date(el.sT).getUTCDay() === 4
                                      ? "Пүр, "
                                      : new Date(el.sT).getUTCDay() === 5
                                      ? "Баа, "
                                      : new Date(el.sT).getUTCDay() === 6
                                      ? "Бям, "
                                      : new Date(el.sT).getUTCDay() === 7
                                      ? "Ням, "
                                      : ""}
                                    {new Date(el.sT).getUTCMonth() +
                                      1 +
                                      "-р сарын " +
                                      new Date(el.sT).getUTCDate() +
                                      ", " +
                                      new Date(el.sT).getUTCFullYear()}
                                  </div>
                                  <div>
                                    {new Date(el.sT).getUTCHours() +
                                      ":" +
                                      (new Date(el.sT).getUTCMinutes() === 0
                                        ? "00"
                                        : new Date(el.sT).getUTCMinutes())}
                                  </div>
                                </div>
                                <div class="xl:text-md h-40 overflow-hidden relative">
                                  <div className="bg-gradient-to-t from-white dark:from-black via-transparent to-transparent h-full w-full absolute"></div>
                                  {el.nf}
                                </div>
                              </div>
                            </div>
                            <div class="mt-4 w-full hidden xl:block font-semibold">
                              <button
                                onClick={() => {
                                  if (el.r === "n") {
                                    return 0;
                                  } else {
                                    window.open(el.r);
                                  }
                                }}
                                class={
                                  el.r === "n"
                                    ? "p-2 text-[#4BAB4D] rounded-lg w-full bg-[#027f0018] cursor-not-allowed"
                                    : "p-2 bg-[#4BAB4D] rounded-lg w-full text-white cursor-pointer"
                                }
                              >
                                Бүртгүүлэх{" "}
                                {el.r === "n" ? " шаардлагагүй " : ""}
                              </button>
                            </div>
                          </div>
                          <div class="w-80 lg:w-72 2xl:w-80 flex flex-col text-wrap justify-between h-auto lg:text-md gap-y-1 lg:gap-y-0">
                            <div class="hidden lg:block xl:hidden font-semibold">
                              <div class="text-md lg:text-lg xl:text-xl">
                                {el.n}
                              </div>
                              <div class="text-[#656366] text-sm 2xl:text-base flex flex-row justify-between">
                                <div>
                                  {new Date(el.sT).getUTCDay() === 1
                                    ? "Дав, "
                                    : new Date(el.sT).getUTCDay() === 2
                                    ? "Мяг, "
                                    : new Date(el.sT).getUTCDay() === 3
                                    ? "Лха, "
                                    : new Date(el.sT).getUTCDay() === 4
                                    ? "Пүр, "
                                    : new Date(el.sT).getUTCDay() === 5
                                    ? "Баа, "
                                    : new Date(el.sT).getUTCDay() === 6
                                    ? "Бям, "
                                    : new Date(el.sT).getUTCDay() === 7
                                    ? "Ням, "
                                    : ""}
                                  {new Date(el.sT).getUTCMonth() +
                                    1 +
                                    "-р сарын " +
                                    new Date(el.sT).getUTCDate() +
                                    ", " +
                                    new Date(el.sT).getUTCFullYear()}
                                </div>
                                <div>
                                  {new Date(el.sT).getUTCHours() +
                                    ":" +
                                    (new Date(el.sT).getUTCMinutes() === 0
                                      ? "00"
                                      : new Date(el.sT).getUTCMinutes())}
                                </div>
                              </div>
                            </div>
                            {el.d.map((ell, index) => (
                              <div class=" flex flex-wrap justify-between font-semibold w-full gap-y-1">
                                <div class="">{Object.keys(ell)}:</div>
                                <div
                                  style={{
                                    backgroundColor:
                                      colors[index % colors.length],
                                  }}
                                  class="text-sm px-1 xl:p-1 rounded-lg text-white flex justify-center items-center"
                                >
                                  {ell[Object.keys(ell)[0]]}
                                </div>
                              </div>
                            ))}
                            <button
                              onClick={() => {
                                if (el.r === "n") {
                                  return 0;
                                } else {
                                  window.open(el.r);
                                }
                              }}
                              class={
                                el.r === "n"
                                  ? "block xl:hidden p-2 text-[#4BAB4D] rounded-lg w-full bg-[#027f0018] cursor-not-allowed"
                                  : "block xl:hidden p-2 bg-[#4BAB4D] rounded-lg w-full text-white cursor-pointer"
                              }
                            >
                              Бүртгүүлэх {el.r === "n" ? " шаардлагагүй " : ""}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                ))}
              </>
            ) : (
              <>
                <div class="snap-always snap-center shrink-0 last:sm:mr-[10%] first:sm:ml-[10%] first:md:ml-[14%] last:md:mr-[14%] first:lg:ml-[20%] last:lg:mr-[20%]">
                  <div>
                    <div class="md:border-2 dark:border-zinc-800 rounded-lg">
                      <div class="rounded-lg flex flex-col lg:flex-row cursor-pointer md:p-4 lg:gap-4 gap-1">
                        <div class="flex flex-col items-center justify-between">
                          <div class="flex flex-col xl:flex-row items-center font-semibold">
                            <div class="w-80 lg:w-72 2xl:w-80 lg:mb-0 mb-2">
                              <div class="w-80 lg:w-72 2xl:w-80 h-48 xl:h-52 2xl:h-56 object-cover rounded-lg bg-black/25 dark:bg-white/25"></div>
                            </div>
                            <div class=" xl:ml-4 w-80 lg:w-72 2xl:w-80 text-justify">
                              <div class="text-lg xl:text-xl lg:hidden xl:block w-full bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                              <div class="text-lg xl:text-xl lg:hidden xl:block w-[40%] mt-1 bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                                <div className="w-[30%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="xl:text-md h-28 mt-2 rounded-lg w-full overflow-hidden relative bg-black/25 dark:bg-white/25"></div>
                            </div>
                          </div>
                          <div class="mt-4 w-full hidden xl:block font-semibold">
                            <button class="p-2 bg-black/25 dark:bg-white/25 rounded-lg w-full cursor-not-allowed h-10"></button>
                          </div>
                        </div>
                        <div class="w-80 lg:w-72 2xl:w-80 flex flex-col text-wrap justify-between h-auto lg:text-md gap-y-1 lg:gap-y-0">
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>

                          <div class="xl:hidden lg:block hidden xl:ml-4 w-80 lg:w-72 2xl:w-80 text-justify">
                            <div class="text-lg xl:text-xl xl:block w-full bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                            <div class="text-lg xl:text-xl xl:block w-[40%] mt-1 bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                            <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                              <div className="w-[30%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            </div>
                            <div className="flex flex-col h-40 w-full justify-between">
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                            </div>
                          </div>
                          <button class="block xl:hidden p-2 bg-black/25 dark:bg-white/25 rounded-lg h-10 w-full cursor-not-allowed"></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="snap-always snap-center shrink-0 last:sm:mr-[10%] first:sm:ml-[10%] first:md:ml-[14%] last:md:mr-[14%] first:lg:ml-[20%] last:lg:mr-[20%]">
                  <div>
                    <div class="md:border-2 dark:border-zinc-800 rounded-lg">
                      <div class="rounded-lg flex flex-col lg:flex-row cursor-pointer md:p-4 lg:gap-4 gap-1">
                        <div class="flex flex-col items-center justify-between">
                          <div class="flex flex-col xl:flex-row items-center font-semibold">
                            <div class="w-80 lg:w-72 2xl:w-80 lg:mb-0 mb-2">
                              <div class="w-80 lg:w-72 2xl:w-80 h-48 xl:h-52 2xl:h-56 object-cover rounded-lg bg-black/25 dark:bg-white/25"></div>
                            </div>
                            <div class=" xl:ml-4 w-80 lg:w-72 2xl:w-80 text-justify">
                              <div class="text-lg xl:text-xl lg:hidden xl:block w-full bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                              <div class="text-lg xl:text-xl lg:hidden xl:block w-[40%] mt-1 bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                                <div className="w-[30%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="xl:text-md h-28 mt-2 rounded-lg w-full overflow-hidden relative bg-black/25 dark:bg-white/25"></div>
                            </div>
                          </div>
                          <div class="mt-4 w-full hidden xl:block font-semibold">
                            <button class="p-2 bg-black/25 dark:bg-white/25 rounded-lg w-full cursor-not-allowed h-10"></button>
                          </div>
                        </div>
                        <div class="w-80 lg:w-72 2xl:w-80 flex flex-col text-wrap justify-between h-auto lg:text-md gap-y-1 lg:gap-y-0">
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>

                          <div class="xl:hidden lg:block hidden xl:ml-4 w-80 lg:w-72 2xl:w-80 text-justify">
                            <div class="text-lg xl:text-xl xl:block w-full bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                            <div class="text-lg xl:text-xl xl:block w-[40%] mt-1 bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                            <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                              <div className="w-[30%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            </div>
                            <div className="flex flex-col h-40 w-full justify-between">
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                            </div>
                          </div>
                          <button class="block xl:hidden p-2 bg-black/25 dark:bg-white/25 rounded-lg h-10 w-full cursor-not-allowed"></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="snap-always snap-center shrink-0 last:sm:mr-[10%] first:sm:ml-[10%] first:md:ml-[14%] last:md:mr-[14%] first:lg:ml-[20%] last:lg:mr-[20%]">
                  <div>
                    <div class="md:border-2 dark:border-zinc-800 rounded-lg">
                      <div class="rounded-lg flex flex-col lg:flex-row cursor-pointer md:p-4 lg:gap-4 gap-1">
                        <div class="flex flex-col items-center justify-between">
                          <div class="flex flex-col xl:flex-row items-center font-semibold">
                            <div class="w-80 lg:w-72 2xl:w-80 lg:mb-0 mb-2">
                              <div class="w-80 lg:w-72 2xl:w-80 h-48 xl:h-52 2xl:h-56 object-cover rounded-lg bg-black/25 dark:bg-white/25"></div>
                            </div>
                            <div class=" xl:ml-4 w-80 lg:w-72 2xl:w-80 text-justify">
                              <div class="text-lg xl:text-xl lg:hidden xl:block w-full bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                              <div class="text-lg xl:text-xl lg:hidden xl:block w-[40%] mt-1 bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                                <div className="w-[30%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="xl:text-md h-28 mt-2 rounded-lg w-full overflow-hidden relative bg-black/25 dark:bg-white/25"></div>
                            </div>
                          </div>
                          <div class="mt-4 w-full hidden xl:block font-semibold">
                            <button class="p-2 bg-black/25 dark:bg-white/25 rounded-lg w-full cursor-not-allowed h-10"></button>
                          </div>
                        </div>
                        <div class="w-80 lg:w-72 2xl:w-80 flex flex-col text-wrap justify-between h-auto lg:text-md gap-y-1 lg:gap-y-0">
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>

                          <div class="xl:hidden lg:block hidden xl:ml-4 w-80 lg:w-72 2xl:w-80 text-justify">
                            <div class="text-lg xl:text-xl xl:block w-full bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                            <div class="text-lg xl:text-xl xl:block w-[40%] mt-1 bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                            <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                              <div className="w-[30%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            </div>
                            <div className="flex flex-col h-40 w-full justify-between">
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                            </div>
                          </div>
                          <button class="block xl:hidden p-2 bg-black/25 dark:bg-white/25 rounded-lg h-10 w-full cursor-not-allowed"></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="snap-always snap-center shrink-0 last:sm:mr-[10%] first:sm:ml-[10%] first:md:ml-[14%] last:md:mr-[14%] first:lg:ml-[20%] last:lg:mr-[20%]">
                  <div>
                    <div class="md:border-2 dark:border-zinc-800 rounded-lg">
                      <div class="rounded-lg flex flex-col lg:flex-row cursor-pointer md:p-4 lg:gap-4 gap-1">
                        <div class="flex flex-col items-center justify-between">
                          <div class="flex flex-col xl:flex-row items-center font-semibold">
                            <div class="w-80 lg:w-72 2xl:w-80 lg:mb-0 mb-2">
                              <div class="w-80 lg:w-72 2xl:w-80 h-48 xl:h-52 2xl:h-56 object-cover rounded-lg bg-black/25 dark:bg-white/25"></div>
                            </div>
                            <div class=" xl:ml-4 w-80 lg:w-72 2xl:w-80 text-justify">
                              <div class="text-lg xl:text-xl lg:hidden xl:block w-full bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                              <div class="text-lg xl:text-xl lg:hidden xl:block w-[40%] mt-1 bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                                <div className="w-[30%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="xl:text-md h-28 mt-2 rounded-lg w-full overflow-hidden relative bg-black/25 dark:bg-white/25"></div>
                            </div>
                          </div>
                          <div class="mt-4 w-full hidden xl:block font-semibold">
                            <button class="p-2 bg-black/25 dark:bg-white/25 rounded-lg w-full cursor-not-allowed h-10"></button>
                          </div>
                        </div>
                        <div class="w-80 lg:w-72 2xl:w-80 flex flex-col text-wrap justify-between h-auto lg:text-md gap-y-1 lg:gap-y-0">
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>

                          <div class="xl:hidden lg:block hidden xl:ml-4 w-80 lg:w-72 2xl:w-80 text-justify">
                            <div class="text-lg xl:text-xl xl:block w-full bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                            <div class="text-lg xl:text-xl xl:block w-[40%] mt-1 bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                            <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                              <div className="w-[30%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            </div>
                            <div className="flex flex-col h-40 w-full justify-between">
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                            </div>
                          </div>
                          <button class="block xl:hidden p-2 bg-black/25 dark:bg-white/25 rounded-lg h-10 w-full cursor-not-allowed"></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="snap-always snap-center shrink-0 last:sm:mr-[10%] first:sm:ml-[10%] first:md:ml-[14%] last:md:mr-[14%] first:lg:ml-[20%] last:lg:mr-[20%]">
                  <div>
                    <div class="md:border-2 dark:border-zinc-800 rounded-lg">
                      <div class="rounded-lg flex flex-col lg:flex-row cursor-pointer md:p-4 lg:gap-4 gap-1">
                        <div class="flex flex-col items-center justify-between">
                          <div class="flex flex-col xl:flex-row items-center font-semibold">
                            <div class="w-80 lg:w-72 2xl:w-80 lg:mb-0 mb-2">
                              <div class="w-80 lg:w-72 2xl:w-80 h-48 xl:h-52 2xl:h-56 object-cover rounded-lg bg-black/25 dark:bg-white/25"></div>
                            </div>
                            <div class=" xl:ml-4 w-80 lg:w-72 2xl:w-80 text-justify">
                              <div class="text-lg xl:text-xl lg:hidden xl:block w-full bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                              <div class="text-lg xl:text-xl lg:hidden xl:block w-[40%] mt-1 bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                                <div className="w-[30%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="xl:text-md h-28 mt-2 rounded-lg w-full overflow-hidden relative bg-black/25 dark:bg-white/25"></div>
                            </div>
                          </div>
                          <div class="mt-4 w-full hidden xl:block font-semibold">
                            <button class="p-2 bg-black/25 dark:bg-white/25 rounded-lg w-full cursor-not-allowed h-10"></button>
                          </div>
                        </div>
                        <div class="w-80 lg:w-72 2xl:w-80 flex flex-col text-wrap justify-between h-auto lg:text-md gap-y-1 lg:gap-y-0">
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>

                          <div class="xl:hidden lg:block hidden xl:ml-4 w-80 lg:w-72 2xl:w-80 text-justify">
                            <div class="text-lg xl:text-xl xl:block w-full bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                            <div class="text-lg xl:text-xl xl:block w-[40%] mt-1 bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                            <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                              <div className="w-[30%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            </div>
                            <div className="flex flex-col h-40 w-full justify-between">
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                            </div>
                          </div>
                          <button class="block xl:hidden p-2 bg-black/25 dark:bg-white/25 rounded-lg h-10 w-full cursor-not-allowed"></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div class="snap-center shrink-0">
              <div class="shrink-0 md:w-48"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full hidden mt-16 justify-center items-center flex-col rounded-lg bg-[#54545428] py-2">
        <div className="font-semibold flex flex-row items-center justify-center p-2 lg:w-[60%] text-center">
          <div className="text-xl md:text-2xl lg:text-3xl">
            Хичээлээс гадуурх үйл ажиллагаатай холбоотой мэдээллүүд
          </div>
        </div>
        <div className="flex flex-row overflow-x-auto gap-4 p-2 w-full">
          <div>
            <div className="w-80 h-80 lg:w-96 lg:h-96 rounded-lg relative ring-2 ring-black m-2 bg-white dark:bg-black">
              <img
                className="w-80 h-60 lg:w-96 lg:h-72 object-cover rounded-lg absolute"
                src={gh}
                alt=""
              />
              <div className="absolute bottom-0 p-2 w-full rounded-lg">
                <div className="font-bold text-xl md:text-2xl">
                  Title/Heading
                </div>
                <div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
                  Date | Author
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="w-80 h-80 lg:w-96 lg:h-96 rounded-lg relative ring-2 ring-black m-2 bg-white dark:bg-black">
              <img
                className="w-80 h-60 lg:w-96 lg:h-72 object-cover rounded-lg absolute"
                src={gh}
                alt=""
              />
              <div className="absolute bottom-0 p-2 w-full  rounded-lg">
                <div className="font-bold text-xl md:text-2xl">
                  Title/Heading
                </div>
                <div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
                  Date | Author
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="w-80 h-80 lg:w-96 lg:h-96 rounded-lg relative ring-2 ring-black m-2 bg-white dark:bg-black">
              <img
                className="w-80 h-60 lg:w-96 lg:h-72 object-cover rounded-lg absolute"
                src={gh}
                alt=""
              />
              <div className="absolute bottom-0 p-2 w-full  rounded-lg">
                <div className="font-bold text-xl md:text-2xl">
                  Title/Heading
                </div>
                <div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
                  Date | Author
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="w-80 h-80 lg:w-96 lg:h-96 rounded-lg relative ring-2 ring-black m-2 bg-white dark:bg-black">
              <img
                className="w-80 h-60 lg:w-96 lg:h-72 object-cover rounded-lg absolute"
                src={gh}
                alt=""
              />
              <div className="absolute bottom-0 p-2 w-full  rounded-lg">
                <div className="font-bold text-xl md:text-2xl">
                  Title/Heading
                </div>
                <div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
                  Date | Author
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="w-80 h-80 lg:w-96 lg:h-96 rounded-lg relative ring-2 ring-black m-2 bg-white dark:bg-black">
              <img
                className="w-80 h-60 lg:w-96 lg:h-72 object-cover rounded-lg absolute"
                src={gh}
                alt=""
              />
              <div className="absolute bottom-0 p-2 w-full  rounded-lg">
                <div className="font-bold text-xl md:text-2xl">
                  Title/Heading
                </div>
                <div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
                  Date | Author
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="w-80 h-80 lg:w-96 lg:h-96 rounded-lg relative ring-2 ring-black m-2 bg-white dark:bg-black">
              <img
                className="w-80 h-60 lg:w-96 lg:h-72 object-cover rounded-lg absolute"
                src={gh}
                alt=""
              />
              <div className="absolute bottom-0 p-2 w-full  rounded-lg">
                <div className="font-bold text-xl md:text-2xl">
                  Title/Heading
                </div>
                <div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
                  Date | Author
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="w-80 h-80 lg:w-96 lg:h-96 rounded-lg relative ring-2 ring-black m-2 bg-white dark:bg-black">
              <img
                className="w-80 h-60 lg:w-96 lg:h-72 object-cover rounded-lg absolute"
                src={gh}
                alt=""
              />
              <div className="absolute bottom-0 p-2 w-full  rounded-lg">
                <div className="font-bold text-xl md:text-2xl">
                  Title/Heading
                </div>
                <div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
                  Date | Author
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-16 flex justify-center items-center flex-col">
        <div className="font-semibold flex flex-row items-center justify-center p-2 lg:w-[60%] text-center">
          <div className="text-xl md:text-2xl lg:text-3xl">
            Чиглэлээ сонгоогүй байсан ч танд хийж болох боломжтой олон юм бий
          </div>
        </div>
        <div className="mt-8 gap-8 flex flex-col md:w-[80%] lg:w-[60%]">
          <div className="flex flex-row gap-4">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/Prof%2F7.png?alt=media&token=4d778928-8d3c-4687-84f5-02d617295221"
              className="w-32 h-32 lg:w-40 lg:h-40 2xl:w-60 2xl:h-60 bg-[#9CABC235] ring-2 ring-[#9CABC2] rounded-lg flex justify-center items-center"
            />
            <div className="">
              <div className="text-lg md:text-xl lg:text-2xl xl:text-3xl">
                Сургуулийн болон сургуулиа гадуурх нийгэмлэгүүдэд хамрагдах.
              </div>
              <div className="text-md lg:text-lg xl:text-xl 2xl:text-2xl text-[#555555] 2xl:text-left text-justify dark:text-[#d1d1d1]">
                Ингэснээр та хүрээллээ тэлж, олон зүйлсэд суралцаж, өөрийгөө
                таньж мэдэж чадна. Энэ нь математикийн, мэтгэлцээний, байгаль
                орчны, шинжлэх ухаан судлалын, урлагийн г.м олон янз байж болно.
                Түүнчлэн өөрийнхөө сонирхлоор нээж ч болох юм.
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-4">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/Prof%2Fvolunteer.png?alt=media&token=76fb194c-9ff9-4274-bb2e-c01a69cdf0d2"
              className="w-32 h-32 lg:w-40 lg:h-40 2xl:w-60 2xl:h-60 bg-[#9CABC235] ring-2 ring-[#9CABC2] rounded-lg flex justify-center items-center"
            />
            <div className="">
              <div className="text-lg md:text-xl lg:text-2xl xl:text-3xl">
                Сайн дурын ажил хийх.
              </div>
              <div className="text-md lg:text-lg xl:text-xl 2xl:text-2xl text-[#555555] 2xl:text-left text-justify dark:text-[#d1d1d1]">
                Энэ нь танд яг ямар төрлийн зүйлс сонирхолтой санагддаг талаар
                сайн мэдэнгээ нийгэмдээ өөрийн хувь нэмрийг оруулах гайхалтай
                боломж юм.
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-4">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/Prof%2F13.png?alt=media&token=83d7af82-0a37-4e32-99be-46db3e56ce31"
              className="w-32 h-32 lg:w-40 lg:h-40 2xl:w-60 2xl:h-60 bg-[#9CABC235] ring-2 ring-[#9CABC2] rounded-lg flex justify-center items-center"
            />
            <div className="">
              <div className="text-lg md:text-xl lg:text-2xl xl:text-3xl">
                Өөрийн тань сонирхлыг татаж буй сургалтуудад хамрагдах.
              </div>
              <div className="text-md lg:text-lg xl:text-xl 2xl:text-2xl text-[#555555] 2xl:text-left text-justify dark:text-[#d1d1d1]">
                Олон төрлийн сэдвээр төлбөртэй, төлбөргүй цахим сургалтууд
                байдаг. Онлайн сургалтад хамрагдах нь өөр өөр сэдвүүдийг судлах,
                ямар чиглэлээр суралцах сонирхолтой байгаагаа харах боломжийн
                арга юм.
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-4">
            <img
              src=" https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/Prof%2Fclub.png?alt=media&token=8df33618-7120-493f-9856-543c35e65191"
              className="w-32 h-32 lg:w-40 lg:h-40 2xl:w-60 2xl:h-60 bg-[#9CABC235] ring-2 ring-[#9CABC2] rounded-lg flex justify-center items-center"
            />
            <div className="">
              <div className="text-lg md:text-xl lg:text-2xl xl:text-3xl">
                Манлайллын үүрэг гүйцэтгэх.
              </div>
              <div className="text-md lg:text-lg xl:text-xl 2xl:text-2xl text-[#555555] 2xl:text-left text-justify dark:text-[#d1d1d1]">
                Сурагчдын Зөвлөлд элсэх, өөрийн клуб/нийгэмлэгийг нээх, НҮБ-ын
                Жишиг Хурал (MUN)-д оролцох г.м маш олон сонирхолтой үйл
                ажиллагаанууд бий. Ямар нэгэн багийг ахалж, манлайлах үүрэг
                гүйцэтгэх нь манлайлах чадвар, харилцааны ур чадвар, асуудлыг
                шийдвэрлэх чадвараа хөгжүүлэх гайхалтай арга юм.
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-4">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/Prof%2F14.png?alt=media&token=5e0d3e06-8517-4654-a593-644d08b85054"
              className="w-32 h-32 lg:w-40 lg:h-40 2xl:w-60 2xl:h-60 bg-[#9CABC235] ring-2 ring-[#9CABC2] rounded-lg flex justify-center items-center"
            />
            <div className="">
              <div className="text-lg md:text-xl lg:text-2xl xl:text-3xl">
                Спортоор хичээллэх.
              </div>
              <div className="text-md lg:text-lg xl:text-xl 2xl:text-2xl text-[#555555] 2xl:text-left text-justify dark:text-[#d1d1d1]">
                Чөлөөт цагаараа биеэ хөгжүүлж, спортоор хичээллэх нь эрүүл
                байхад тустайгаас гадна багаар ажиллах чадвар, сахилга баттай
                болоход тусалдаг.
              </div>
            </div>
          </div>

          <div className="flex flex-row gap-4">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/Prof%2F12.png?alt=media&token=ed4d4dbc-64c5-461f-a462-976f8eca38c1"
              className="w-32 h-32 lg:w-40 lg:h-40 2xl:w-60 2xl:h-60 bg-[#9CABC235] ring-2 ring-[#9CABC2] rounded-lg flex justify-center items-center"
            />
            <div className="">
              <div className="text-lg md:text-xl lg:text-2xl xl:text-3xl">
                Өөрийн тань сонирхлыг татаж буй тэмцээнүүдэд багаараа, эсвэл
                ганцаараа оролцох.
              </div>
              <div className="text-md lg:text-lg xl:text-xl 2xl:text-2xl text-[#555555] 2xl:text-left text-justify dark:text-[#d1d1d1]">
                Багаараа төслийн тэмцээнүүдэд оролцох, бие даан эсээний
                уралдаанд оролцох г.м сонирхлоороо уралдаан, тэмцээнүүдэд
                оролцсоноор сонирхол нэгтэй үе тэнгийнхэнтэйгээ танилцаж,
                өөрийгөө сорих боломжтой.
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-4">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/Prof%2F15.png?alt=media&token=fc0c3415-038b-4840-9639-9e706bc31f19"
              className="w-32 h-32 lg:w-40 lg:h-40 2xl:w-60 2xl:h-60 bg-[#9CABC235] ring-2 ring-[#9CABC2] rounded-lg flex justify-center items-center"
            />
            <div className="">
              <div className="text-lg md:text-xl lg:text-2xl xl:text-3xl">
                Шинэ хэл сурах.
              </div>
              <div className="text-md lg:text-lg xl:text-xl 2xl:text-2xl text-[#555555] 2xl:text-left text-justify dark:text-[#d1d1d1]">
                Шинэ хэл сурах нь өөрийгөө сорих, танин мэдэхүйн чадвараа
                сайжруулах гайхалтай арга юм. Мөн гадаадад суралцах, ажиллах
                шинэ боломжийг нээж өгч болно.
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-4">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/Prof%2F16.png?alt=media&token=20802c4c-a3b5-415c-a2b1-12003bbc009d"
              className="w-32 h-32 lg:w-40 lg:h-40 2xl:w-60 2xl:h-60 bg-[#9CABC235] ring-2 ring-[#9CABC2] rounded-lg flex justify-center items-center"
            />
            <div className="">
              <div className="text-lg md:text-xl lg:text-2xl xl:text-3xl">
                Өөрийгөө таньж мэдэх
              </div>
              <div className="text-md lg:text-lg xl:text-xl 2xl:text-2xl text-[#555555] 2xl:text-left text-justify dark:text-[#d1d1d1]">
                Хувь хүнийг тодорхойлох тестүүдийг өгөн өөрийн давуу болон сул
                талуудыг мэдэж авч, өөрт тань юу хамгийн үнэ цэнтэй байдгийг
                тодорхойлж(бусдад туслах, мөнгө г.м) нь өөрийн мэргэжлийг олоход
                тустай.
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-4">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/Prof%2F17.png?alt=media&token=479965f0-6d6e-4ab5-a05a-7ae679b98fd5"
              className="w-32 h-32 lg:w-40 lg:h-40 2xl:w-60 2xl:h-60 bg-[#9CABC235] ring-2 ring-[#9CABC2] rounded-lg flex justify-center items-center"
            />
            <div className="">
              <div className="text-lg md:text-xl lg:text-2xl xl:text-3xl">
                Мэргэжлүүдийг судлах
              </div>
              <div className="text-md lg:text-lg xl:text-xl 2xl:text-2xl text-[#555555] 2xl:text-left text-justify dark:text-[#d1d1d1]">
                Интернэт дээр олон төрлийн мэргэжлүүдийг уншиж, судлан өөрт
                тохирно хэмээн үзэж буй хэдийг тодорхойлж, тэдгээр мэргэжлүүдийг
                гүн судлах. (Тийм мэргэжилтэй хүнтэй ярилцах, тэдгээр мэргэжлээр
                дадлага хийж үзэх г.м)
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-4">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/Prof%2F18.png?alt=media&token=7b4677d1-2932-43e4-ada3-cc8d3e1191db"
              className="w-32 h-32 lg:w-40 lg:h-40 2xl:w-60 2xl:h-60 bg-[#9CABC235] ring-2 ring-[#9CABC2] rounded-lg flex justify-center items-center"
            />
            <div className="">
              <div className="text-lg md:text-xl lg:text-2xl xl:text-3xl">
                Хичээлээс гадуурх үйл ажиллагаануудад идэвхтэй оролцох
              </div>
              <div className="text-md lg:text-lg xl:text-xl 2xl:text-2xl text-[#555555] 2xl:text-left text-justify dark:text-[#d1d1d1]">
                Олон төрлийн зүйлсийг туршиж үзэн өөрийгөө сорьсноор хувь хүнээ
                таньж мэдэн сонирхлуудаа нээх боломжтой.
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-4">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/Prof%2F19.png?alt=media&token=4b4d1c8f-dcf7-49ef-9115-80adf0f0a599"
              className="w-32 h-32 lg:w-40 lg:h-40 2xl:w-60 2xl:h-60 bg-[#9CABC235] ring-2 ring-[#9CABC2] rounded-lg flex justify-center items-center"
            />
            <div className="">
              <div className="text-lg md:text-xl lg:text-2xl xl:text-3xl">
                Зорилгоо тодорхойлох
              </div>
              <div className="text-md lg:text-lg xl:text-xl 2xl:text-2xl text-[#555555] 2xl:text-left text-justify dark:text-[#d1d1d1]">
                Өөрийнхөө тухай сурсан зүйл дээрээ үндэслэн богино болон урт
                хугацааны зорилгоо тодорхойл.
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-4">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/Prof%2F20.png?alt=media&token=511451a0-d040-4b13-90af-cd338cdb6b01"
              className="w-32 h-32 lg:w-40 lg:h-40 2xl:w-60 2xl:h-60 bg-[#9CABC235] ring-2 ring-[#9CABC2] rounded-lg flex justify-center items-center"
            />
            <div className="">
              <div className="text-lg md:text-xl lg:text-2xl xl:text-3xl">
                Өөрчлөлтөд бэлэн байх
              </div>
              <div className="text-md lg:text-lg xl:text-xl 2xl:text-2xl text-[#555555] 2xl:text-left text-justify dark:text-[#d1d1d1]">
                Шинэ туршлагад нээлттэй байж, өөрийн сонголтын талаар илүү ихийг
                мэдэхийн хэрээр чиглэлээ өөрчлөх уян хатан байдлыг өөртөө олго.
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-4">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/Prof%2F21.png?alt=media&token=94413766-ac94-4687-bdc1-88465fbb20ad"
              className="w-32 h-32 lg:w-40 lg:h-40 2xl:w-60 2xl:h-60 bg-[#9CABC235] ring-2 ring-[#9CABC2] rounded-lg flex justify-center items-center"
            />
            <div className="">
              <div className="text-lg md:text-xl lg:text-2xl xl:text-3xl">
                Өөрт олдож буй бүх боломжийг ашиглах
              </div>
              <div className="text-md lg:text-lg xl:text-xl 2xl:text-2xl text-[#555555] 2xl:text-left text-justify dark:text-[#d1d1d1]">
                Төрөл бүрийн үйл ажиллагаанууд дээр биечлэн оролцож, мэргэжлийн
                хүмүүсээс сонирхлыг тань татаж буй асуултууд асуун яриа
                өрнүүлэх.
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="mt-8 flex justify-center flex-col w-full items-center">
        <div className="font-semibold flex flex-row items-center justify-center mb-4">
          <div className="text-2xl lg:text-4xl">Сэтгэгдлийн хэсэг</div>
        </div>
        <div className="gap-4 flex flex-col lg:w-[60%] w-[95%]">
          <div className="w-full">
            <div className="relative div ">
              <input
                type="text"
                placeholder=" "
                id="Feedback"
                className="rounded-md shadow-sm border-2 sm:text-sm outline-none focus:ring-2 ring-black"
              />
              <textarea
                id="Feedback"
                rows="4"
                placeholder=" "
                className="dark:bg-neutral-900 rounded-md shadow-sm border-2 text-sm outline-none ring-0 dark:border-none focus:ring-2 ring-black dark:ring-neutral-600 input w-full max-h-44 lg:max-h-72 min-h-16"
              ></textarea>
              <label
                for="Feedback"
                className="bg-white dark:bg-neutral-900 px-1 rounded-md text-sm md:text-base"
              >
                Сэтгэгдэл
              </label>
            </div>
            <div className="mt-3 w-full flex justify-end">
              <button class="transition ease-in-out duration-300 px-4 bg-black rounded-lg text-white py-2 dark:bg-neutral-300 hover:scale-110 hover:bg-gray-950 dark:text-black">
                Илгээх
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};
