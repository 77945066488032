import React from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { formatDistanceToNow } from "date-fns";
import { Helmet } from "react-helmet";
import { useContext } from "react";
import { Article } from "./article";
import axios from "axios";
export const Flashcard = () => {
  const [ar, setAr] = useState(true);
  const [data, setData] = useState([]);
  const [flV, setFlV] = useState([]);
  const [qw, setQw] = useState(true);
  const mergejil = [];

  //   useEffect(() => {
  //     if (window.location.pathname === "") {
  //       setAr(false);
  //     } else {
  //       console.log("no");
  //     }
  //     document.title = "Tmull ・ Мэдээ";
  //     const dataRetriever = async () => {
  //       await axios.get(`https://tmull-be.vercel.app/news`).then((response) => {
  //         console.log(response?.data);
  //         setData(response?.data);
  //       });
  //     };
  //     dataRetriever();
  //   }, []);
  console.log(flV);
  const [flippedStates, setFlippedStates] = useState([]);
  const handleFlip = (index) => {
    setFlippedStates((prev) => {
      const newStates = [...prev];
      newStates[index] = !newStates[index];
      return newStates;
    });
  };

  return (
    <div className="mt-24 lg:mt-28 dark:text-white flex flex-col justify-center items-center">
      <div className="w-full">
        <div
          for="last_name"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Үгээ оруулна уу
        </div>
        <textarea
          type="text"
          onChange={(e) => setFlV(e.target.value.split("\n"))}
          class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
          placeholder="Commodity money
Commodity money"
          required
        ></textarea>
      </div>
      <div
        id="contentt"
        className="scroll-smooth w-[70%] dark:text-white lg:no-scrollbar flex flex-row gap-8 xl:gap-36 font-bold overflow-x-auto py-4 pb-6 overflow-y-hidden snap-x transition-all ease-in-out duration-300 delay-0 transform"
      >
        {flV.map((el, index) => {
          const isFlipped = flippedStates[index] || false;

          return (
            <div
              key={index}
              className="snap-center first:sm:ml-[28%] first:md:ml-[36%] first:lg:ml-[41%] last:sm:mr-[28%] last:md:mr-[36%] last:lg:mr-[41%] transition-all ease-in-out duration-500 delay-0 transform"
            >
              <div
                className={`flip-card1 transition-all ease-in-out duration-500 transform delay-150 ${
                  isFlipped ? "flipped" : ""
                }`}
                onClick={() => handleFlip(index)}
              >
                <div className="flip-card-inner1 relative h-56 xl:h-64 2xl:h-96 w-[600px] cursor-pointer lg:text-md xl:text-lg">
                  <div className="text-center flip-card-front dark:card-front dark:border-neutral-700 overflow-hidden dark:bg-[#181818] border-2 w-full h-full rounded-lg bg-white whitespace-normal flex justify-center items-center">
                    {String(flV[index]).split(" - ")[0]}
                  </div>
                  <div className="text-center flip-card-back1 dark:card-back w-full absolute top-0 overflow-hidden h-full border-2 rounded-lg flex justify-center items-center bg-white dark:border-neutral-700 dark:bg-[#181818]">
                    {String(flV[index]).split(" - ")[1]}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
