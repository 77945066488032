import { useState, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import { Home } from "./pages/home.jsx";
import { News } from "./pages/news.jsx";
import { Video } from "./pages/video.jsx";
import { Article } from "./pages/article.jsx";
import { Watch } from "./pages/watch.jsx";
import { Extracurricular } from "./pages/extracurricular.jsx";
import { Activities } from "./pages/activities.jsx";
import { Archive } from "./pages/archive.jsx";
import { Todolist } from "./pages/todolist.jsx";
import { DataSet } from "./pages/dataset.jsx";
import { AboutUs } from "./pages/aboutus.jsx";
import { EventComing } from "./pages/event.jsx";
import { Academy } from "./pages/academy.jsx";
import { School } from "./pages/school.jsx";
import { Academy1 } from "./pages/academy1.jsx";
import { Eventinfo } from "./pages/event1.jsx";
import { Login } from "./pages/login.jsx";
import { SignUp } from "./pages/signup.jsx";
import "preline/preline";
import { SugTodo } from "./pages/SugToDo.jsx";
import axios from "axios";
import { Test } from "./pages/test.jsx";
import { TestCategory } from "./pages/testcategory.jsx";
import { Upload } from "./pages/upload.jsx";
import { UploadNews } from "./pages/uploadnews.jsx";
import { UploadAcad } from "./pages/uploadacad.jsx";
import { Flashcard } from "./pages/flashcard.jsx";
import { ErrorPage } from "./pages/404.jsx";
import { Headerr } from "./pages/header.jsx";
function App() {
  const [homeb, setHomeb] = useState(false);
  const [activitiesb, setActivitiesb] = useState(false);
  const [watchb, setWatchb] = useState(false);
  const [newsb, setNewsb] = useState(false);
  const [archiveb, setArchiveb] = useState(false);
  const [emailValue, setEmailValue] = useState("");
  const [textValue, setTextValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadtext, setLoadtext] = useState("Илгээж байна...");
  const [aboutb, setAboutb] = useState(false);
  const [data, setData] = useState([]);
  const [mode, setMode] = useState(() => {
    if (localStorage.getItem("dark-mode") === "false") {
      return false;
    } else {
      return true;
    }
  });

  const sent = async () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailPattern.test(emailValue) && textValue !== "") {
      setLoading(true);
      await axios({
        url: `https://tmull-be.vercel.app/tl`,
        method: "POST",
        data: {
          email: emailValue,
          text: textValue,
        },
      }).then((response) => {
        console.log(response?.data);
      });
      setLoadtext("Илгээсэн");
      setTimeout(() => setLoading(false), 1000);
      document.getElementById("email").value = "";
      document.getElementById("Feedback").value = "";
    }
  };

  return (
    <BrowserRouter>
      <div className="flex justify-center relative w-full h-full font-medium">
        <div
          id="hs-vertically-centered-scrollable-modal"
          class="hs-overlay hidden size-full fixed top-0 start-0 z-[60] overflow-x-hidden overflow-y-auto pointer-events-none hs-overlay-backdrop-open:bg-black/60"
        >
          <div class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto h-[calc(100%-3.5rem)] min-h-[calc(100%-3.5rem)] flex items-center">
            <div class="w-full max-h-full overflow-hidden flex flex-col bg-white border-2 shadow-sm rounded-xl pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
              <div class="flex justify-between items-center py-3 px-4 border-b dark:border-neutral-700">
                <h3 class="font-bold text-gray-800 dark:text-white">
                  Modal title
                </h3>
                <button
                  type="button"
                  class="flex justify-center items-center size-7 text-sm  rounded-full border-2 border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700"
                  data-hs-overlay="#hs-vertically-centered-scrollable-modal"
                >
                  <span class="sr-only">Close</span>
                  <svg
                    class="flex-shrink-0 size-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M18 6 6 18"></path>
                    <path d="m6 6 12 12"></path>
                  </svg>
                </button>
              </div>
              <div class="p-4 overflow-y-auto">
                <div class="space-y-4">
                  <div>
                    <h3 class="text-lg  text-gray-800 dark:text-white">
                      Be bold
                    </h3>
                    <p class="mt-1 text-gray-800 dark:text-neutral-400">
                      Motivate teams to do their best work. Offer best practices
                      to get users going in the right direction. Be bold and
                      offer just enough help to get the work started, and then
                      get out of the way. Give accurate information so users can
                      make educated decisions. Know your user's struggles and
                      desired outcomes and give just enough information to let
                      them get where they need to go.
                    </p>
                  </div>

                  <div>
                    <h3 class="text-lg  text-gray-800 dark:text-white">
                      Be optimistic
                    </h3>
                    <p class="mt-1 text-gray-800 dark:text-neutral-400">
                      Focusing on the details gives people confidence in our
                      products. Weave a consistent story across our fabric and
                      be diligent about vocabulary across all messaging by being
                      brand conscious across products to create a seamless flow
                      across all the things. Let people know that they can jump
                      in and start working expecting to find a dependable
                      experience across all the things. Keep teams in the loop
                      about what is happening by informing them of relevant
                      features, products and opportunities for success. Be on
                      the journey with them and highlight the key points that
                      will help them the most - right now. Be in the moment by
                      focusing attention on the important bits first.
                    </p>
                  </div>

                  <div>
                    <h3 class="text-lg  text-gray-800 dark:text-white">
                      Be practical, with a wink
                    </h3>
                    <p class="mt-1 text-gray-800 dark:text-neutral-400">
                      Keep our own story short and give teams just enough to get
                      moving. Get to the point and be direct. Be concise - we
                      tell the story of how we can help, but we do it directly
                      and with purpose. Be on the lookout for opportunities and
                      be quick to offer a helping hand. At the same time realize
                      that novbody likes a nosy neighbor. Give the user just
                      enough to know that something awesome is around the corner
                      and then get out of the way. Write clear, accurate, and
                      concise text that makes interfaces more usable and
                      consistent - and builds trust. We strive to write text
                      that is understandable by anyone, anywhere, regardless of
                      their culture or language so that everyone feels they are
                      part of the team.
                    </p>
                  </div>
                </div>
              </div>
              <div class="flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-neutral-700">
                <button
                  type="button"
                  class="py-2 px-3 inline-flex items-center gap-x-2 text-sm  rounded-lg border-2 border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
                  data-hs-overlay="#hs-vertically-centered-scrollable-modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  class="py-2 px-3 inline-flex items-center gap-x-2 text-sm  rounded-lg border-2 border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
        <Headerr />
        {/* <div className="bg-black w-full h-96"></div> */}
        <div className="w-[85%] z-10">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/niitleluud" element={<News />} />
            <Route path="/niitleluud/:article" element={<Article />} />
            <Route path="/uzeh" element={<Video />} />
            <Route path="/uzeh/:watch" element={<Watch />} />
            <Route path="/sain_duriin_ajil" element={<Extracurricular />} />
            <Route
              path="/sain_duriin_ajil/:activities"
              element={<Activities />}
            />
            <Route path="/noots" element={<Archive />} />
            <Route path="/upload" element={<Upload />} />
            <Route path="/uploadnews" element={<UploadNews />} />
            <Route path="/uploadacad" element={<UploadAcad />} />
            <Route path="/noots/hiih_zuils" element={<Todolist />} />
            <Route path="/noots/sur_dataset" element={<DataSet />} />
            <Route path="/bid" element={<AboutUs />} />
            <Route path="/noots/uil_ajillagaa" element={<EventComing />} />
            <Route path="/noots/surgalt" element={<Academy />} />
            <Route path="/noots/surgalt/:ner" element={<Academy1 />} />
            <Route path="/noots/sur_dataset/:sur" element={<School />} />
            <Route path="/noots/uil_ajillagaa/:ner" element={<Eventinfo />} />
            <Route path="/nevtreh" element={<Login />} />
            <Route path="/burtguuleh" element={<SignUp />} />
            <Route path="*" element={<ErrorPage />} />
            <Route path="/noots/hiih_zuils/:todo" element={<SugTodo />} />
            <Route path="/test" element={<Test />} />
            <Route path="/test/:test" element={<TestCategory />} />
            <Route path="/flashcard" element={<Flashcard />} />
          </Routes>
          <footer className="w-full mt-16 lg:mt-20 transition-all ease-in-out duration-300 delay-0 transform dark:text-neutral-300 font-semibold border-t-2 dark:border-neutral-700">
            <div className="lg:mx-10 my-10">
              <div className="text-lg md:text-xl lg:text-2xl 2xl:text-3xl transition-all ease-in-out duration-300 delay-0 transform">
                Тусламж
              </div>
              <div className="flex flex-col md:flex-row justify-between w-full lg:w-[90%] xl:w-[70%] lg:text-lg xl:text-xl mt-8 transition-all ease-in-out duration-300 delay-0 transform">
                <div>
                  <Link
                    to="/"
                    className="gap-2 hover:text-white hover:bg-black pl-2 rounded-lg transition delay-150 duration-500 ease-in-out transform hover:translate-x-5 py-2 flex flex-row justify-between items-center group/item my-2 cursor-pointer"
                  >
                    Нүүр хуудас
                    <svg
                      className="h-4 lg:h-6 pr-2 group-hover/item:opacity-100 opacity-0 text-white transition-all delay-150 duration-500 ease-in-out transform"
                      viewBox="0 0 366 359"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1639_2733)">
                        <path
                          d="M186.935 358.886L145.741 318.048L253.874 209.916H0.5V149.902H253.874L145.741 41.9472L186.935 0.931641L365.912 179.909L186.935 358.886Z"
                          className="group-hover/item:fill-[#4BAB4D] transition-all ease-in-out duration-300 delay-0 transform"
                          fill="#525252"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1639_2733">
                          <rect width="366" height="359" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </Link>
                  <Link
                    to="/noots"
                    className="gap-2 hover:text-white hover:bg-black pl-2 rounded-lg transition delay-150 duration-500 ease-in-out transform hover:translate-x-5 py-2 flex flex-row justify-between items-center group/item md:my-2 cursor-pointer"
                  >
                    Нөөц
                    <svg
                      className="h-4 lg:h-6 pr-2 group-hover/item:opacity-100 opacity-0 text-white transition-all delay-150 duration-500 ease-in-out transform"
                      viewBox="0 0 366 359"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1639_2733)">
                        <path
                          d="M186.935 358.886L145.741 318.048L253.874 209.916H0.5V149.902H253.874L145.741 41.9472L186.935 0.931641L365.912 179.909L186.935 358.886Z"
                          className="group-hover/item:fill-[#4BAB4D] transition-all ease-in-out duration-300 delay-0 transform"
                          fill="#525252"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1639_2733">
                          <rect width="366" height="359" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </Link>
                  <Link
                    to="/sain_duriin_ajil"
                    className="gap-2 hover:text-white hover:bg-black pl-2 rounded-lg transition delay-150 duration-500 ease-in-out transform hover:translate-x-5 py-2 flex flex-row justify-between items-center group/item my-2 cursor-pointer"
                  >
                    Хичээлээс гадуурх ҮА
                    <svg
                      className="h-4 lg:h-6 pr-2 group-hover/item:opacity-100 opacity-0 text-white transition-all delay-150 duration-500 ease-in-out transform"
                      viewBox="0 0 366 359"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1639_2733)">
                        <path
                          d="M186.935 358.886L145.741 318.048L253.874 209.916H0.5V149.902H253.874L145.741 41.9472L186.935 0.931641L365.912 179.909L186.935 358.886Z"
                          className="group-hover/item:fill-[#4BAB4D] transition-all ease-in-out duration-300 delay-0 transform"
                          fill="#525252"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1639_2733">
                          <rect width="366" height="359" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </Link>
                  <Link
                    to="/useh"
                    className="gap-2 hover:text-white hover:bg-black pl-2 rounded-lg transition delay-150 duration-500 ease-in-out transform hover:translate-x-5 py-2 flex flex-row justify-between items-center group/item my-2 cursor-pointer"
                  >
                    Цуврал видео
                    <svg
                      className="h-4 lg:h-6 pr-2 group-hover/item:opacity-100 opacity-0 text-white transition-all delay-150 duration-500 ease-in-out transform"
                      viewBox="0 0 366 359"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1639_2733)">
                        <path
                          d="M186.935 358.886L145.741 318.048L253.874 209.916H0.5V149.902H253.874L145.741 41.9472L186.935 0.931641L365.912 179.909L186.935 358.886Z"
                          className="group-hover/item:fill-[#4BAB4D] transition-all ease-in-out duration-300 delay-0 transform"
                          fill="#525252"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1639_2733">
                          <rect width="366" height="359" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </Link>
                  <Link
                    to="/niitleluud"
                    className="gap-2 hover:text-white hover:bg-black pl-2 rounded-lg transition delay-150 duration-500 ease-in-out transform hover:translate-x-5 py-2 flex flex-row justify-between items-center group/item my-2 cursor-pointer"
                  >
                    Мэдээ мэдээлэл
                    <svg
                      className="h-4 lg:h-6 pr-2 group-hover/item:opacity-100 opacity-0 text-white transition-all delay-150 duration-500 ease-in-out transform"
                      viewBox="0 0 366 359"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1639_2733)">
                        <path
                          d="M186.935 358.886L145.741 318.048L253.874 209.916H0.5V149.902H253.874L145.741 41.9472L186.935 0.931641L365.912 179.909L186.935 358.886Z"
                          className="group-hover/item:fill-[#4BAB4D] transition-all ease-in-out duration-300 delay-0 transform"
                          fill="#525252"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1639_2733">
                          <rect width="366" height="359" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </Link>
                </div>
                <div>
                  <Link
                    to="/noots/sur_dataset"
                    className="gap-2 hover:text-white hover:bg-black pl-2 rounded-lg transition delay-150 duration-500 ease-in-out transform hover:translate-x-5 py-2 flex flex-row justify-between items-center group/item my-2 cursor-pointer"
                  >
                    Сургуулиудын мэдээлэл
                    <svg
                      className="h-4 lg:h-6 pr-2 group-hover/item:opacity-100 opacity-0 text-white transition-all delay-150 duration-500 ease-in-out transform"
                      viewBox="0 0 366 359"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1639_2733)">
                        <path
                          d="M186.935 358.886L145.741 318.048L253.874 209.916H0.5V149.902H253.874L145.741 41.9472L186.935 0.931641L365.912 179.909L186.935 358.886Z"
                          className="group-hover/item:fill-[#4BAB4D] transition-all ease-in-out duration-300 delay-0 transform"
                          fill="#525252"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1639_2733">
                          <rect width="366" height="359" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </Link>
                  <Link
                    to="/bid"
                    className="gap-2 hover:text-white hover:bg-black pl-2 rounded-lg transition delay-150 duration-500 ease-in-out transform hover:translate-x-5 py-2 flex flex-row justify-between items-center group/item my-2 cursor-pointer"
                  >
                    Тусламж ба дэмжлэг
                    <svg
                      className="h-4 lg:h-6 pr-2 group-hover/item:opacity-100 opacity-0 text-white transition-all delay-150 duration-500 ease-in-out transform"
                      viewBox="0 0 366 359"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1639_2733)">
                        <path
                          d="M186.935 358.886L145.741 318.048L253.874 209.916H0.5V149.902H253.874L145.741 41.9472L186.935 0.931641L365.912 179.909L186.935 358.886Z"
                          className="group-hover/item:fill-[#4BAB4D] transition-all ease-in-out duration-300 delay-0 transform"
                          fill="#525252"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1639_2733">
                          <rect width="366" height="359" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </Link>
                  <Link
                    to="/noots/hiih_zuils"
                    className="gap-2 hover:text-white hover:bg-black pl-2 rounded-lg transition delay-150 duration-500 ease-in-out transform hover:translate-x-5 py-2 flex flex-row justify-between items-center group/item my-2 cursor-pointer"
                  >
                    Хийх зүйлсийн жагсаалт
                    <svg
                      className="h-4 lg:h-6 pr-2 group-hover/item:opacity-100 opacity-0 text-white transition-all delay-150 duration-500 ease-in-out transform"
                      viewBox="0 0 366 359"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1639_2733)">
                        <path
                          d="M186.935 358.886L145.741 318.048L253.874 209.916H0.5V149.902H253.874L145.741 41.9472L186.935 0.931641L365.912 179.909L186.935 358.886Z"
                          className="group-hover/item:fill-[#4BAB4D] transition-all ease-in-out duration-300 delay-0 transform"
                          fill="#525252"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1639_2733">
                          <rect width="366" height="359" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </Link>
                  <Link
                    to="/noots/surgalt"
                    className="gap-2 hover:text-white hover:bg-black pl-2 rounded-lg transition delay-150 duration-500 ease-in-out transform hover:translate-x-5 py-2 flex flex-row justify-between items-center group/item my-2 cursor-pointer"
                  >
                    Хөтөлбөрүүд
                    <svg
                      className="h-4 lg:h-6 pr-2 group-hover/item:opacity-100 opacity-0 text-white transition-all delay-150 duration-500 ease-in-out transform"
                      viewBox="0 0 366 359"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1639_2733)">
                        <path
                          d="M186.935 358.886L145.741 318.048L253.874 209.916H0.5V149.902H253.874L145.741 41.9472L186.935 0.931641L365.912 179.909L186.935 358.886Z"
                          className="group-hover/item:fill-[#4BAB4D] transition-all ease-in-out duration-300 delay-0 transform"
                          fill="#525252"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1639_2733">
                          <rect width="366" height="359" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </Link>
                  <Link
                    to="/bid"
                    className="gap-2 hover:text-white hover:bg-black pl-2 rounded-lg transition delay-150 duration-500 ease-in-out transform hover:translate-x-5 py-2 flex flex-row justify-between items-center group/item my-2 cursor-pointer"
                  >
                    Бидний тухай
                    <svg
                      className="h-4 lg:h-6 pr-2 group-hover/item:opacity-100 opacity-0 text-white transition-all delay-150 duration-500 ease-in-out transform"
                      viewBox="0 0 366 359"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1639_2733)">
                        <path
                          d="M186.935 358.886L145.741 318.048L253.874 209.916H0.5V149.902H253.874L145.741 41.9472L186.935 0.931641L365.912 179.909L186.935 358.886Z"
                          className="group-hover/item:fill-[#4BAB4D] transition-all ease-in-out duration-300 delay-0 transform"
                          fill="#525252"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1639_2733">
                          <rect width="366" height="359" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
            <div className="lg:px-10 py-10 pb-0 flex flex-col lg:flex-row justify-between transition-all ease-in-out duration-300 delay-0 transform border-t-2 dark:border-neutral-700">
              <div className="w-full lg:w-[50%] transition-all ease-in-out duration-300 delay-0 transform">
                <div className="text-lg md:text-xl lg:text-2xl">
                  Санал хүсэлт
                </div>
                <div className="relative div w-full">
                  <input
                    type="email"
                    placeholder=" "
                    id="email"
                    onChange={(e) => setEmailValue(e.target.value)}
                    className="dark:bg-neutral-950 rounded-md shadow-sm border-2 text-sm outline-none focus:ring-2 ring-black dark:ring-neutral-600 input w-[95%] ring-0 dark:border-neutral-700"
                  />
                  <label
                    for="email"
                    className="bg-white dark:bg-neutral-950 px-1 rounded-md text-sm md:text-base"
                  >
                    Мэйл хаяг
                  </label>
                </div>
                <div className="relative div group/item">
                  <textarea
                    id="Feedback"
                    rows="4"
                    onChange={(e) => setTextValue(e.target.value)}
                    placeholder=" "
                    className="dark:bg-neutral-950 rounded-md shadow-sm border-2 text-sm outline-none ring-0 focus:ring-2 ring-black dark:ring-neutral-600 input w-[95%] max-h-44 lg:max-h-72 min-h-16 dark:border-neutral-700"
                  ></textarea>
                  <label
                    for="Feedback"
                    className="bg-white dark:bg-neutral-950 px-1 rounded-md text-sm md:text-base"
                  >
                    Санал хүсэлт
                  </label>
                </div>
                <div className="mt-1 lg:mt-3">
                  <button
                    disabled={loading}
                    onClick={sent}
                    class="px-3 bg-black dark:bg-neutral-300 rounded-lg text-white dark:text-black py-1.5 hover:scale-110 hover:bg-[#4BAB4D] transition-all ease-in-out duration-300 delay-0 transform"
                  >
                    {loading ? (
                      <div className="flex justify-center items-center">
                        <svg
                          class={
                            loadtext === "Илгээж байна..."
                              ? "animate-spin h-5 w-5 mr-2"
                              : "animate-spin h-5 w-5 mr-2 hidden"
                          }
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_2426_2968)">
                            <mask
                              id="mask0_2426_2968"
                              maskUnits="userSpaceOnUse"
                              x="0"
                              y="0"
                              width="24"
                              height="24"
                            >
                              <path d="M24 0H0V24H24V0Z" fill="white" />
                            </mask>
                            <g mask="url(#mask0_2426_2968)">
                              <path
                                d="M20.4838 3.51472C18.8736 1.90454 16.8385 0.785743 14.6162 0.289C12.3939 -0.207741 10.0762 -0.0619192 7.93359 0.709433L8.88267 3.34554C10.525 2.75428 12.3016 2.6425 14.0051 3.02326C15.7085 3.40403 17.2684 4.26161 18.5027 5.49585L20.4838 3.51472Z"
                                fill="#D9D9D9"
                              />
                              <path
                                d="M7.93457 0.710938C5.48964 1.59131 3.39656 3.24154 1.96998 5.41354C0.543402 7.58554 -0.139575 10.162 0.0237267 12.7554C0.187028 15.3489 1.18778 17.8192 2.87554 19.7952C4.5633 21.7711 6.84685 23.1457 9.38288 23.7125C11.919 24.2792 14.5704 24.0074 16.9387 22.9379C19.307 21.8685 21.2642 20.0591 22.516 17.7818C23.7678 15.5046 24.2465 12.8825 23.8802 10.3099C23.5139 7.73721 22.3224 5.35296 20.4849 3.51557L18.5038 5.4968C19.9124 6.90521 20.8257 8.73285 21.1064 10.7048C21.3872 12.6768 21.0202 14.6867 20.0607 16.4322C19.1012 18.1777 17.601 19.5647 15.7857 20.3844C13.9703 21.2042 11.9378 21.4126 9.99392 20.9781C8.05 20.5437 6.29961 19.49 5.0059 17.9755C3.7122 16.4609 2.9451 14.5673 2.81993 12.5793C2.69475 10.5914 3.21827 8.61653 4.31178 6.95164C5.40528 5.28675 7.00967 4.02181 8.88376 3.34699L7.93457 0.710938Z"
                                fill="white"
                                fill-opacity="0.3"
                              />
                            </g>
                          </g>
                          <defs>
                            <clipPath id="clip0_2426_2968">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        {loadtext}
                      </div>
                    ) : (
                      "Илгээх"
                    )}
                  </button>
                </div>
              </div>
              <div className=" w-full lg:w-[50%]">
                <div className="text-lg md:text-xl  lg:text-2xl mt-4 md:mt-8 lg:mt-0 transition-all ease-in-out duration-300 delay-0 transform">
                  Холбогдох:
                </div>
                <div className="flex flex-col sm:flex-row gap-2 items-center mt-3 justify-between">
                  <div>@tmull.mn</div>
                  <div className="flex flex-row gap-3 mt-4 sm:mt-0">
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to="https://www.instagram.com/tmull.mn/"
                      className="relative w-8 flex justify-center items-center"
                    >
                      <svg
                        className="group/item h-8 hover:h-10 absolute transition-all items-center duration-300 ease-in-out"
                        viewBox="0 0 35 35"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask
                          id="mask0_1254_2470"
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="35"
                          height="35"
                        >
                          <circle cx="17.5" cy="17.5" r="17.5" fill="white" />
                        </mask>
                        <g mask="url(#mask0_1254_2470)">
                          <path
                            className="group-hover/item:fill-[#4BAB4D] fill-black dark:fill-neutral-300 transition-all ease-in duration-150 delay-0"
                            d="M20.5762 17.5C20.5762 19.1989 19.1989 20.5762 17.5 20.5762C15.8011 20.5762 14.4238 19.1989 14.4238 17.5C14.4238 15.8011 15.8011 14.4238 17.5 14.4238C19.1989 14.4238 20.5762 15.8011 20.5762 17.5Z"
                          />
                          <path
                            className="group-hover/item:fill-[#4BAB4D] fill-black dark:fill-neutral-300 transition-all ease-in duration-300 delay-0"
                            d="M22.6953 8.20312H12.3047C10.0429 8.20312 8.20312 10.0429 8.20312 12.3047V22.6953C8.20312 24.9571 10.0429 26.7969 12.3047 26.7969H22.6953C24.9571 26.7969 26.7969 24.9571 26.7969 22.6953V12.3047C26.7969 10.0429 24.9571 8.20312 22.6953 8.20312ZM17.5 22.627C14.673 22.627 12.373 20.327 12.373 17.5C12.373 14.673 14.673 12.373 17.5 12.373C20.327 12.373 22.627 14.673 22.627 17.5C22.627 20.327 20.327 22.627 17.5 22.627ZM23.3789 12.6465C22.8125 12.6465 22.3535 12.1875 22.3535 11.6211C22.3535 11.0547 22.8125 10.5957 23.3789 10.5957C23.9453 10.5957 24.4043 11.0547 24.4043 11.6211C24.4043 12.1875 23.9453 12.6465 23.3789 12.6465Z"
                          />
                          <path
                            className="group-hover/item:fill-[#4BAB4D] fill-black dark:fill-neutral-300 transition-all ease-in duration-500 delay-0"
                            d="M25.7715 0H9.22852C4.13998 0 0 4.14005 0 9.22852V25.7715C0 30.86 4.14005 35 9.22852 35H25.7715C30.86 35 35 30.86 35 25.7715V9.22852C35 4.13998 30.86 0 25.7715 0ZM28.8477 22.6953C28.8477 26.0876 26.0876 28.8477 22.6953 28.8477H12.3047C8.91235 28.8477 6.15234 26.0876 6.15234 22.6953V12.3047C6.15234 8.91235 8.91235 6.15234 12.3047 6.15234H22.6953C26.0876 6.15234 28.8477 8.91235 28.8477 12.3047V22.6953Z"
                          />
                        </g>
                      </svg>
                    </Link>
                    <Link
                      to="https://www.facebook.com/tmull.mn"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="relative w-8 flex justify-center items-center"
                    >
                      <svg
                        className="group/item h-8 hover:h-10 absolute transition-all items-center duration-300 ease-in-out"
                        viewBox="0 0 35 35"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          className="group-hover/item:fill-[#4BAB4D] fill-black dark:fill-neutral-300 transition-all ease-in duration-300 delay-0"
                          d="M17.5001 0C7.83516 0 0 7.86792 0 17.5733C0 26.2778 6.30913 33.4867 14.5814 34.8826V21.2395H10.3599V16.3299H14.5814V12.7098C14.5814 8.5094 17.1362 6.22042 20.8682 6.22042C22.6556 6.22042 24.1917 6.35416 24.6376 6.41307V10.8036L22.0492 10.8049C20.02 10.8049 19.6288 11.773 19.6288 13.194V16.3274H24.4706L23.8391 21.237H19.6288V35C28.2873 33.9418 35 26.5493 35 17.5682C35 7.86792 27.1648 0 17.5001 0Z"
                        />
                      </svg>
                    </Link>
                    <Link
                      to="https://www.youtube.com/@TmullMn"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="relative w-8 flex justify-center items-center"
                    >
                      <svg
                        className="group/item h-8 hover:h-10 absolute transition-all items-center duration-300 ease-in-out"
                        viewBox="0 0 35 35"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="17.5"
                          cy="17.5"
                          r="17.5"
                          className="group-hover/item:fill-[#4BAB4D] fill-black dark:fill-neutral-300 transition-all ease-in duration-500 delay-0"
                        />
                        <g clip-path="url(#clip0_1254_2479)">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M27.7965 14.0355C27.7965 14.0355 27.6012 12.6569 26.999 12.0514C26.2389 11.2555 25.3861 11.2507 24.9954 11.2035C22.1992 11 17.9984 11 17.9984 11C17.9984 11 13.7992 11 10.9997 11.2035C10.609 11.2507 9.75781 11.2539 8.99609 12.0514C8.39062 12.6569 8.19857 14.0355 8.19857 14.0355C8.07488 15.1108 8.00859 16.192 8 17.2744L8 18.7897C8.00875 19.8721 8.07503 20.9533 8.19857 22.0286C8.19857 22.0286 8.39388 23.4072 8.99121 24.0208C9.75293 24.8184 10.7523 24.7923 11.1999 24.877C12.7998 25.0299 18 25.0755 18 25.0755C18 25.0755 22.2041 25.0755 24.9987 24.8688C25.391 24.8216 26.2422 24.8167 27.0039 24.0208C27.6045 23.4154 27.7998 22.0352 27.7998 22.0352C27.9235 20.9598 27.9898 19.8786 27.9984 18.7962V17.2728C27.9875 16.1903 27.9196 15.1092 27.7949 14.0339L27.7965 14.0355Z"
                            className="fill-white dark:fill-black"
                          />
                          <path
                            className="group-hover/item:fill-[#4BAB4D] fill-black dark:fill-neutral-300 transition-all ease-in duration-300 delay-0"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M15.9277 20.6289V15.0088L21.3298 17.8278L15.9277 20.6289Z"
                          />
                        </g>
                      </svg>
                    </Link>
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row gap-2 items-center mt-8 justify-between">
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    to="https://www.unicef.org/mongolia/"
                  >
                    @unicefmongolia
                  </Link>
                  <div className="flex flex-row gap-3 mt-4 sm:mt-0">
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to="https://www.instagram.com/unicefmongolia/"
                      className="relative w-8 flex justify-center items-center"
                    >
                      <svg
                        className="group/item h-8 hover:h-10 absolute transition-all items-center duration-300 ease-in-out"
                        viewBox="0 0 35 35"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask
                          id="mask0_1254_2470"
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="35"
                          height="35"
                        >
                          <circle cx="17.5" cy="17.5" r="17.5" fill="white" />
                        </mask>
                        <g mask="url(#mask0_1254_2470)">
                          <path
                            className="group-hover/item:fill-[#4BAB4D] fill-black dark:fill-neutral-300 transition-all ease-in duration-150 delay-0"
                            d="M20.5762 17.5C20.5762 19.1989 19.1989 20.5762 17.5 20.5762C15.8011 20.5762 14.4238 19.1989 14.4238 17.5C14.4238 15.8011 15.8011 14.4238 17.5 14.4238C19.1989 14.4238 20.5762 15.8011 20.5762 17.5Z"
                          />
                          <path
                            className="group-hover/item:fill-[#4BAB4D] fill-black dark:fill-neutral-300 transition-all ease-in duration-300 delay-0"
                            d="M22.6953 8.20312H12.3047C10.0429 8.20312 8.20312 10.0429 8.20312 12.3047V22.6953C8.20312 24.9571 10.0429 26.7969 12.3047 26.7969H22.6953C24.9571 26.7969 26.7969 24.9571 26.7969 22.6953V12.3047C26.7969 10.0429 24.9571 8.20312 22.6953 8.20312ZM17.5 22.627C14.673 22.627 12.373 20.327 12.373 17.5C12.373 14.673 14.673 12.373 17.5 12.373C20.327 12.373 22.627 14.673 22.627 17.5C22.627 20.327 20.327 22.627 17.5 22.627ZM23.3789 12.6465C22.8125 12.6465 22.3535 12.1875 22.3535 11.6211C22.3535 11.0547 22.8125 10.5957 23.3789 10.5957C23.9453 10.5957 24.4043 11.0547 24.4043 11.6211C24.4043 12.1875 23.9453 12.6465 23.3789 12.6465Z"
                          />
                          <path
                            className="group-hover/item:fill-[#4BAB4D] fill-black dark:fill-neutral-300 transition-all ease-in duration-500 delay-0"
                            d="M25.7715 0H9.22852C4.13998 0 0 4.14005 0 9.22852V25.7715C0 30.86 4.14005 35 9.22852 35H25.7715C30.86 35 35 30.86 35 25.7715V9.22852C35 4.13998 30.86 0 25.7715 0ZM28.8477 22.6953C28.8477 26.0876 26.0876 28.8477 22.6953 28.8477H12.3047C8.91235 28.8477 6.15234 26.0876 6.15234 22.6953V12.3047C6.15234 8.91235 8.91235 6.15234 12.3047 6.15234H22.6953C26.0876 6.15234 28.8477 8.91235 28.8477 12.3047V22.6953Z"
                          />
                        </g>
                      </svg>
                    </Link>
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to="https://www.facebook.com/unicefmongolia"
                      className="relative w-8 flex justify-center items-center"
                    >
                      <svg
                        className="group/item h-8 hover:h-10 absolute transition-all items-center duration-300 ease-in-out"
                        viewBox="0 0 35 35"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          className="group-hover/item:fill-[#4BAB4D] fill-black dark:fill-neutral-300 transition-all ease-in duration-300 delay-0"
                          d="M17.5001 0C7.83516 0 0 7.86792 0 17.5733C0 26.2778 6.30913 33.4867 14.5814 34.8826V21.2395H10.3599V16.3299H14.5814V12.7098C14.5814 8.5094 17.1362 6.22042 20.8682 6.22042C22.6556 6.22042 24.1917 6.35416 24.6376 6.41307V10.8036L22.0492 10.8049C20.02 10.8049 19.6288 11.773 19.6288 13.194V16.3274H24.4706L23.8391 21.237H19.6288V35C28.2873 33.9418 35 26.5493 35 17.5682C35 7.86792 27.1648 0 17.5001 0Z"
                        />
                      </svg>
                    </Link>
                    <div className="relative w-8 flex justify-center items-center cursor-pointer">
                      <svg
                        className="group/item h-8 hover:h-10 absolute transition-all items-center duration-300 ease-in-out"
                        viewBox="0 0 35 35"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="17.5"
                          cy="17.5"
                          r="17.5"
                          className="group-hover/item:fill-[#4BAB4D] fill-black dark:fill-neutral-300 transition-all ease-in duration-500 delay-0"
                        />
                        <g clip-path="url(#clip0_1254_2479)">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M27.7965 14.0355C27.7965 14.0355 27.6012 12.6569 26.999 12.0514C26.2389 11.2555 25.3861 11.2507 24.9954 11.2035C22.1992 11 17.9984 11 17.9984 11C17.9984 11 13.7992 11 10.9997 11.2035C10.609 11.2507 9.75781 11.2539 8.99609 12.0514C8.39062 12.6569 8.19857 14.0355 8.19857 14.0355C8.07488 15.1108 8.00859 16.192 8 17.2744L8 18.7897C8.00875 19.8721 8.07503 20.9533 8.19857 22.0286C8.19857 22.0286 8.39388 23.4072 8.99121 24.0208C9.75293 24.8184 10.7523 24.7923 11.1999 24.877C12.7998 25.0299 18 25.0755 18 25.0755C18 25.0755 22.2041 25.0755 24.9987 24.8688C25.391 24.8216 26.2422 24.8167 27.0039 24.0208C27.6045 23.4154 27.7998 22.0352 27.7998 22.0352C27.9235 20.9598 27.9898 19.8786 27.9984 18.7962V17.2728C27.9875 16.1903 27.9196 15.1092 27.7949 14.0339L27.7965 14.0355Z"
                            className="fill-white dark:fill-black"
                          />
                          <path
                            className="group-hover/item:fill-[#4BAB4D] fill-black dark:fill-neutral-300 transition-all ease-in duration-300 delay-0"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M15.9277 20.6289V15.0088L21.3298 17.8278L15.9277 20.6289Z"
                          />
                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row gap-2 items-center mt-8 justify-between">
                  <div>@mongolian_childrens_council</div>
                  <div className="flex flex-row gap-3 mt-4 sm:mt-0">
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to="https://www.instagram.com/mongolian_childrens_council/"
                      className="relative w-8 flex justify-center items-center"
                    >
                      <svg
                        className="group/item h-8 hover:h-10 absolute transition-all items-center duration-300 ease-in-out"
                        viewBox="0 0 35 35"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask
                          id="mask0_1254_2470"
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="35"
                          height="35"
                        >
                          <circle cx="17.5" cy="17.5" r="17.5" fill="white" />
                        </mask>
                        <g mask="url(#mask0_1254_2470)">
                          <path
                            className="group-hover/item:fill-[#4BAB4D] fill-black dark:fill-neutral-300 transition-all ease-in duration-150 delay-0"
                            d="M20.5762 17.5C20.5762 19.1989 19.1989 20.5762 17.5 20.5762C15.8011 20.5762 14.4238 19.1989 14.4238 17.5C14.4238 15.8011 15.8011 14.4238 17.5 14.4238C19.1989 14.4238 20.5762 15.8011 20.5762 17.5Z"
                          />
                          <path
                            className="group-hover/item:fill-[#4BAB4D] fill-black dark:fill-neutral-300 transition-all ease-in duration-300 delay-0"
                            d="M22.6953 8.20312H12.3047C10.0429 8.20312 8.20312 10.0429 8.20312 12.3047V22.6953C8.20312 24.9571 10.0429 26.7969 12.3047 26.7969H22.6953C24.9571 26.7969 26.7969 24.9571 26.7969 22.6953V12.3047C26.7969 10.0429 24.9571 8.20312 22.6953 8.20312ZM17.5 22.627C14.673 22.627 12.373 20.327 12.373 17.5C12.373 14.673 14.673 12.373 17.5 12.373C20.327 12.373 22.627 14.673 22.627 17.5C22.627 20.327 20.327 22.627 17.5 22.627ZM23.3789 12.6465C22.8125 12.6465 22.3535 12.1875 22.3535 11.6211C22.3535 11.0547 22.8125 10.5957 23.3789 10.5957C23.9453 10.5957 24.4043 11.0547 24.4043 11.6211C24.4043 12.1875 23.9453 12.6465 23.3789 12.6465Z"
                          />
                          <path
                            className="group-hover/item:fill-[#4BAB4D] fill-black dark:fill-neutral-300 transition-all ease-in duration-500 delay-0"
                            d="M25.7715 0H9.22852C4.13998 0 0 4.14005 0 9.22852V25.7715C0 30.86 4.14005 35 9.22852 35H25.7715C30.86 35 35 30.86 35 25.7715V9.22852C35 4.13998 30.86 0 25.7715 0ZM28.8477 22.6953C28.8477 26.0876 26.0876 28.8477 22.6953 28.8477H12.3047C8.91235 28.8477 6.15234 26.0876 6.15234 22.6953V12.3047C6.15234 8.91235 8.91235 6.15234 12.3047 6.15234H22.6953C26.0876 6.15234 28.8477 8.91235 28.8477 12.3047V22.6953Z"
                          />
                        </g>
                      </svg>
                    </Link>
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to="https://www.facebook.com/childrenscouncil.mn"
                      className="relative w-8 flex justify-center items-center"
                    >
                      <svg
                        className="group/item h-8 hover:h-10 absolute transition-all items-center duration-300 ease-in-out"
                        viewBox="0 0 35 35"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          className="group-hover/item:fill-[#4BAB4D] fill-black dark:fill-neutral-300 transition-all ease-in duration-300 delay-0"
                          d="M17.5001 0C7.83516 0 0 7.86792 0 17.5733C0 26.2778 6.30913 33.4867 14.5814 34.8826V21.2395H10.3599V16.3299H14.5814V12.7098C14.5814 8.5094 17.1362 6.22042 20.8682 6.22042C22.6556 6.22042 24.1917 6.35416 24.6376 6.41307V10.8036L22.0492 10.8049C20.02 10.8049 19.6288 11.773 19.6288 13.194V16.3274H24.4706L23.8391 21.237H19.6288V35C28.2873 33.9418 35 26.5493 35 17.5682C35 7.86792 27.1648 0 17.5001 0Z"
                        />
                      </svg>
                    </Link>
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to="https://youtube.com/@mongolianchildrenscouncil"
                      className="relative w-8 flex justify-center items-center"
                    >
                      <svg
                        className="group/item h-8 hover:h-10 absolute transition-all items-center duration-300 ease-in-out"
                        viewBox="0 0 35 35"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="17.5"
                          cy="17.5"
                          r="17.5"
                          className="group-hover/item:fill-[#4BAB4D] fill-black dark:fill-neutral-300 transition-all ease-in duration-500 delay-0"
                        />
                        <g clip-path="url(#clip0_1254_2479)">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M27.7965 14.0355C27.7965 14.0355 27.6012 12.6569 26.999 12.0514C26.2389 11.2555 25.3861 11.2507 24.9954 11.2035C22.1992 11 17.9984 11 17.9984 11C17.9984 11 13.7992 11 10.9997 11.2035C10.609 11.2507 9.75781 11.2539 8.99609 12.0514C8.39062 12.6569 8.19857 14.0355 8.19857 14.0355C8.07488 15.1108 8.00859 16.192 8 17.2744L8 18.7897C8.00875 19.8721 8.07503 20.9533 8.19857 22.0286C8.19857 22.0286 8.39388 23.4072 8.99121 24.0208C9.75293 24.8184 10.7523 24.7923 11.1999 24.877C12.7998 25.0299 18 25.0755 18 25.0755C18 25.0755 22.2041 25.0755 24.9987 24.8688C25.391 24.8216 26.2422 24.8167 27.0039 24.0208C27.6045 23.4154 27.7998 22.0352 27.7998 22.0352C27.9235 20.9598 27.9898 19.8786 27.9984 18.7962V17.2728C27.9875 16.1903 27.9196 15.1092 27.7949 14.0339L27.7965 14.0355Z"
                            className="fill-white dark:fill-black"
                          />
                          <path
                            className="group-hover/item:fill-[#4BAB4D] fill-black dark:fill-neutral-300 transition-all ease-in duration-300 delay-0"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M15.9277 20.6289V15.0088L21.3298 17.8278L15.9277 20.6289Z"
                          />
                        </g>
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </footer>
          <div className=" w-full text-center my-16 dark:text-white">
            All rights reserved © TMULL 2024
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
