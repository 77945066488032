import React from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
// import mhz from '../assets/bid.png';
import gh from "../assets/godhand.jpg";
// import nova from '../assets/nova.png';
// import erdem from '../assets/erdem.svg';
// import tmll from '../assets/tmll.svg';
// import available from '../assets/bolomjtoi.svg';
// import unavailable from '../assets/bolomjgui.svg';
// import availableb from '../assets/bolomjtoib.svg';
// import unavailableb from '../assets/bolomjguib.svg';
// import sbdbg from '../assets/sbd.png';
// import sat from '../assets/sat.png';
// import ielts from '../assets/ielts.png';
// import topik from '../assets/topik.png';
import { useContext } from "react";
export const Video = () => {
  const mergejil = [];

  useEffect(() => {
    document.title = "Tmull ・ Үзэх";
  }, []);
  return (
    <div className="mt-24 lg:mt-28 dark:text-white justify-center items-center flex">
      <div className="flex flex-row justify-center blur-ultra w-96 mt-20">
        <div className="bg-[#66e75a] w-96 h-96 animate-spin"></div>
        <div className="bg-[#FED440] w-96 h-96 rotate-180 mt-20 animate-spin"></div>
        <div className="bg-[#FE7062] w-96 h-96 animate-spin"></div>
        <div className="bg-[#5AC2E7] w-96 h-96 rotate-180 mt-20 animate-spin"></div>
      </div>

      <div className="absolute text-white dark:text-black">Тун удахгүй...</div>
    </div>
  );
};
