import React from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
// import tmll from '../assets/tmll.svg';
// import available from '../assets/bolomjtoi.svg';
// import unavailable from '../assets/bolomjgui.svg';
// import availableb from '../assets/bolomjtoib.svg';
// import unavailableb from '../assets/bolomjguib.svg';
// import sbdbg from '../assets/sbd.png';
// import sat from '../assets/sat.png';
// import ielts from '../assets/ielts.png';
import { useContext } from "react";
import axios from "axios";
export const Login = () => {
  const [see, setSee] = useState(false);
  const [tokenn, setTokenn] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [passValue, setPassValue] = useState("");
  const [erremail, setErremail] = useState(false);
  const [errpass, setErrpass] = useState(false);
  // const [token, setToken] = useState()

  const dataRetriever = async () => {
    await axios({
      url: `https://tmull-be.vercel.app/login`,
      method: "POST",
      data: {
        email: emailValue,
        password: passValue,
      },
      headers: {
        authorization: `Bearer ${tokenn}`,
      },
    }).then((response) => {
      console.log(response?.data);
      if (
        response?.data ===
        "Энэ имэйл бүртгэлгүй тул бүртгэлийн хэсэг рүү орно уу"
      ) {
        setErremail(true);
      } else {
        setErremail(false);
      }
      if (response?.data === "Нууц үг буруу байна") {
        setErrpass(true);
      } else {
        setErrpass(false);
      }
      if (
        response?.data !==
          "Энэ имэйл бүртгэлгүй тул бүртгэлийн хэсэг рүү орно уу" ||
        response?.data !== "Нууц үг буруу байна"
      ) {
        localStorage.setItem("token", response?.data[1]);
        localStorage.setItem("user", response?.data[0]);
        window.location.href = "/";
      }
    });
  };
  useEffect(() => {
    document.title = "Tmull ・ Нэвтрэх";
  }, []);
  return (
    <div className="mt-24 lg:mt-28 font-semibold dark:text-white">
      <div className="flex lg:flex-row flex-col-reverse justify-between w-full rounded-lg border-2 dark:border-stone-900 overflow-hidden">
        <div className="lg:w-[70%] xl:w-[55%] w-full pb-8 lg:py-8 xl:py-14 px-4 lg:pl-8 xl:pl-14 relative text-sm">
          <div className="text-2xl lg:text-3xl">Буцаад тавтай морил!</div>
          <div className="mt-4 lg:mt-6 font-semibold">
            <div
              for="price"
              class="block leading-6 text-gray-900 dark:text-gray-300"
            >
              И-мэйл
            </div>
            <div class="relative rounded-md shadow-sm mt-1">
              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
                <svg
                  className="w-5 lg:w-7"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 4C2.46957 4 1.96086 4.21071 1.58579 4.58579C1.21071 4.96086 1 5.46957 1 6V7.161L9.441 11.382C9.61456 11.4688 9.80595 11.514 10 11.514C10.194 11.514 10.3854 11.4688 10.559 11.382L19 7.162V6C19 5.46957 18.7893 4.96086 18.4142 4.58579C18.0391 4.21071 17.5304 4 17 4H3Z"
                    fill="#636363"
                  />
                  <path
                    d="M19 8.83984L11.23 12.7248C10.8481 12.9158 10.427 13.0153 10 13.0153C9.57301 13.0153 9.1519 12.9158 8.77 12.7248L1 8.83984V14.0008C1 14.5313 1.21071 15.04 1.58579 15.4151C1.96086 15.7901 2.46957 16.0008 3 16.0008H17C17.5304 16.0008 18.0391 15.7901 18.4142 15.4151C18.7893 15.04 19 14.5313 19 14.0008V8.83984Z"
                    fill="#636363"
                  />
                </svg>
              </div>
              <input
                required={erremail}
                type="email"
                autocomplete="email"
                name="email"
                id="email"
                onChange={(e) => setEmailValue(e.target.value)}
                placeholder="you@example.com"
                class="bg-gray-50 peer w-full rounded-md dark:border-neutral-700 border-2 py-3 pl-8 lg:pl-10 text-gray-900 dark:text-white dark:bg-black border-inset border-gray-300 placeholder:text-gray-400 focus:border-[#4BAB4D] text-sm md:text-base xl:text-md sm:leading-6 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 focus:outline-none invalid:border-red-500 invalid:text-red-600 focus:invalid:border-red-500 focus:invalid:ring-red-500"
              />
              {erremail ? (
                <p className="text-red-600 text-sm absolute flex justify-end w-full">
                  Зөв имэйл хаяг оруулна уу.
                </p>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="mt-4 lg:mt-6">
            <div
              for="price"
              class="block leading-6 text-gray-900 dark:text-gray-300"
            >
              Нууц үг
            </div>
            <div class="relative rounded-md shadow-sm mt-1">
              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
                <svg
                  className="w-5 lg:w-6 xl:w-7"
                  width="512"
                  height="512"
                  viewBox="0 0 512 512"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M256 21.9141C191.458 21.9141 138.971 74.4306 138.971 138.943V212.144H124.343C96.1094 212.144 73.1426 235.111 73.1426 263.344V438.887C73.1426 467.12 96.1094 490.087 124.343 490.087H387.657C415.89 490.087 438.857 467.12 438.857 438.887V263.344C438.857 235.111 415.89 212.144 387.657 212.144H373.028V138.943C373.028 74.4306 320.512 21.9141 256 21.9141ZM182.857 138.943C182.857 98.6263 215.669 65.7998 256 65.7998C296.331 65.7998 329.143 98.6263 329.143 138.943V212.144H182.857V138.943ZM175.543 373.205C163.401 373.205 153.6 363.403 153.6 351.262C153.6 339.12 163.401 329.319 175.543 329.319C187.684 329.319 197.485 339.12 197.485 351.262C197.485 363.403 187.684 373.205 175.543 373.205ZM256 373.205C243.858 373.205 234.057 363.403 234.057 351.262C234.057 339.12 243.858 329.319 256 329.319C268.141 329.319 277.943 339.12 277.943 351.262C277.943 363.403 268.141 373.205 256 373.205ZM336.457 373.205C324.315 373.205 314.514 363.403 314.514 351.262C314.514 339.12 324.315 329.319 336.457 329.319C348.599 329.319 358.4 339.12 358.4 351.262C358.4 363.403 348.599 373.205 336.457 373.205Z"
                    fill="#636363"
                  />
                </svg>
              </div>
              <input
                required={errpass}
                type={see ? "password" : "text"}
                name="password"
                id="password"
                autocomplete="current-password"
                onChange={(e) => setPassValue(e.target.value)}
                placeholder="•••••••••"
                class="bg-gray-50 peer w-full rounded-md border-2 py-3 pl-8 lg:pl-10 text-gray-900 dark:bg-black dark:text-white dark:border-neutral-700 border-inset border-gray-300 placeholder:text-gray-400 focus:border-[#4BAB4D] text-sm md:text-base xl:text-md sm:leading-6 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 focus:outline-none invalid:border-red-500 invalid:text-red-600 focus:invalid:border-red-500 focus:invalid:ring-red-500"
              />
              {errpass ? (
                <p class=" text-red-600 text-sm absolute flex justify-end w-full">
                  Нууц үг буруу байна.
                </p>
              ) : (
                <></>
              )}
              <div
                onClick={() => {
                  setSee(!see);
                }}
                class="absolute inset-y-0 right-0 flex items-center pr-3"
              >
                {see ? (
                  <svg
                    className="w-5 lg:w-6 xl:w-7"
                    viewBox="0 0 512 512"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M419.72 419.72L92.26 92.27L92.19 92.19C90.4172 90.4316 88.3154 89.0397 86.0046 88.0935C83.6938 87.1474 81.2192 86.6657 78.7223 86.6759C76.2253 86.6862 73.7548 87.1881 71.4518 88.1531C69.1488 89.1181 67.0584 90.5272 65.3 92.3C63.5416 94.0729 62.1497 96.1747 61.2035 98.4855C60.2574 100.796 59.7757 103.271 59.7859 105.768C59.8066 110.811 61.8296 115.639 65.41 119.19L94.08 147.86C59.3111 171.673 29.3837 201.875 5.89005 236.86C2.05354 242.524 0.00292969 249.209 0.00292969 256.05C0.00292969 262.891 2.05354 269.576 5.89005 275.24C65.86 364 160.93 416 256 415.35C286.795 415.462 317.384 410.335 346.46 400.19L392.87 446.6C396.446 450.06 401.236 451.98 406.212 451.946C411.188 451.912 415.952 449.928 419.48 446.419C423.009 442.91 425.02 438.157 425.081 433.182C425.143 428.206 423.25 423.405 419.81 419.81L419.72 419.72ZM256 363.74C237.988 363.733 220.265 359.213 204.45 350.591C188.636 341.97 175.234 329.522 165.47 314.386C155.706 299.25 149.891 281.908 148.556 263.946C147.222 245.983 150.41 227.973 157.83 211.56L187.78 241.51C185.368 252.878 185.834 264.669 189.136 275.811C192.439 286.953 198.473 297.093 206.69 305.31C214.907 313.528 225.048 319.562 236.19 322.864C247.332 326.166 259.122 326.632 270.49 324.22L300.44 354.17C286.481 360.499 271.327 363.763 256 363.74ZM506.11 236.81C446.14 148 351.07 96 256 96.65C225.205 96.5385 194.616 101.665 165.54 111.81L211.54 157.81C231.501 148.777 253.741 146.042 275.296 149.969C296.85 153.896 316.696 164.299 332.189 179.791C347.681 195.284 358.084 215.13 362.011 236.685C365.939 258.239 363.203 280.479 354.17 300.44L417.91 364.18C452.685 340.371 482.616 310.168 506.11 275.18C509.944 269.517 511.994 262.834 511.994 255.995C511.994 249.156 509.944 242.473 506.11 236.81Z"
                      fill="#636363"
                    />
                    <path
                      d="M256 186.26C251.13 186.26 246.274 186.77 241.51 187.78L324.22 270.48C326.376 260.319 326.236 249.804 323.809 239.705C321.382 229.605 316.73 220.175 310.191 212.103C303.653 204.032 295.395 197.523 286.019 193.051C276.643 188.58 266.387 186.26 256 186.26Z"
                      fill="#636363"
                    />
                  </svg>
                ) : (
                  <svg
                    className="w-5 lg:w-6 xl:w-7"
                    viewBox="0 0 512 512"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M256.002 334.867C299.535 334.867 334.825 299.577 334.825 256.044C334.825 212.511 299.535 177.221 256.002 177.221C212.469 177.221 177.179 212.511 177.179 256.044C177.179 299.577 212.469 334.867 256.002 334.867Z"
                      fill="#636363"
                    />
                    <path
                      d="M504.148 234.211C443.558 160.994 351.842 90.5156 256.002 90.5156C160.143 90.5156 68.4054 161.043 7.85622 234.211C-2.61744 246.862 -2.61744 265.228 7.85622 277.879C23.079 296.274 54.9926 331.784 97.6163 362.811C204.963 440.956 306.805 441.129 414.388 362.811C457.011 331.784 488.925 296.274 504.148 277.879C514.591 265.252 514.645 246.903 504.148 234.211ZM256.002 145.692C316.854 145.692 366.355 195.193 366.355 256.045C366.355 316.896 316.854 366.397 256.002 366.397C195.15 366.397 145.649 316.896 145.649 256.045C145.649 195.193 195.15 145.692 256.002 145.692Z"
                      fill="#636363"
                    />
                  </svg>
                )}
              </div>
            </div>
            <div className="w-full flex flex-row justify-between mt-5 lg:mt-7 text-sm flex-wrap gap-x-2">
              <div class="flex items-start">
                <div class="flex items-center h-5">
                  <input
                    id="remember"
                    type="checkbox"
                    value=""
                    class="w-4 h-4 accent-[#4BAB4D]"
                    required
                  />
                </div>
                <div
                  for="remember"
                  class="ms-1 font-medium text-gray-900 dark:text-gray-300 "
                >
                  И-мэйл санах
                </div>
              </div>
              <a href="/" class="text-[#4BAB4D] hover:underline">
                Нууц үгээ мартсан уу?
              </a>
            </div>
          </div>
          <button
            type="submit"
            onClick={dataRetriever}
            className="mt-4 lg:mt-6 text-white hover:bg-[#4BAB4D] bg-[#037000] focus:outline-none font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center"
          >
            Нэвтрэх
          </button>
          {/* <div class="py-3 flex items-center text-xs text-gray-400 uppercase before:flex-1 before:border-t before:border-gray-200 before:me-6 after:flex-1 after:border-t after:border-gray-200 after:ms-6 dark:text-neutral-500 dark:before:border-neutral-600 dark:after:border-neutral-600">
            ЭСВЭЛ
          </div>
          <button className="mt-4 dark:bg-neutral-900 dark:border-neutral-700 dark:hover:bg-neutral-800 dark:text-neutral-300 lg:mt-6 border-2 focus:outline-none font-semibold rounded-lg text-sm w-full px-5 py-2.5 text-center flex flex-row items-center justify-center gap-2">
            <svg
              className="h-6"
              viewBox="0 0 512 512"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1721_2716)">
                <path
                  d="M113.47 309.408L95.648 375.94L30.509 377.318C11.042 341.211 0 299.9 0 256C0 213.549 10.324 173.517 28.624 138.268H28.638L86.63 148.9L112.034 206.544C106.717 222.045 103.819 238.685 103.819 256C103.821 274.792 107.225 292.797 113.47 309.408Z"
                  fill="#FBBB00"
                />
                <path
                  d="M507.527 208.176C510.467 223.662 512 239.655 512 256C512 274.328 510.073 292.206 506.402 309.451C493.94 368.134 461.377 419.376 416.268 455.638L416.254 455.624L343.21 451.897L332.872 387.362C362.804 369.808 386.196 342.337 398.518 309.451H261.628V208.176H507.527Z"
                  fill="#518EF8"
                />
                <path
                  d="M416.253 455.623L416.267 455.637C372.396 490.9 316.666 511.999 256 511.999C158.509 511.999 73.7478 457.508 30.5088 377.318L113.47 309.408C135.089 367.106 190.748 408.179 256 408.179C284.047 408.179 310.323 400.597 332.87 387.361L416.253 455.623Z"
                  fill="#28B446"
                />
                <path
                  d="M419.404 58.936L336.471 126.832C313.136 112.246 285.552 103.82 256 103.82C189.271 103.82 132.571 146.777 112.035 206.544L28.638 138.268H28.624C71.23 56.123 157.06 0 256 0C318.115 0 375.068 22.126 419.404 58.936Z"
                  fill="#F14336"
                />
              </g>
              <defs>
                <clipPath id="clip0_1721_2716">
                  <rect width="512" height="512" fill="white" />
                </clipPath>
              </defs>
            </svg>
            Google-ээр нэвтрэх
          </button> */}
          <div className="w-full flex flex-col md:flex-row mt-4 lg:mt-6 text-sm gap-x-1.5 justify-center items-center flex-wrap">
            <div
              for="remember"
              class="ms-1 font-medium text-gray-900 dark:text-gray-300"
            >
              Аккаунт үүсгээгүй юу?
            </div>
            <a href="/burtguuleh" class="text-[#4BAB4D] hover:underline">
              Шинэ хэрэглэгчээр бүртгүүлэх
            </a>
          </div>
        </div>
        <div className="w-full preview bg-cover h-36 lg:h-auto relative flex justify-center items-center">
          <div className="bg-gradient-to-t lg:bg-gradient-to-r from-[#ffffff] dark:from-[#000000] via-[#00000000] to-[#00000000] h-full w-full absolute group-hover/item:opacity-0 opacity-100 transition-all items-center ease-in-out duration-1000 delay-0 "></div>
          {/* <div className="absolute text-white text-2xl lg:text-4xl xl:text-5xl text-center drop-shadow-xl w-[80%]">
						<div className="drop-shadow-lg">
							<div className="drop-shadow-lg">
								<div className="drop-shadow-lg">
									<div className="drop-shadow-lg hidden lg:block">
										Илүү ихийг олж
										<br />
										мэдэхийн тулд
										<br /> нэвтэрнэ үү
									</div>
								</div>
							</div>
						</div>
					</div> */}
        </div>
      </div>
    </div>
  );
};
