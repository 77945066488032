import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import { useState, useEffect, useRef } from "react";
// import mhz from '../assets/bid.png';
import gh from "../assets/godhand.jpg";
import nova from "../assets/nova.png";
import erdem from "../assets/erdem.svg";
// import tmll from '../assets/tmll.svg';
// import available from '../assets/bolomjtoi.svg';
// import unavailable from '../assets/bolomjgui.svg';
// import availableb from '../assets/bolomjtoib.svg';
// import unavailableb from '../assets/bolomjguib.svg';
// import sbdbg from '../assets/sbd.png';
// import sat from '../assets/sat.png';
// import ielts from '../assets/ielts.png';
// import topik from '../assets/topik.png';
import { useContext } from "react";
import Comment from "./comment";
import axios from "axios";
export const Activities = () => {
  const mergejil = [];
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const location = useLocation();
  const bairshil = location.pathname;
  const mbti = [
    {
      n: "intj",
      t: "Шинжээчид",
      c: "Архитектор",
      d: "Бүх зүйлийг төлөвлөдөг уран сэтгэмж болон стратегитай сэтгэгчид.",
      i: "Intj.png?alt=media&token=c88eea74-fb45-4375-a748-8cf612741d1c",
    },
    {
      n: "enfj",
      t: "Дипломатууд",
      c: "Гол дүр",
      d: "Сонсогчдыг гайхшруулж чаддаг сэтгэл татам, урам зориг өгдөг манлайлагчид.",
      i: "enfj.png?alt=media&token=8dac02e3-2181-4961-aa47-04e888e24a24",
    },
    {
      n: "enfp",
      t: "Дипломатууд",
      c: "Сурталчлагч",
      d: "Инээмсэглэх шалтгаан үргэлж олж чаддаг урам зоригтой, бүтээлч, нийтэч эрх чөлөөтэй хүмүүс.",
      i: "enfp.png?alt=media&token=2cdd91a0-900c-4f0c-a4bc-c3f047d52303",
    },
    {
      n: "entj",
      t: "Шинжээчид",
      c: "Командлагч",
      d: "Үргэлж арга зам олдог эсвэл бий болгодог зоригтой, уран сэтгэмжтэй бөгөөд хүсэл эрмэлзэлтэй манлайлагчид.",
      i: "entj.png?alt=media&token=1bb3c7d9-6599-4b5d-8590-ad06df9288cf",
    },
    {
      n: "entp",
      t: "Шинжээчид",
      c: "Мэтгэлцэгч",
      d: "Оюунлаг сорилтоос буцдаггүй ухаалаг, сониуч сэтгэгчид.",
      i: "entp.png?alt=media&token=3b1fcab9-bfeb-4098-83eb-2ef3d05e85f1",
    },
    {
      n: "esfj",
      t: "Хамгаалагчид",
      c: "Зөвлөгч",
      d: "Үргэлж бусдад туслахыг хүсдэг гайхалтай халамжтай, нийтэч бөгөөд алдартай хүмүүс.",
      i: "esfj.png?alt=media&token=c52128a3-017e-4c05-bbdb-5e752d9d545c",
    },
    {
      n: "esfp",
      t: "Судлаачид",
      c: "Хөгжөөгч",
      d: "Тэдний хажууд байхад хэзээ ч уйдахааргүй гэнэтийн, эрч хүчтэй, урам зоригтой хүмүүс.",
      i: "esfp.png?alt=media&token=f26384a5-ec61-42d9-ba49-5d9bd26ab5ca",
    },
    {
      n: "estj",
      t: "Хамгаалагчид",
      c: "Гүйцэтгэх ажилтан",
      d: "Аливаа зүйлийг эсвэл хүмүүсийг удирдах харьцуулшгүй чадвартай маш сайн удирдагчид.",
      i: "estj.png?alt=media&token=3e08bddf-7aac-44bf-991e-ba3f17bb2763",
    },
    {
      n: "estp",
      t: "Судлаачид",
      c: "Бизнес эрхлэгч",
      d: "Эрсдэл хийх дуртай ухаалаг, эрч хүчтэй бөгөөд маш авхаалжтай хүмүүс.",
      i: "estp.png?alt=media&token=548e65fb-2029-45ed-93b6-24a45f2d9695",
    },
    {
      n: "infj",
      t: "Дипломатууд",
      c: "Өмгөөлөгч",
      d: "Чимээгүй, нууцлаг хэрнээ маш их урам зориг өгдөг цуцашгүй идеалистууд.",
      i: "infj.png?alt=media&token=300cf395-9a77-4058-905a-577b414f43ea",
    },
    {
      n: "infp",
      t: "Дипломатууд",
      c: "Дундыг баримтлагч",
      d: "Сайн үйлсэд туслахыг үргэлж хүсдэг яруу найрагч, сайхан сэтгэлтэй, энэрэнгүй өглөгч хүмүүс.",
      i: "infp.png?alt=media&token=343b59eb-38de-4708-a732-8acb1ab1da9d",
    },
    {
      n: "intp",
      t: "Шинжээчид",
      c: "Логик сэтгэгч",
      d: "Мэдлэгийн цангаа нь тайлагдашгүй шинэлэг зохион бүтээгчид.",
      i: "intp.png?alt=media&token=a52443f6-d8a6-4532-b866-3c77a4f249bb",
    },
    {
      n: "isfj",
      t: "Хамгаалагчид",
      c: "Хамгаалагч",
      d: "Хайртай дотны хүмүүсээ хамгаалахад үргэлж бэлэн байдаг маш шаргуу хөдөлмөрч, халуун дулаан сэтгэлтэй хамгаалагчид.",
      i: "isfj.png?alt=media&token=3bdfb98c-f0a1-49e5-b7fd-b05e737705bb",
    },
    {
      n: "isfp",
      t: "Судлаачид",
      c: "Адал явдал хайгч",
      d: "Аливаа шинэ зүйлийг судалж, туршихад үргэлж бэлэн байдаг уян хатан, сэтгэл татам уран бүтээлчид.",
      i: "isfp.png?alt=media&token=5ebdb2d0-725f-4a4d-9104-66512800981a",
    },
    {
      n: "istj",
      t: "Хамгаалагчид",
      c: "Логистикч",
      d: "Найдвартай байдалд нь эргэлзэх шаардлагагүй прагматик, бодит сэтгэлгээтэй хүмүүс.",
      i: "istj.png?alt=media&token=cf61db0e-5bd5-4c48-8fbc-2f4e2b4c4cb6",
    },
    {
      n: "istp",
      t: "Судлаачид",
      c: "Урлагийн мэдрэмжтэн",
      d: "Бүх төрлийн арга хэрэгслийг чадварлаг эзэмшсэн зоригтой бөгөөд прагматик туршигчид.",
      i: "istp.png?alt=media&token=f55080fd-684b-4ae2-a1ad-5ad885c93cde",
    },
  ];
  useEffect(() => {
    document.title = "Tmull ・ Шүдний эмч";

    const dataRetriever = async () => {
      try {
        const response = await axios.get(
          `https://tmull-be.vercel.app/prof/${bairshil.split("/")[2]}`
        );

        if (response.status) {
          console.log(response?.data);
          setData(response?.data.data);
          setLoad(true);
        }
      } catch (error) {
        console.error("Error retrieving data:", error);
        // window.location.href = '/';
      }
    };

    dataRetriever();
  }, []);
  console.log(data);

  return (
    <div className="mt-24 lg:mt-28 dark:text-white">
      {load ? (
        <div>
          <div className="group/item relative flex justify-center flex-col items-center">
            <div className="flex justify-center items-center group/item cursor-pointer relative p-8">
              <div
                key="1"
                className="rounded-2xl w-32 h-32 lg:w-44 lg:h-44 ring-4 ring-[#656366] ring-offset-4 dark:ring-offset-black rotate-45 group-hover/item:rotate-0 group-hover/item:ring-[#4BAB4D] transition delay-150 duration-500 ease-in-out"
              ></div>
              <img
                src={`../${data.img}`}
                className="lg:w-36 lg:h-36 w-24 h-24 absolute mb-6 group-hover/item:mb-0 group-hover/item:grayscale-0 transition-all delay-150 duration-500 ease-in-out"
                alt=""
              />
            </div>
            <div className="capitalize font-semibold text-center drop-shadow absolute">
              <div class=" lg:group-hover/item:mt-60 group-hover/item:mt-48 lg:mt-72 mt-56 lg:group-hover/item:text-3xl group-hover/item:text-2xl transition-all delay-150 duration-500 ease-in-out text-2xl">
                {data.na}
              </div>
            </div>
          </div>
          <div className="mt-8 flex justify-center items-center flex-col">
            <div className="flex flex-row items-center justify-center p-2 lg:w-[60%] text-center">
              <div className="text-md lg:text-lg text-[#7c7c7c] text-center xl:w-[60%] lg:w-[70%] w-[80%]">
                Уг мэргэжилтэй холбоотой мэдээлэл
              </div>
            </div>
            <div className="mt-8 gap-16 flex flex-col md:w-[80%] text-justify lg:text-lg">
              <div className="gap-2 flex flex-col">
                <div className="text-xl	lg:text-3xl text-center">
                  Мэргэжлийн Тодорхойлолт
                </div>
                <div>{data.di}</div>
              </div>
              <div className="gap-2 flex flex-col justify-center items-center">
                <div className="text-xl	lg:text-3xl text-center">
                  Уг мэргэжил дээрх шилдэг их сургуулиуд
                </div>
                <div className="overflow-x-auto h-60 flex flex-row gap-10 w-96 md:w-full snap-x mt-4">
                  {data.sch.map((el) => {
                    return (
                      <div className="w-60 h-60">
                        <div className="flex flex-col justify-center items-center relative w-60 bg-slate-500/10 overflow-hidden h-60 group/item rounded-lg">
                          <img
                            className="w-full h-full p-2 blur-3xl absolute -z-10 transition-all dark:brightness-125 ease-out duration-500 delay-0 transform"
                            src={`https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/sch%2F${el.img}`}
                            alt=""
                          />
                          <img
                            className="group-hover/item:w-24 group-hover/item:h-24 group-hover/item:lg:w-32 group-hover/item:lg:h-32 bg-white object-cover w-full h-full rounded-lg  transition-all ease-out-in duration-500 delay-0 transform"
                            src={`https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/sch%2F${el.img}`}
                            alt=""
                          />
                          <div className="w-[80%] group-hover/item:mt-1 text-center group-hover/item:h-16 h-0 transition-all ease-in-out duration-500 delay-0 transform">
                            {el.n}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="gap-2 flex flex-col justify-center items-center">
                <div className="text-xl	lg:text-3xl text-center">
                  Хийж болох хичээлээс гадуурх үйл ажиллагаа
                </div>
                <div className="flex flex-row overflow-x-auto w-96 md:w-full gap-5 lg:gap-10 text-lg lg:text-xl mt-2 snap-x md:snap-none">
                  {data.tl.map((el) => {
                    return (
                      <div className="snap-end">
                        <div className="flex flex-col items-center group/item gap-2 xl:w-96 lg:w-72 md:w-60 w-52 h-min relative bg-slate-500/10 overflow-hidden rounded-lg">
                          <img
                            src={`https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/Prof%2F${
                              el.n === 1
                                ? "club.png?alt=media&token=8df33618-7120-493f-9856-543c35e65191"
                                : el.n === 2
                                ? "olympiad.png?alt=media&token=99f58327-d3e5-45f0-8d15-cf4f035c00b0"
                                : el.n === 3
                                ? "research.png?alt=media&token=d3f0d319-523d-4f33-8c69-fc1793b30665"
                                : el.n === 4
                                ? "volunteer.png?alt=media&token=76fb194c-9ff9-4274-bb2e-c01a69cdf0d2"
                                : el.n === 5
                                ? "exp.png?alt=media&token=0021900a-df1a-40c7-a857-73fcc17ba40f"
                                : el.n === 6
                                ? "6.png?alt=media&token=3f136521-6174-429e-9b1e-5ceb586355cf"
                                : el.n === 7
                                ? "7.png?alt=media&token=4d778928-8d3c-4687-84f5-02d617295221"
                                : el.n === 8
                                ? "8.png?alt=media&token=5874c908-fd0c-458d-ad62-424aba11858a"
                                : el.n === 10
                                ? "10.png?alt=media&token=8a560342-fde8-4687-b5f9-4b907cbe83b8"
                                : el.n === 11
                                ? "11.png?alt=media&token=4ae43f41-9af7-4055-aa67-5161b4c5fa8d"
                                : el.n === 12
                                ? "12.png?alt=media&token=ed4d4dbc-64c5-461f-a462-976f8eca38c1"
                                : el.n === 13
                                ? "13.png?alt=media&token=83d7af82-0a37-4e32-99be-46db3e56ce31"
                                : el.n === 14
                                ? "14.png?alt=media&token=5e0d3e06-8517-4654-a593-644d08b85054"
                                : el.n === 15
                                ? "15.png?alt=media&token=fc0c3415-038b-4840-9639-9e706bc31f19"
                                : el.n === 16
                                ? "16.png?alt=media&token=20802c4c-a3b5-415c-a2b1-12003bbc009d"
                                : el.n === 17
                                ? "17.png?alt=media&token=479965f0-6d6e-4ab5-a05a-7ae679b98fd5"
                                : el.n === 18
                                ? "18.png?alt=media&token=7b4677d1-2932-43e4-ada3-cc8d3e1191db"
                                : el.n === 19
                                ? "19.png?alt=media&token=4b4d1c8f-dcf7-49ef-9115-80adf0f0a599"
                                : el.n === 20
                                ? "20.png?alt=media&token=511451a0-d040-4b13-90af-cd338cdb6b01"
                                : el.n === 21
                                ? "21.png?alt=media&token=94413766-ac94-4687-bdc1-88465fbb20ad"
                                : "22.png?alt=media&token=555083b6-a55a-41f4-b419-d7855ef8d097"
                            }`}
                            className="xl:w-96 xl:h-96 lg:w-72 lg:h-72 md:w-60 md:h-60 w-52 h-52"
                            alt=""
                          />
                          <div className="p-4 pt-24 w-full group-hover/item:opacity-0 text-justify opacity-100 absolute bg-gradient-to-b from-[#00000000] dark:to-[#000000] dark:text-white text-black to-[#ffffff] bottom-0 group-hover/item:blur blur-0 transition-all ease-out duration-500 delay-0 transform">
                            {el.t}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="gap-2 flex flex-col justify-center items-center">
                <div className="text-xl	lg:text-3xl text-center">CHECK LIST</div>
                <div className="flex flex-wrap gap-4 2xl:gap-10 justify-center text-sm lg:text-base mt-2 ">
                  {data.reqq.map((el) => {
                    const randomHue = Math.floor(Math.random() * 360); // Random hue for variety
                    const color = `hsla(${randomHue}, 70%, 80%, 0.3)`; // Soft pastel colors with 50% transparency
                    return (
                      <div
                        key={el} // Always include a key when mapping over arrays
                        style={{ backgroundColor: color }}
                        className="lg:w-48 flex justify-center items-center h-36 w-36 lg:h-48 border-2 p-2 text-justify rounded-lg dark:border-neutral-700"
                      >
                        {el}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="gap-2 flex flex-col">
                <div className="text-xl	lg:text-3xl text-center">
                  Алдартай хүмүүс
                </div>
                <div className="flex 2xL:h-[600px] xl:h-[500px] lg:h-[400px] h-[300px]">
                  {data.pl.map((el) => {
                    return (
                      <div className="flex flex-row group/item first:rounded-l-lg last:rounded-r-lg overflow-hidden hover:w-[300%] w-full relative transition-all ease-out duration-500 delay-0 transform">
                        <img
                          className=" object-cover group-hover/item:brightness-100 brightness-50  w-full h-full transition-all ease-out duration-500 delay-0 transform"
                          src={`https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/Prof%2F${el.img}`}
                          alt=""
                        />
                        <div className="group-hover/item:opacity-100 opacity-0 absolute w-full bottom-0 p-2 text-white bg-[#00000080] transition-all ease-out duration-300 delay-300 transform">
                          {el.p}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="flex-col w-full flex">
                <div className="text-xl	lg:text-3xl text-center">Бичлэг</div>
                <div className="flex flex-wrap gap-4 2xl:justify-between justify-center items-center mt-4">
                  {data.vid.map((el) => {
                    let videoId = "";

                    if (Object.values(el)[0].includes("youtu.be")) {
                      videoId = Object.values(el)[0]
                        .split("youtu.be/")[1]
                        .split("?")[0]; // For shortened URLs
                    } else if (Object.values(el)[0].includes("v=")) {
                      videoId = Object.values(el)[0]
                        .split("v=")[1]
                        ?.split("&")[0]; // For regular URLs
                    }

                    const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;

                    return (
                      <div className="relative w-96 aspect-video rounded-lg overflow-hidden">
                        <a
                          target="_blank"
                          className="hover:text-[#83b160] w-full bottom-0 text-white uppercase absolute underline p-1 lg:p-2 bg-[#0000009b]"
                          rel="noopener noreferrer"
                          href={Object.values(el)[0]}
                        >
                          {Object.keys(el)[0]}
                        </a>
                        <img
                          src={thumbnailUrl}
                          alt="YouTube Thumbnail"
                          className=" object-cover h-auto w-full"
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="flex flex-col items-center justify-center">
                <div className="text-xl	lg:text-3xl text-center mb-2">
                  Энэ мэргэжилд илүү нийцдэг MBTI
                </div>
                <div className="flex sm:flex-row flex-col xl:text-2xl gap-2 justify-center">
                  {data.mbti.map((el) => {
                    const element = mbti.find(
                      (item) => item.n.toLowerCase() === el.toLowerCase()
                    );
                    return element ? (
                      <div
                        style={{
                          backgroundColor:
                            element.t === "Шинжээчид"
                              ? "#e7dfea"
                              : element.t === "Хамгаалагчид"
                              ? "#d9eaf0"
                              : element.t === "Судлаачид"
                              ? "#f9eed7"
                              : "#d6ece3",
                          color:
                            element.t === "Шинжээчид"
                              ? "#88619a"
                              : element.t === "Хамгаалагчид"
                              ? "#4298b4"
                              : element.t === "Судлаачид"
                              ? "#e4ae3a"
                              : "#33a474",
                        }}
                        className="text-center text-[#e7dfea7a] rounded lg:p-4 p-2  w-full flex justify-start items-center flex-col"
                      >
                        <div className="lg:text-2xl xl:text-4xl">
                          {element.t}
                        </div>
                        <img
                          className="brightness-125 dark:brightness-100 h-36 lg:h-44 xl:h-80 object-cover"
                          src={
                            "https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/Prof%2Fmbti%2F" +
                            element.i
                          }
                          alt=""
                        />
                        <div className="text-[#343c4b] lg:text-xl xl:text-3xl">
                          {element.c}
                        </div>
                        <div className="uppercase">{element.n}</div>

                        <div className="text-[#787f8d] w-full font-normal text-base lg:text-lg text-justify">
                          {element.d}
                        </div>
                      </div>
                    ) : null;
                  })}
                </div>
              </div>
            </div>
          </div>
          <Comment id={data._id} />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
