import React from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { formatDistanceToNow } from "date-fns";
import { Helmet } from "react-helmet";
// import mhz from '../assets/bid.png';
import gh from "../assets/godhand.jpg";
// import nova from '../assets/nova.png';
// import erdem from '../assets/erdem.svg';
// import tmll from '../assets/tmll.svg';
// import available from '../assets/bolomjtoi.svg';
// import unavailable from '../assets/bolomjgui.svg';
// import availableb from '../assets/bolomjtoib.svg';
// import unavailableb from '../assets/bolomjguib.svg';
// import sbdbg from '../assets/sbd.png';
// import sat from '../assets/sat.png';
// import ielts from '../assets/ielts.png';
// import topik from '../assets/topik.png';
import { useContext } from "react";
import { Article } from "./article";
import axios from "axios";
export const News = () => {
  const [ar, setAr] = useState(true);
  const [data, setData] = useState([]);
  const mergejil = [];

  useEffect(() => {
    if (window.location.pathname === "") {
      setAr(false);
    } else {
      console.log("no");
    }
    document.title = "Tmull ・ Мэдээ";
    const dataRetriever = async () => {
      await axios.get(`https://tmull-be.vercel.app/news`).then((response) => {
        console.log(response?.data);
        setData(response?.data);
      });
    };
    dataRetriever();
  }, []);
  return (
    <div className="mt-24 lg:mt-28 dark:text-white">
      <Helmet>
        <meta property="og:title" content="Сүүлд нэмэгдсэн нийтлэл" />
        <meta property="og:type" content="article" />
        <meta property="og:url" content="https://www.tmull.mn/niitleluud" />
        <meta
          property="og:image"
          content="https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/news.png?alt=media&token=293aa618-2dd5-4c85-b8ce-23040ffa4765"
        />
        <meta property="og:site_name" content="Tmull.mn" />
      </Helmet>
      <div>
        <div>
          <div className="font-semibold flex flex-row items-center justify-center mb-4">
            <div className="text-2xl lg:text-4xl">Сүүлд нэмэгдсэн</div>
          </div>

          <div className="flex flex-row gap-4 overflow-x-auto w-full">
            {data.length > 0 ? (
              <div className="flex flex-row gap-4 w-full">
                {data.map((el) => (
                  <a href={"/niitleluud/" + el.pl}>
                    <div className="w-80 h-80 lg:w-96 lg:h-96 rounded-lg relative overflow-hidden group/item cursor-pointer">
                      <img
                        className="w-80 h-80 lg:w-96 lg:h-96 object-cover absolute"
                        src={
                          "https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/News%2F" +
                          el.img
                        }
                        alt=""
                      />
                      <div className="m-2 flex flex-row right-0 absolute text-sm text-white gap-0.5">
                        <div className=" flex flex-row w-min items-center justify-center gap-1 backdrop-blur-sm rounded-l px-1 bg-[#4BAB4D]">
                          <svg
                            className="h-3.5 fill-white"
                            viewBox="0 0 512 512"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M256 410.667C306.074 410.667 346.667 370.074 346.667 320.001C346.667 269.927 306.074 229.334 256 229.334C205.926 229.334 165.333 269.927 165.333 320.001C165.333 370.074 205.926 410.667 256 410.667Z" />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M480.64 276.693L448.341 244.373C342.101 138.155 169.899 138.155 63.6588 244.373L31.3601 276.693C29.8753 278.178 28.6975 279.941 27.8939 281.881C27.0904 283.821 26.6768 285.9 26.6768 288C26.6768 290.1 27.0904 292.179 27.8939 294.119C28.6975 296.059 29.8753 297.822 31.3601 299.307C32.8449 300.792 34.6077 301.969 36.5477 302.773C38.4877 303.577 40.567 303.99 42.6668 303.99C44.7666 303.99 46.8459 303.577 48.7859 302.773C50.7259 301.969 52.4887 300.792 53.9735 299.307L86.2935 267.008C180.011 173.269 331.989 173.269 425.707 267.008L458.027 299.307C461.026 302.306 465.093 303.99 469.333 303.99C473.574 303.99 477.641 302.306 480.64 299.307C483.639 296.308 485.323 292.241 485.323 288C485.323 283.759 483.639 279.692 480.64 276.693Z"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M402.133 146.496L370.133 201.92C368.208 205.585 367.775 209.853 368.926 213.829C370.078 217.805 372.724 221.182 376.309 223.251C379.895 225.319 384.143 225.92 388.161 224.926C392.18 223.932 395.658 221.422 397.867 217.92L429.867 162.496C431.988 158.818 432.562 154.449 431.462 150.348C430.362 146.247 427.678 142.751 424 140.629C420.322 138.508 415.953 137.934 411.852 139.034C407.751 140.134 404.255 142.818 402.133 146.496ZM240 117.333V181.333C240 185.577 241.686 189.647 244.686 192.647C247.687 195.648 251.757 197.333 256 197.333C260.244 197.333 264.313 195.648 267.314 192.647C270.314 189.647 272 185.577 272 181.333V117.333C272 113.09 270.314 109.02 267.314 106.02C264.313 103.019 260.244 101.333 256 101.333C251.757 101.333 247.687 103.019 244.686 106.02C241.686 109.02 240 113.09 240 117.333ZM82.1334 162.496L114.133 217.92C116.342 221.422 119.82 223.932 123.839 224.926C127.858 225.92 132.105 225.319 135.691 223.251C139.277 221.182 141.922 217.805 143.074 213.829C144.225 209.853 143.793 205.585 141.867 201.92L109.867 146.496C108.816 144.675 107.417 143.079 105.75 141.799C104.082 140.518 102.179 139.579 100.148 139.034C98.1179 138.489 96 138.35 93.9156 138.624C91.8312 138.897 89.8211 139.579 88.0001 140.629C86.1791 141.68 84.5829 143.079 83.3025 144.747C82.0222 146.414 81.0828 148.317 80.538 150.348C79.9933 152.378 79.8538 154.496 80.1275 156.581C80.4012 158.665 81.0829 160.675 82.1334 162.496Z"
                            />
                          </svg>
                          {el.s}
                        </div>
                        <div className="backdrop-blur-sm rounded-r px-1 bg-black/40">
                          {el.rt}
                        </div>
                      </div>

                      <div className="from-[#000000] to-[#00000000] w-full bg-gradient-to-t h-[90%] group-hover/item:h-full group-hover/item:opacity-100 opacity-90 absolute bottom-0 transition-all ease-in-out duration-500 delay-0 transform"></div>
                      <div className="absolute bottom-0 p-2 w-full text-white">
                        <div className="font-bold text-lg md:text-xl">
                          {el.ti}
                        </div>
                        <div className="group-hover/item:h-16 overflow-scroll h-5 group-hover/item:whitespace-normal whitespace-nowrap font-normal text-[#cecece] w-full transition-all ease-in-out duration-700 delay-0 transform text-ellipsis group-hover/item:text-clip group-hover/item:overflow-auto text-xs lg:text-sm">
                          {el.di}
                        </div>
                        <div className="font-normal text-[#7c7c7c] truncate group-hover/item:h-5 h-0 lg:group-hover/item:h-6 group-hover/item:opacity-100 opacity-0 transition-all ease-in-out duration-300 delay-0 transform">
                          {formatDistanceToNow(new Date(el.cAt), {
                            addSuffix: true,
                          })
                            .replace(
                              "less than a minute ago",
                              "минут хүрэхгүй хугацааны өмнө"
                            )
                            .replace("about ", "")
                            .replace("days", "өдрийн")
                            .replace("hours", "цагийн")
                            .replace("hour", "цагийн")
                            .replace("minutes", "минутын")
                            .replace("minute", "минутын")
                            .replace("months", "сарын")
                            .replace("month", "сарын")
                            .replace("ago", "өмнө")}{" "}
                          | {el.au.map((el) => el).join(", ")}
                        </div>
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            ) : (
              <div className="flex flex-row gap-4 w-full">
                <div className="rounded-lg bg-black/25 dark:bg-white/25">
                  <div className="w-80 h-80 lg:w-96 lg:h-96 animate-pulse  relative overflow-hidden group/item cursor-pointer">
                    <div className="m-2 flex flex-row right-0 absolute text-sm text-white gap-0.5">
                      <div className=" flex flex-row w-6 items-center justify-center gap-1 backdrop-blur-sm rounded-l px-1 bg-[#4BAB4D]">
                        <svg
                          className="h-3.5 fill-white"
                          viewBox="0 0 512 512"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M256 410.667C306.074 410.667 346.667 370.074 346.667 320.001C346.667 269.927 306.074 229.334 256 229.334C205.926 229.334 165.333 269.927 165.333 320.001C165.333 370.074 205.926 410.667 256 410.667Z" />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M480.64 276.693L448.341 244.373C342.101 138.155 169.899 138.155 63.6588 244.373L31.3601 276.693C29.8753 278.178 28.6975 279.941 27.8939 281.881C27.0904 283.821 26.6768 285.9 26.6768 288C26.6768 290.1 27.0904 292.179 27.8939 294.119C28.6975 296.059 29.8753 297.822 31.3601 299.307C32.8449 300.792 34.6077 301.969 36.5477 302.773C38.4877 303.577 40.567 303.99 42.6668 303.99C44.7666 303.99 46.8459 303.577 48.7859 302.773C50.7259 301.969 52.4887 300.792 53.9735 299.307L86.2935 267.008C180.011 173.269 331.989 173.269 425.707 267.008L458.027 299.307C461.026 302.306 465.093 303.99 469.333 303.99C473.574 303.99 477.641 302.306 480.64 299.307C483.639 296.308 485.323 292.241 485.323 288C485.323 283.759 483.639 279.692 480.64 276.693Z"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M402.133 146.496L370.133 201.92C368.208 205.585 367.775 209.853 368.926 213.829C370.078 217.805 372.724 221.182 376.309 223.251C379.895 225.319 384.143 225.92 388.161 224.926C392.18 223.932 395.658 221.422 397.867 217.92L429.867 162.496C431.988 158.818 432.562 154.449 431.462 150.348C430.362 146.247 427.678 142.751 424 140.629C420.322 138.508 415.953 137.934 411.852 139.034C407.751 140.134 404.255 142.818 402.133 146.496ZM240 117.333V181.333C240 185.577 241.686 189.647 244.686 192.647C247.687 195.648 251.757 197.333 256 197.333C260.244 197.333 264.313 195.648 267.314 192.647C270.314 189.647 272 185.577 272 181.333V117.333C272 113.09 270.314 109.02 267.314 106.02C264.313 103.019 260.244 101.333 256 101.333C251.757 101.333 247.687 103.019 244.686 106.02C241.686 109.02 240 113.09 240 117.333ZM82.1334 162.496L114.133 217.92C116.342 221.422 119.82 223.932 123.839 224.926C127.858 225.92 132.105 225.319 135.691 223.251C139.277 221.182 141.922 217.805 143.074 213.829C144.225 209.853 143.793 205.585 141.867 201.92L109.867 146.496C108.816 144.675 107.417 143.079 105.75 141.799C104.082 140.518 102.179 139.579 100.148 139.034C98.1179 138.489 96 138.35 93.9156 138.624C91.8312 138.897 89.8211 139.579 88.0001 140.629C86.1791 141.68 84.5829 143.079 83.3025 144.747C82.0222 146.414 81.0828 148.317 80.538 150.348C79.9933 152.378 79.8538 154.496 80.1275 156.581C80.4012 158.665 81.0829 160.675 82.1334 162.496Z"
                          />
                        </svg>
                      </div>
                      <div className="backdrop-blur-sm rounded-r px-1 w-6 bg-black/40"></div>
                    </div>

                    <div className="absolute bottom-0 p-2 w-full flex flex-col gap-2 text-white">
                      <div class="h-8 bg-white/25 rounded w-[40%]"></div>
                      <div class="h-8 bg-white/25 rounded w-full"></div>
                      <div class="h-4 bg-white/25 rounded w-full mt-1"></div>
                    </div>
                  </div>
                </div>
                <div className="rounded-lg bg-black/25 dark:bg-white/25">
                  <div className="w-80 h-80 lg:w-96 lg:h-96 animate-pulse  relative overflow-hidden group/item cursor-pointer">
                    <div className="m-2 flex flex-row right-0 absolute text-sm text-white gap-0.5">
                      <div className=" flex flex-row w-6 items-center justify-center gap-1 backdrop-blur-sm rounded-l px-1 bg-[#4BAB4D]">
                        <svg
                          className="h-3.5 fill-white"
                          viewBox="0 0 512 512"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M256 410.667C306.074 410.667 346.667 370.074 346.667 320.001C346.667 269.927 306.074 229.334 256 229.334C205.926 229.334 165.333 269.927 165.333 320.001C165.333 370.074 205.926 410.667 256 410.667Z" />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M480.64 276.693L448.341 244.373C342.101 138.155 169.899 138.155 63.6588 244.373L31.3601 276.693C29.8753 278.178 28.6975 279.941 27.8939 281.881C27.0904 283.821 26.6768 285.9 26.6768 288C26.6768 290.1 27.0904 292.179 27.8939 294.119C28.6975 296.059 29.8753 297.822 31.3601 299.307C32.8449 300.792 34.6077 301.969 36.5477 302.773C38.4877 303.577 40.567 303.99 42.6668 303.99C44.7666 303.99 46.8459 303.577 48.7859 302.773C50.7259 301.969 52.4887 300.792 53.9735 299.307L86.2935 267.008C180.011 173.269 331.989 173.269 425.707 267.008L458.027 299.307C461.026 302.306 465.093 303.99 469.333 303.99C473.574 303.99 477.641 302.306 480.64 299.307C483.639 296.308 485.323 292.241 485.323 288C485.323 283.759 483.639 279.692 480.64 276.693Z"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M402.133 146.496L370.133 201.92C368.208 205.585 367.775 209.853 368.926 213.829C370.078 217.805 372.724 221.182 376.309 223.251C379.895 225.319 384.143 225.92 388.161 224.926C392.18 223.932 395.658 221.422 397.867 217.92L429.867 162.496C431.988 158.818 432.562 154.449 431.462 150.348C430.362 146.247 427.678 142.751 424 140.629C420.322 138.508 415.953 137.934 411.852 139.034C407.751 140.134 404.255 142.818 402.133 146.496ZM240 117.333V181.333C240 185.577 241.686 189.647 244.686 192.647C247.687 195.648 251.757 197.333 256 197.333C260.244 197.333 264.313 195.648 267.314 192.647C270.314 189.647 272 185.577 272 181.333V117.333C272 113.09 270.314 109.02 267.314 106.02C264.313 103.019 260.244 101.333 256 101.333C251.757 101.333 247.687 103.019 244.686 106.02C241.686 109.02 240 113.09 240 117.333ZM82.1334 162.496L114.133 217.92C116.342 221.422 119.82 223.932 123.839 224.926C127.858 225.92 132.105 225.319 135.691 223.251C139.277 221.182 141.922 217.805 143.074 213.829C144.225 209.853 143.793 205.585 141.867 201.92L109.867 146.496C108.816 144.675 107.417 143.079 105.75 141.799C104.082 140.518 102.179 139.579 100.148 139.034C98.1179 138.489 96 138.35 93.9156 138.624C91.8312 138.897 89.8211 139.579 88.0001 140.629C86.1791 141.68 84.5829 143.079 83.3025 144.747C82.0222 146.414 81.0828 148.317 80.538 150.348C79.9933 152.378 79.8538 154.496 80.1275 156.581C80.4012 158.665 81.0829 160.675 82.1334 162.496Z"
                          />
                        </svg>
                      </div>
                      <div className="backdrop-blur-sm rounded-r px-1 w-6 bg-black/40"></div>
                    </div>

                    <div className="absolute bottom-0 p-2 w-full flex flex-col gap-2 text-white">
                      <div class="h-8 bg-white/25 rounded w-[40%]"></div>
                      <div class="h-8 bg-white/25 rounded w-full"></div>
                      <div class="h-4 bg-white/25 rounded w-full mt-1"></div>
                    </div>
                  </div>
                </div>
                <div className="rounded-lg bg-black/25 dark:bg-white/25">
                  <div className="w-80 h-80 lg:w-96 lg:h-96 animate-pulse  relative overflow-hidden group/item cursor-pointer">
                    <div className="m-2 flex flex-row right-0 absolute text-sm text-white gap-0.5">
                      <div className=" flex flex-row w-6 items-center justify-center gap-1 backdrop-blur-sm rounded-l px-1 bg-[#4BAB4D]">
                        <svg
                          className="h-3.5 fill-white"
                          viewBox="0 0 512 512"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M256 410.667C306.074 410.667 346.667 370.074 346.667 320.001C346.667 269.927 306.074 229.334 256 229.334C205.926 229.334 165.333 269.927 165.333 320.001C165.333 370.074 205.926 410.667 256 410.667Z" />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M480.64 276.693L448.341 244.373C342.101 138.155 169.899 138.155 63.6588 244.373L31.3601 276.693C29.8753 278.178 28.6975 279.941 27.8939 281.881C27.0904 283.821 26.6768 285.9 26.6768 288C26.6768 290.1 27.0904 292.179 27.8939 294.119C28.6975 296.059 29.8753 297.822 31.3601 299.307C32.8449 300.792 34.6077 301.969 36.5477 302.773C38.4877 303.577 40.567 303.99 42.6668 303.99C44.7666 303.99 46.8459 303.577 48.7859 302.773C50.7259 301.969 52.4887 300.792 53.9735 299.307L86.2935 267.008C180.011 173.269 331.989 173.269 425.707 267.008L458.027 299.307C461.026 302.306 465.093 303.99 469.333 303.99C473.574 303.99 477.641 302.306 480.64 299.307C483.639 296.308 485.323 292.241 485.323 288C485.323 283.759 483.639 279.692 480.64 276.693Z"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M402.133 146.496L370.133 201.92C368.208 205.585 367.775 209.853 368.926 213.829C370.078 217.805 372.724 221.182 376.309 223.251C379.895 225.319 384.143 225.92 388.161 224.926C392.18 223.932 395.658 221.422 397.867 217.92L429.867 162.496C431.988 158.818 432.562 154.449 431.462 150.348C430.362 146.247 427.678 142.751 424 140.629C420.322 138.508 415.953 137.934 411.852 139.034C407.751 140.134 404.255 142.818 402.133 146.496ZM240 117.333V181.333C240 185.577 241.686 189.647 244.686 192.647C247.687 195.648 251.757 197.333 256 197.333C260.244 197.333 264.313 195.648 267.314 192.647C270.314 189.647 272 185.577 272 181.333V117.333C272 113.09 270.314 109.02 267.314 106.02C264.313 103.019 260.244 101.333 256 101.333C251.757 101.333 247.687 103.019 244.686 106.02C241.686 109.02 240 113.09 240 117.333ZM82.1334 162.496L114.133 217.92C116.342 221.422 119.82 223.932 123.839 224.926C127.858 225.92 132.105 225.319 135.691 223.251C139.277 221.182 141.922 217.805 143.074 213.829C144.225 209.853 143.793 205.585 141.867 201.92L109.867 146.496C108.816 144.675 107.417 143.079 105.75 141.799C104.082 140.518 102.179 139.579 100.148 139.034C98.1179 138.489 96 138.35 93.9156 138.624C91.8312 138.897 89.8211 139.579 88.0001 140.629C86.1791 141.68 84.5829 143.079 83.3025 144.747C82.0222 146.414 81.0828 148.317 80.538 150.348C79.9933 152.378 79.8538 154.496 80.1275 156.581C80.4012 158.665 81.0829 160.675 82.1334 162.496Z"
                          />
                        </svg>
                      </div>
                      <div className="backdrop-blur-sm rounded-r px-1 w-6 bg-black/40"></div>
                    </div>

                    <div className="absolute bottom-0 p-2 w-full flex flex-col gap-2 text-white">
                      <div class="h-8 bg-white/25 rounded w-[40%]"></div>
                      <div class="h-8 bg-white/25 rounded w-full"></div>
                      <div class="h-4 bg-white/25 rounded w-full mt-1"></div>
                    </div>
                  </div>
                </div>
                <div className="rounded-lg bg-black/25 dark:bg-white/25">
                  <div className="w-80 h-80 lg:w-96 lg:h-96 animate-pulse  relative overflow-hidden group/item cursor-pointer">
                    <div className="m-2 flex flex-row right-0 absolute text-sm text-white gap-0.5">
                      <div className=" flex flex-row w-6 items-center justify-center gap-1 backdrop-blur-sm rounded-l px-1 bg-[#4BAB4D]">
                        <svg
                          className="h-3.5 fill-white"
                          viewBox="0 0 512 512"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M256 410.667C306.074 410.667 346.667 370.074 346.667 320.001C346.667 269.927 306.074 229.334 256 229.334C205.926 229.334 165.333 269.927 165.333 320.001C165.333 370.074 205.926 410.667 256 410.667Z" />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M480.64 276.693L448.341 244.373C342.101 138.155 169.899 138.155 63.6588 244.373L31.3601 276.693C29.8753 278.178 28.6975 279.941 27.8939 281.881C27.0904 283.821 26.6768 285.9 26.6768 288C26.6768 290.1 27.0904 292.179 27.8939 294.119C28.6975 296.059 29.8753 297.822 31.3601 299.307C32.8449 300.792 34.6077 301.969 36.5477 302.773C38.4877 303.577 40.567 303.99 42.6668 303.99C44.7666 303.99 46.8459 303.577 48.7859 302.773C50.7259 301.969 52.4887 300.792 53.9735 299.307L86.2935 267.008C180.011 173.269 331.989 173.269 425.707 267.008L458.027 299.307C461.026 302.306 465.093 303.99 469.333 303.99C473.574 303.99 477.641 302.306 480.64 299.307C483.639 296.308 485.323 292.241 485.323 288C485.323 283.759 483.639 279.692 480.64 276.693Z"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M402.133 146.496L370.133 201.92C368.208 205.585 367.775 209.853 368.926 213.829C370.078 217.805 372.724 221.182 376.309 223.251C379.895 225.319 384.143 225.92 388.161 224.926C392.18 223.932 395.658 221.422 397.867 217.92L429.867 162.496C431.988 158.818 432.562 154.449 431.462 150.348C430.362 146.247 427.678 142.751 424 140.629C420.322 138.508 415.953 137.934 411.852 139.034C407.751 140.134 404.255 142.818 402.133 146.496ZM240 117.333V181.333C240 185.577 241.686 189.647 244.686 192.647C247.687 195.648 251.757 197.333 256 197.333C260.244 197.333 264.313 195.648 267.314 192.647C270.314 189.647 272 185.577 272 181.333V117.333C272 113.09 270.314 109.02 267.314 106.02C264.313 103.019 260.244 101.333 256 101.333C251.757 101.333 247.687 103.019 244.686 106.02C241.686 109.02 240 113.09 240 117.333ZM82.1334 162.496L114.133 217.92C116.342 221.422 119.82 223.932 123.839 224.926C127.858 225.92 132.105 225.319 135.691 223.251C139.277 221.182 141.922 217.805 143.074 213.829C144.225 209.853 143.793 205.585 141.867 201.92L109.867 146.496C108.816 144.675 107.417 143.079 105.75 141.799C104.082 140.518 102.179 139.579 100.148 139.034C98.1179 138.489 96 138.35 93.9156 138.624C91.8312 138.897 89.8211 139.579 88.0001 140.629C86.1791 141.68 84.5829 143.079 83.3025 144.747C82.0222 146.414 81.0828 148.317 80.538 150.348C79.9933 152.378 79.8538 154.496 80.1275 156.581C80.4012 158.665 81.0829 160.675 82.1334 162.496Z"
                          />
                        </svg>
                      </div>
                      <div className="backdrop-blur-sm rounded-r px-1 w-6 bg-black/40"></div>
                    </div>

                    <div className="absolute bottom-0 p-2 w-full flex flex-col gap-2 text-white">
                      <div class="h-8 bg-white/25 rounded w-[40%]"></div>
                      <div class="h-8 bg-white/25 rounded w-full"></div>
                      <div class="h-4 bg-white/25 rounded w-full mt-1"></div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div class="flex flex-col sm:inline-flex sm:flex-row rounded-lg shadow-sm lg:mt-6 mt-2">
          <button
            type="button"
            class="bg-[#4BAB4D] text-white py-3 px-4 inline-flex items-center gap-x-2 -mt-px -ms-px first:rounded-t-md last:rounded-b-md sm:first:rounded-s-md sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-md text-sm focus:z-10 border border-gray-200 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
          >
            БҮГД
          </button>
          <button
            type="button"
            class="py-3 px-4 inline-flex items-center gap-x-2 -mt-px -ms-px first:rounded-t-md last:rounded-b-md sm:first:rounded-s-md sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-md text-sm focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
          >
            SAT
          </button>
          <button
            type="button"
            class="py-3 px-4 inline-flex items-center gap-x-2 -mt-px -ms-px first:rounded-t-md last:rounded-b-md sm:first:rounded-s-md sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-md text-sm  focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
          >
            IELTS
          </button>
          <button
            type="button"
            class="py-3 px-4 inline-flex items-center gap-x-2 -mt-px -ms-px first:rounded-t-md last:rounded-b-md sm:first:rounded-s-md sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-md text-sm  focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
          >
            SCHOOL SELECTION
          </button>
          <button
            type="button"
            class="py-3 px-4 inline-flex items-center gap-x-2 -mt-px -ms-px first:rounded-t-md last:rounded-b-md sm:first:rounded-s-md sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-md text-sm  focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
          >
            RECOMMENDATION LETTER
          </button>
          <button
            type="button"
            class="py-3 px-4 inline-flex items-center gap-x-2 -mt-px -ms-px first:rounded-t-md last:rounded-b-md sm:first:rounded-s-md sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-md text-sm  focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
          >
            ESSAY
          </button>
          <button
            type="button"
            class="py-3 px-4 inline-flex items-center gap-x-2 -mt-px -ms-px first:rounded-t-md last:rounded-b-md sm:first:rounded-s-md sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-md text-sm  focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
          >
            PERSONAL STATEMENT
          </button>
        </div>
        <div className="w-full flex flex-col items-center justify-center gap-4">
          <div className="flex flex-wrap items-center justify-center gap-4 lg:mt-6 w-full mt-2">
            {data.length > 0 ? (
              <div className="flex flex-col lg:grid lg:grid-cols-2 gap-6 tems-center justify-center w-full">
                {data.map((el) => (
                  <a
                    className="w-full flex flex-col 2xl:flex-row 2xl:gap-4"
                    href={"/niitleluud/" + el.pl}
                  >
                    <div>
                      <div className="relative h-48 2xl:w-56">
                        <img
                          className="h-48 w-full 2xl:w-96 absolute object-cover 2xl:rounded-l-lg"
                          src={
                            "https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/News%2F" +
                            el.img
                          }
                          alt=""
                        />
                      </div>
                    </div>
                    <div>
                      <div className="gap-1 flex flex-col relative w-full dark:text-white">
                        <div className="font-bold text-lg lg:text-xl gap-1">
                          {el.ti}

                          <div className="flex flex-row h-min text-sm text-white gap-0.5 mt-1 overflow-x-auto">
                            <div className="rounded-l flex flex-row w-min items-center justify-center gap-1 backdrop-blur-sm px-1 bg-[#4BAB4D]">
                              <svg
                                className="h-3.5 fill-white"
                                viewBox="0 0 512 512"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M256 410.667C306.074 410.667 346.667 370.074 346.667 320.001C346.667 269.927 306.074 229.334 256 229.334C205.926 229.334 165.333 269.927 165.333 320.001C165.333 370.074 205.926 410.667 256 410.667Z" />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M480.64 276.693L448.341 244.373C342.101 138.155 169.899 138.155 63.6588 244.373L31.3601 276.693C29.8753 278.178 28.6975 279.941 27.8939 281.881C27.0904 283.821 26.6768 285.9 26.6768 288C26.6768 290.1 27.0904 292.179 27.8939 294.119C28.6975 296.059 29.8753 297.822 31.3601 299.307C32.8449 300.792 34.6077 301.969 36.5477 302.773C38.4877 303.577 40.567 303.99 42.6668 303.99C44.7666 303.99 46.8459 303.577 48.7859 302.773C50.7259 301.969 52.4887 300.792 53.9735 299.307L86.2935 267.008C180.011 173.269 331.989 173.269 425.707 267.008L458.027 299.307C461.026 302.306 465.093 303.99 469.333 303.99C473.574 303.99 477.641 302.306 480.64 299.307C483.639 296.308 485.323 292.241 485.323 288C485.323 283.759 483.639 279.692 480.64 276.693Z"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M402.133 146.496L370.133 201.92C368.208 205.585 367.775 209.853 368.926 213.829C370.078 217.805 372.724 221.182 376.309 223.251C379.895 225.319 384.143 225.92 388.161 224.926C392.18 223.932 395.658 221.422 397.867 217.92L429.867 162.496C431.988 158.818 432.562 154.449 431.462 150.348C430.362 146.247 427.678 142.751 424 140.629C420.322 138.508 415.953 137.934 411.852 139.034C407.751 140.134 404.255 142.818 402.133 146.496ZM240 117.333V181.333C240 185.577 241.686 189.647 244.686 192.647C247.687 195.648 251.757 197.333 256 197.333C260.244 197.333 264.313 195.648 267.314 192.647C270.314 189.647 272 185.577 272 181.333V117.333C272 113.09 270.314 109.02 267.314 106.02C264.313 103.019 260.244 101.333 256 101.333C251.757 101.333 247.687 103.019 244.686 106.02C241.686 109.02 240 113.09 240 117.333ZM82.1334 162.496L114.133 217.92C116.342 221.422 119.82 223.932 123.839 224.926C127.858 225.92 132.105 225.319 135.691 223.251C139.277 221.182 141.922 217.805 143.074 213.829C144.225 209.853 143.793 205.585 141.867 201.92L109.867 146.496C108.816 144.675 107.417 143.079 105.75 141.799C104.082 140.518 102.179 139.579 100.148 139.034C98.1179 138.489 96 138.35 93.9156 138.624C91.8312 138.897 89.8211 139.579 88.0001 140.629C86.1791 141.68 84.5829 143.079 83.3025 144.747C82.0222 146.414 81.0828 148.317 80.538 150.348C79.9933 152.378 79.8538 154.496 80.1275 156.581C80.4012 158.665 81.0829 160.675 82.1334 162.496Z"
                                />
                              </svg>
                              {el.s}
                            </div>
                            <div className="backdrop-blur-sm px-1 bg-[#4b86ab] w-max">
                              {el.rt}
                            </div>
                            <div className="flex gap-1 bg-[#564bab] w-min text-sm px-1 last:rounded-r">
                              {el.ca.length > 0 ? (
                                <div className="capitalize flex flex-row hover:gap-2 group/item ease-in-out duration-1000">
                                  <div className="w-0 opacity-0 group-hover/item:opacity-100 cursor-default group-hover/item:w-16 ease-in-out duration-500">
                                    Ангилал:{" "}
                                  </div>
                                  <div className="cursor-pointer w-max">
                                    {el.ca.map((ell) => ell).join(", ")}
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>

                            {el.cou.length > 0 ? (
                              <div className="flex gap-1 bg-[#a34bab] w-min text-sm px-1 last:rounded-r">
                                <div className="capitalize flex flex-row hover:gap-2 group/item ease-in-out duration-1000">
                                  <div className="w-0 opacity-0 group-hover/item:opacity-100 cursor-default group-hover/item:w-8 ease-in-out duration-500">
                                    Улс:{" "}
                                  </div>
                                  <div className="cursor-pointer w-max">
                                    {el.cou.map((ell) => ell).join(", ")}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>

                        <div className="font-normal text-[#7c7c7c] truncate text-sm opacity-100">
                          {formatDistanceToNow(new Date(el.cAt), {
                            addSuffix: true,
                          })
                            .replace(
                              "less than a minute ago",
                              "минут хүрэхгүй хугацааны өмнө"
                            )
                            .replace("about ", "")
                            .replace("days", "өдрийн")
                            .replace("hours", "цагийн")
                            .replace("hour", "цагийн")
                            .replace("minutes", "минутын")
                            .replace("minute", "минутын")
                            .replace("months", "сарын")
                            .replace("month", "сарын")
                            .replace("ago", "өмнө")}{" "}
                          | {el.au.map((el) => el).join(", ")}
                        </div>
                        <div className="overflow-scroll font-normal text-[#828282] w-full text-xs lg:text-sm text-justify">
                          {el.di}
                        </div>
                        {el.ht.length > 0 ? (
                          <div className="w-full capitalize text-xs flex flex-wrap gap-1 cursor-default">
                            {el.ht.map((el) => (
                              <div className="p-1 border-2 rounded-lg cursor-pointer dark:border-neutral-700">
                                #{el}
                              </div>
                            ))}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            ) : (
              <div className="flex flex-col lg:grid lg:grid-cols-2 gap-6 tems-center justify-center w-full">
                <a className="w-full flex flex-col 2xl:flex-row 2xl:gap-4 animate-pulse">
                  <div>
                    <div className="relative h-48 2xl:w-56 bg-black/40 dark:bg-white/40 2xl:rounded-l-lg"></div>
                  </div>
                  <div className="w-full">
                    <div className="gap-2 flex flex-col relative w-full dark:text-white">
                      <div className="font-bold text-lg lg:text-xl gap-2">
                        <div className="bg-black/40 dark:bg-white/40 w-full h-8 rounded"></div>

                        <div className="flex flex-row h-min text-sm text-white gap-0.5 mt-2 overflow-x-auto">
                          <div className="rounded-l flex flex-row w-12 items-center justify-center gap-1 h-5 backdrop-blur-sm px-1 bg-[#4BAB4D]">
                            <svg
                              className="h-3.5 fill-white"
                              viewBox="0 0 512 512"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M256 410.667C306.074 410.667 346.667 370.074 346.667 320.001C346.667 269.927 306.074 229.334 256 229.334C205.926 229.334 165.333 269.927 165.333 320.001C165.333 370.074 205.926 410.667 256 410.667Z" />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M480.64 276.693L448.341 244.373C342.101 138.155 169.899 138.155 63.6588 244.373L31.3601 276.693C29.8753 278.178 28.6975 279.941 27.8939 281.881C27.0904 283.821 26.6768 285.9 26.6768 288C26.6768 290.1 27.0904 292.179 27.8939 294.119C28.6975 296.059 29.8753 297.822 31.3601 299.307C32.8449 300.792 34.6077 301.969 36.5477 302.773C38.4877 303.577 40.567 303.99 42.6668 303.99C44.7666 303.99 46.8459 303.577 48.7859 302.773C50.7259 301.969 52.4887 300.792 53.9735 299.307L86.2935 267.008C180.011 173.269 331.989 173.269 425.707 267.008L458.027 299.307C461.026 302.306 465.093 303.99 469.333 303.99C473.574 303.99 477.641 302.306 480.64 299.307C483.639 296.308 485.323 292.241 485.323 288C485.323 283.759 483.639 279.692 480.64 276.693Z"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M402.133 146.496L370.133 201.92C368.208 205.585 367.775 209.853 368.926 213.829C370.078 217.805 372.724 221.182 376.309 223.251C379.895 225.319 384.143 225.92 388.161 224.926C392.18 223.932 395.658 221.422 397.867 217.92L429.867 162.496C431.988 158.818 432.562 154.449 431.462 150.348C430.362 146.247 427.678 142.751 424 140.629C420.322 138.508 415.953 137.934 411.852 139.034C407.751 140.134 404.255 142.818 402.133 146.496ZM240 117.333V181.333C240 185.577 241.686 189.647 244.686 192.647C247.687 195.648 251.757 197.333 256 197.333C260.244 197.333 264.313 195.648 267.314 192.647C270.314 189.647 272 185.577 272 181.333V117.333C272 113.09 270.314 109.02 267.314 106.02C264.313 103.019 260.244 101.333 256 101.333C251.757 101.333 247.687 103.019 244.686 106.02C241.686 109.02 240 113.09 240 117.333ZM82.1334 162.496L114.133 217.92C116.342 221.422 119.82 223.932 123.839 224.926C127.858 225.92 132.105 225.319 135.691 223.251C139.277 221.182 141.922 217.805 143.074 213.829C144.225 209.853 143.793 205.585 141.867 201.92L109.867 146.496C108.816 144.675 107.417 143.079 105.75 141.799C104.082 140.518 102.179 139.579 100.148 139.034C98.1179 138.489 96 138.35 93.9156 138.624C91.8312 138.897 89.8211 139.579 88.0001 140.629C86.1791 141.68 84.5829 143.079 83.3025 144.747C82.0222 146.414 81.0828 148.317 80.538 150.348C79.9933 152.378 79.8538 154.496 80.1275 156.581C80.4012 158.665 81.0829 160.675 82.1334 162.496Z"
                              />
                            </svg>
                          </div>
                          <div className="backdrop-blur-sm px-1 bg-black/40 dark:bg-white/40 w-12 h-5"></div>
                          <div className="flex gap-1 bg-[#564bab] text-sm px-1 last:rounded-r bg-black/40 dark:bg-white/40 w-12 h-5"></div>

                          <div className="flex gap-1 bg-[#a34bab] w-12 text-sm px-1 last:rounded-r bg-black/40 dark:bg-white/40 h-5"></div>
                        </div>
                      </div>

                      <div className="font-normal text-[#7c7c7c] truncate text-sm opacity-100 bg-black/40 dark:bg-white/40 w-full h-12 rounded"></div>
                      <div className="overflow-scroll font-normal text-[#828282] w-full text-xs lg:text-sm text-justify bg-black/40 dark:bg-white/40 h-10 rounded"></div>
                    </div>
                  </div>
                </a>

                <a className="w-full flex flex-col 2xl:flex-row 2xl:gap-4 animate-pulse">
                  <div>
                    <div className="relative h-48 2xl:w-56 bg-black/40 dark:bg-white/40 2xl:rounded-l-lg"></div>
                  </div>
                  <div className="w-full">
                    <div className="gap-2 flex flex-col relative w-full dark:text-white">
                      <div className="font-bold text-lg lg:text-xl gap-2">
                        <div className="bg-black/40 dark:bg-white/40 w-full h-8 rounded"></div>

                        <div className="flex flex-row h-min text-sm text-white gap-0.5 mt-2 overflow-x-auto">
                          <div className="rounded-l flex flex-row w-12 items-center justify-center gap-1 h-5 backdrop-blur-sm px-1 bg-[#4BAB4D]">
                            <svg
                              className="h-3.5 fill-white"
                              viewBox="0 0 512 512"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M256 410.667C306.074 410.667 346.667 370.074 346.667 320.001C346.667 269.927 306.074 229.334 256 229.334C205.926 229.334 165.333 269.927 165.333 320.001C165.333 370.074 205.926 410.667 256 410.667Z" />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M480.64 276.693L448.341 244.373C342.101 138.155 169.899 138.155 63.6588 244.373L31.3601 276.693C29.8753 278.178 28.6975 279.941 27.8939 281.881C27.0904 283.821 26.6768 285.9 26.6768 288C26.6768 290.1 27.0904 292.179 27.8939 294.119C28.6975 296.059 29.8753 297.822 31.3601 299.307C32.8449 300.792 34.6077 301.969 36.5477 302.773C38.4877 303.577 40.567 303.99 42.6668 303.99C44.7666 303.99 46.8459 303.577 48.7859 302.773C50.7259 301.969 52.4887 300.792 53.9735 299.307L86.2935 267.008C180.011 173.269 331.989 173.269 425.707 267.008L458.027 299.307C461.026 302.306 465.093 303.99 469.333 303.99C473.574 303.99 477.641 302.306 480.64 299.307C483.639 296.308 485.323 292.241 485.323 288C485.323 283.759 483.639 279.692 480.64 276.693Z"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M402.133 146.496L370.133 201.92C368.208 205.585 367.775 209.853 368.926 213.829C370.078 217.805 372.724 221.182 376.309 223.251C379.895 225.319 384.143 225.92 388.161 224.926C392.18 223.932 395.658 221.422 397.867 217.92L429.867 162.496C431.988 158.818 432.562 154.449 431.462 150.348C430.362 146.247 427.678 142.751 424 140.629C420.322 138.508 415.953 137.934 411.852 139.034C407.751 140.134 404.255 142.818 402.133 146.496ZM240 117.333V181.333C240 185.577 241.686 189.647 244.686 192.647C247.687 195.648 251.757 197.333 256 197.333C260.244 197.333 264.313 195.648 267.314 192.647C270.314 189.647 272 185.577 272 181.333V117.333C272 113.09 270.314 109.02 267.314 106.02C264.313 103.019 260.244 101.333 256 101.333C251.757 101.333 247.687 103.019 244.686 106.02C241.686 109.02 240 113.09 240 117.333ZM82.1334 162.496L114.133 217.92C116.342 221.422 119.82 223.932 123.839 224.926C127.858 225.92 132.105 225.319 135.691 223.251C139.277 221.182 141.922 217.805 143.074 213.829C144.225 209.853 143.793 205.585 141.867 201.92L109.867 146.496C108.816 144.675 107.417 143.079 105.75 141.799C104.082 140.518 102.179 139.579 100.148 139.034C98.1179 138.489 96 138.35 93.9156 138.624C91.8312 138.897 89.8211 139.579 88.0001 140.629C86.1791 141.68 84.5829 143.079 83.3025 144.747C82.0222 146.414 81.0828 148.317 80.538 150.348C79.9933 152.378 79.8538 154.496 80.1275 156.581C80.4012 158.665 81.0829 160.675 82.1334 162.496Z"
                              />
                            </svg>
                          </div>
                          <div className="backdrop-blur-sm px-1 bg-black/40 dark:bg-white/40 w-12 h-5"></div>
                          <div className="flex gap-1 bg-[#564bab] text-sm px-1 last:rounded-r bg-black/40 dark:bg-white/40 w-12 h-5"></div>

                          <div className="flex gap-1 bg-[#a34bab] w-12 text-sm px-1 last:rounded-r bg-black/40 dark:bg-white/40 h-5"></div>
                        </div>
                      </div>

                      <div className="font-normal text-[#7c7c7c] truncate text-sm opacity-100 bg-black/40 dark:bg-white/40 w-full h-12 rounded"></div>
                      <div className="overflow-scroll font-normal text-[#828282] w-full text-xs lg:text-sm text-justify bg-black/40 dark:bg-white/40 h-10 rounded"></div>
                    </div>
                  </div>
                </a>
                <a className="w-full flex flex-col 2xl:flex-row 2xl:gap-4 animate-pulse">
                  <div>
                    <div className="relative h-48 2xl:w-56 bg-black/40 dark:bg-white/40 2xl:rounded-l-lg"></div>
                  </div>
                  <div className="w-full">
                    <div className="gap-2 flex flex-col relative w-full dark:text-white">
                      <div className="font-bold text-lg lg:text-xl gap-2">
                        <div className="bg-black/40 dark:bg-white/40 w-full h-8 rounded"></div>

                        <div className="flex flex-row h-min text-sm text-white gap-0.5 mt-2 overflow-x-auto">
                          <div className="rounded-l flex flex-row w-12 items-center justify-center gap-1 h-5 backdrop-blur-sm px-1 bg-[#4BAB4D]">
                            <svg
                              className="h-3.5 fill-white"
                              viewBox="0 0 512 512"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M256 410.667C306.074 410.667 346.667 370.074 346.667 320.001C346.667 269.927 306.074 229.334 256 229.334C205.926 229.334 165.333 269.927 165.333 320.001C165.333 370.074 205.926 410.667 256 410.667Z" />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M480.64 276.693L448.341 244.373C342.101 138.155 169.899 138.155 63.6588 244.373L31.3601 276.693C29.8753 278.178 28.6975 279.941 27.8939 281.881C27.0904 283.821 26.6768 285.9 26.6768 288C26.6768 290.1 27.0904 292.179 27.8939 294.119C28.6975 296.059 29.8753 297.822 31.3601 299.307C32.8449 300.792 34.6077 301.969 36.5477 302.773C38.4877 303.577 40.567 303.99 42.6668 303.99C44.7666 303.99 46.8459 303.577 48.7859 302.773C50.7259 301.969 52.4887 300.792 53.9735 299.307L86.2935 267.008C180.011 173.269 331.989 173.269 425.707 267.008L458.027 299.307C461.026 302.306 465.093 303.99 469.333 303.99C473.574 303.99 477.641 302.306 480.64 299.307C483.639 296.308 485.323 292.241 485.323 288C485.323 283.759 483.639 279.692 480.64 276.693Z"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M402.133 146.496L370.133 201.92C368.208 205.585 367.775 209.853 368.926 213.829C370.078 217.805 372.724 221.182 376.309 223.251C379.895 225.319 384.143 225.92 388.161 224.926C392.18 223.932 395.658 221.422 397.867 217.92L429.867 162.496C431.988 158.818 432.562 154.449 431.462 150.348C430.362 146.247 427.678 142.751 424 140.629C420.322 138.508 415.953 137.934 411.852 139.034C407.751 140.134 404.255 142.818 402.133 146.496ZM240 117.333V181.333C240 185.577 241.686 189.647 244.686 192.647C247.687 195.648 251.757 197.333 256 197.333C260.244 197.333 264.313 195.648 267.314 192.647C270.314 189.647 272 185.577 272 181.333V117.333C272 113.09 270.314 109.02 267.314 106.02C264.313 103.019 260.244 101.333 256 101.333C251.757 101.333 247.687 103.019 244.686 106.02C241.686 109.02 240 113.09 240 117.333ZM82.1334 162.496L114.133 217.92C116.342 221.422 119.82 223.932 123.839 224.926C127.858 225.92 132.105 225.319 135.691 223.251C139.277 221.182 141.922 217.805 143.074 213.829C144.225 209.853 143.793 205.585 141.867 201.92L109.867 146.496C108.816 144.675 107.417 143.079 105.75 141.799C104.082 140.518 102.179 139.579 100.148 139.034C98.1179 138.489 96 138.35 93.9156 138.624C91.8312 138.897 89.8211 139.579 88.0001 140.629C86.1791 141.68 84.5829 143.079 83.3025 144.747C82.0222 146.414 81.0828 148.317 80.538 150.348C79.9933 152.378 79.8538 154.496 80.1275 156.581C80.4012 158.665 81.0829 160.675 82.1334 162.496Z"
                              />
                            </svg>
                          </div>
                          <div className="backdrop-blur-sm px-1 bg-black/40 dark:bg-white/40 w-12 h-5"></div>
                          <div className="flex gap-1 bg-[#564bab] text-sm px-1 last:rounded-r bg-black/40 dark:bg-white/40 w-12 h-5"></div>

                          <div className="flex gap-1 bg-[#a34bab] w-12 text-sm px-1 last:rounded-r bg-black/40 dark:bg-white/40 h-5"></div>
                        </div>
                      </div>

                      <div className="font-normal text-[#7c7c7c] truncate text-sm opacity-100 bg-black/40 dark:bg-white/40 w-full h-12 rounded"></div>
                      <div className="overflow-scroll font-normal text-[#828282] w-full text-xs lg:text-sm text-justify bg-black/40 dark:bg-white/40 h-10 rounded"></div>
                    </div>
                  </div>
                </a>
                <a className="w-full flex flex-col 2xl:flex-row 2xl:gap-4 animate-pulse">
                  <div>
                    <div className="relative h-48 2xl:w-56 bg-black/40 dark:bg-white/40 2xl:rounded-l-lg"></div>
                  </div>
                  <div className="w-full">
                    <div className="gap-2 flex flex-col relative w-full dark:text-white">
                      <div className="font-bold text-lg lg:text-xl gap-2">
                        <div className="bg-black/40 dark:bg-white/40 w-full h-8 rounded"></div>

                        <div className="flex flex-row h-min text-sm text-white gap-0.5 mt-2 overflow-x-auto">
                          <div className="rounded-l flex flex-row w-12 items-center justify-center gap-1 h-5 backdrop-blur-sm px-1 bg-[#4BAB4D]">
                            <svg
                              className="h-3.5 fill-white"
                              viewBox="0 0 512 512"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M256 410.667C306.074 410.667 346.667 370.074 346.667 320.001C346.667 269.927 306.074 229.334 256 229.334C205.926 229.334 165.333 269.927 165.333 320.001C165.333 370.074 205.926 410.667 256 410.667Z" />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M480.64 276.693L448.341 244.373C342.101 138.155 169.899 138.155 63.6588 244.373L31.3601 276.693C29.8753 278.178 28.6975 279.941 27.8939 281.881C27.0904 283.821 26.6768 285.9 26.6768 288C26.6768 290.1 27.0904 292.179 27.8939 294.119C28.6975 296.059 29.8753 297.822 31.3601 299.307C32.8449 300.792 34.6077 301.969 36.5477 302.773C38.4877 303.577 40.567 303.99 42.6668 303.99C44.7666 303.99 46.8459 303.577 48.7859 302.773C50.7259 301.969 52.4887 300.792 53.9735 299.307L86.2935 267.008C180.011 173.269 331.989 173.269 425.707 267.008L458.027 299.307C461.026 302.306 465.093 303.99 469.333 303.99C473.574 303.99 477.641 302.306 480.64 299.307C483.639 296.308 485.323 292.241 485.323 288C485.323 283.759 483.639 279.692 480.64 276.693Z"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M402.133 146.496L370.133 201.92C368.208 205.585 367.775 209.853 368.926 213.829C370.078 217.805 372.724 221.182 376.309 223.251C379.895 225.319 384.143 225.92 388.161 224.926C392.18 223.932 395.658 221.422 397.867 217.92L429.867 162.496C431.988 158.818 432.562 154.449 431.462 150.348C430.362 146.247 427.678 142.751 424 140.629C420.322 138.508 415.953 137.934 411.852 139.034C407.751 140.134 404.255 142.818 402.133 146.496ZM240 117.333V181.333C240 185.577 241.686 189.647 244.686 192.647C247.687 195.648 251.757 197.333 256 197.333C260.244 197.333 264.313 195.648 267.314 192.647C270.314 189.647 272 185.577 272 181.333V117.333C272 113.09 270.314 109.02 267.314 106.02C264.313 103.019 260.244 101.333 256 101.333C251.757 101.333 247.687 103.019 244.686 106.02C241.686 109.02 240 113.09 240 117.333ZM82.1334 162.496L114.133 217.92C116.342 221.422 119.82 223.932 123.839 224.926C127.858 225.92 132.105 225.319 135.691 223.251C139.277 221.182 141.922 217.805 143.074 213.829C144.225 209.853 143.793 205.585 141.867 201.92L109.867 146.496C108.816 144.675 107.417 143.079 105.75 141.799C104.082 140.518 102.179 139.579 100.148 139.034C98.1179 138.489 96 138.35 93.9156 138.624C91.8312 138.897 89.8211 139.579 88.0001 140.629C86.1791 141.68 84.5829 143.079 83.3025 144.747C82.0222 146.414 81.0828 148.317 80.538 150.348C79.9933 152.378 79.8538 154.496 80.1275 156.581C80.4012 158.665 81.0829 160.675 82.1334 162.496Z"
                              />
                            </svg>
                          </div>
                          <div className="backdrop-blur-sm px-1 bg-black/40 dark:bg-white/40 w-12 h-5"></div>
                          <div className="flex gap-1 bg-[#564bab] text-sm px-1 last:rounded-r bg-black/40 dark:bg-white/40 w-12 h-5"></div>

                          <div className="flex gap-1 bg-[#a34bab] w-12 text-sm px-1 last:rounded-r bg-black/40 dark:bg-white/40 h-5"></div>
                        </div>
                      </div>

                      <div className="font-normal text-[#7c7c7c] truncate text-sm opacity-100 bg-black/40 dark:bg-white/40 w-full h-12 rounded"></div>
                      <div className="overflow-scroll font-normal text-[#828282] w-full text-xs lg:text-sm text-justify bg-black/40 dark:bg-white/40 h-10 rounded"></div>
                    </div>
                  </div>
                </a>
                <a className="w-full flex flex-col 2xl:flex-row 2xl:gap-4 animate-pulse">
                  <div>
                    <div className="relative h-48 2xl:w-56 bg-black/40 dark:bg-white/40 2xl:rounded-l-lg"></div>
                  </div>
                  <div className="w-full">
                    <div className="gap-2 flex flex-col relative w-full dark:text-white">
                      <div className="font-bold text-lg lg:text-xl gap-2">
                        <div className="bg-black/40 dark:bg-white/40 w-full h-8 rounded"></div>

                        <div className="flex flex-row h-min text-sm text-white gap-0.5 mt-2 overflow-x-auto">
                          <div className="rounded-l flex flex-row w-12 items-center justify-center gap-1 h-5 backdrop-blur-sm px-1 bg-[#4BAB4D]">
                            <svg
                              className="h-3.5 fill-white"
                              viewBox="0 0 512 512"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M256 410.667C306.074 410.667 346.667 370.074 346.667 320.001C346.667 269.927 306.074 229.334 256 229.334C205.926 229.334 165.333 269.927 165.333 320.001C165.333 370.074 205.926 410.667 256 410.667Z" />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M480.64 276.693L448.341 244.373C342.101 138.155 169.899 138.155 63.6588 244.373L31.3601 276.693C29.8753 278.178 28.6975 279.941 27.8939 281.881C27.0904 283.821 26.6768 285.9 26.6768 288C26.6768 290.1 27.0904 292.179 27.8939 294.119C28.6975 296.059 29.8753 297.822 31.3601 299.307C32.8449 300.792 34.6077 301.969 36.5477 302.773C38.4877 303.577 40.567 303.99 42.6668 303.99C44.7666 303.99 46.8459 303.577 48.7859 302.773C50.7259 301.969 52.4887 300.792 53.9735 299.307L86.2935 267.008C180.011 173.269 331.989 173.269 425.707 267.008L458.027 299.307C461.026 302.306 465.093 303.99 469.333 303.99C473.574 303.99 477.641 302.306 480.64 299.307C483.639 296.308 485.323 292.241 485.323 288C485.323 283.759 483.639 279.692 480.64 276.693Z"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M402.133 146.496L370.133 201.92C368.208 205.585 367.775 209.853 368.926 213.829C370.078 217.805 372.724 221.182 376.309 223.251C379.895 225.319 384.143 225.92 388.161 224.926C392.18 223.932 395.658 221.422 397.867 217.92L429.867 162.496C431.988 158.818 432.562 154.449 431.462 150.348C430.362 146.247 427.678 142.751 424 140.629C420.322 138.508 415.953 137.934 411.852 139.034C407.751 140.134 404.255 142.818 402.133 146.496ZM240 117.333V181.333C240 185.577 241.686 189.647 244.686 192.647C247.687 195.648 251.757 197.333 256 197.333C260.244 197.333 264.313 195.648 267.314 192.647C270.314 189.647 272 185.577 272 181.333V117.333C272 113.09 270.314 109.02 267.314 106.02C264.313 103.019 260.244 101.333 256 101.333C251.757 101.333 247.687 103.019 244.686 106.02C241.686 109.02 240 113.09 240 117.333ZM82.1334 162.496L114.133 217.92C116.342 221.422 119.82 223.932 123.839 224.926C127.858 225.92 132.105 225.319 135.691 223.251C139.277 221.182 141.922 217.805 143.074 213.829C144.225 209.853 143.793 205.585 141.867 201.92L109.867 146.496C108.816 144.675 107.417 143.079 105.75 141.799C104.082 140.518 102.179 139.579 100.148 139.034C98.1179 138.489 96 138.35 93.9156 138.624C91.8312 138.897 89.8211 139.579 88.0001 140.629C86.1791 141.68 84.5829 143.079 83.3025 144.747C82.0222 146.414 81.0828 148.317 80.538 150.348C79.9933 152.378 79.8538 154.496 80.1275 156.581C80.4012 158.665 81.0829 160.675 82.1334 162.496Z"
                              />
                            </svg>
                          </div>
                          <div className="backdrop-blur-sm px-1 bg-black/40 dark:bg-white/40 w-12 h-5"></div>
                          <div className="flex gap-1 bg-[#564bab] text-sm px-1 last:rounded-r bg-black/40 dark:bg-white/40 w-12 h-5"></div>

                          <div className="flex gap-1 bg-[#a34bab] w-12 text-sm px-1 last:rounded-r bg-black/40 dark:bg-white/40 h-5"></div>
                        </div>
                      </div>

                      <div className="font-normal text-[#7c7c7c] truncate text-sm opacity-100 bg-black/40 dark:bg-white/40 w-full h-12 rounded"></div>
                      <div className="overflow-scroll font-normal text-[#828282] w-full text-xs lg:text-sm text-justify bg-black/40 dark:bg-white/40 h-10 rounded"></div>
                    </div>
                  </div>
                </a>
                <a className="w-full flex flex-col 2xl:flex-row 2xl:gap-4 animate-pulse">
                  <div>
                    <div className="relative h-48 2xl:w-56 bg-black/40 dark:bg-white/40 2xl:rounded-l-lg"></div>
                  </div>
                  <div className="w-full">
                    <div className="gap-2 flex flex-col relative w-full dark:text-white">
                      <div className="font-bold text-lg lg:text-xl gap-2">
                        <div className="bg-black/40 dark:bg-white/40 w-full h-8 rounded"></div>

                        <div className="flex flex-row h-min text-sm text-white gap-0.5 mt-2 overflow-x-auto">
                          <div className="rounded-l flex flex-row w-12 items-center justify-center gap-1 h-5 backdrop-blur-sm px-1 bg-[#4BAB4D]">
                            <svg
                              className="h-3.5 fill-white"
                              viewBox="0 0 512 512"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M256 410.667C306.074 410.667 346.667 370.074 346.667 320.001C346.667 269.927 306.074 229.334 256 229.334C205.926 229.334 165.333 269.927 165.333 320.001C165.333 370.074 205.926 410.667 256 410.667Z" />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M480.64 276.693L448.341 244.373C342.101 138.155 169.899 138.155 63.6588 244.373L31.3601 276.693C29.8753 278.178 28.6975 279.941 27.8939 281.881C27.0904 283.821 26.6768 285.9 26.6768 288C26.6768 290.1 27.0904 292.179 27.8939 294.119C28.6975 296.059 29.8753 297.822 31.3601 299.307C32.8449 300.792 34.6077 301.969 36.5477 302.773C38.4877 303.577 40.567 303.99 42.6668 303.99C44.7666 303.99 46.8459 303.577 48.7859 302.773C50.7259 301.969 52.4887 300.792 53.9735 299.307L86.2935 267.008C180.011 173.269 331.989 173.269 425.707 267.008L458.027 299.307C461.026 302.306 465.093 303.99 469.333 303.99C473.574 303.99 477.641 302.306 480.64 299.307C483.639 296.308 485.323 292.241 485.323 288C485.323 283.759 483.639 279.692 480.64 276.693Z"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M402.133 146.496L370.133 201.92C368.208 205.585 367.775 209.853 368.926 213.829C370.078 217.805 372.724 221.182 376.309 223.251C379.895 225.319 384.143 225.92 388.161 224.926C392.18 223.932 395.658 221.422 397.867 217.92L429.867 162.496C431.988 158.818 432.562 154.449 431.462 150.348C430.362 146.247 427.678 142.751 424 140.629C420.322 138.508 415.953 137.934 411.852 139.034C407.751 140.134 404.255 142.818 402.133 146.496ZM240 117.333V181.333C240 185.577 241.686 189.647 244.686 192.647C247.687 195.648 251.757 197.333 256 197.333C260.244 197.333 264.313 195.648 267.314 192.647C270.314 189.647 272 185.577 272 181.333V117.333C272 113.09 270.314 109.02 267.314 106.02C264.313 103.019 260.244 101.333 256 101.333C251.757 101.333 247.687 103.019 244.686 106.02C241.686 109.02 240 113.09 240 117.333ZM82.1334 162.496L114.133 217.92C116.342 221.422 119.82 223.932 123.839 224.926C127.858 225.92 132.105 225.319 135.691 223.251C139.277 221.182 141.922 217.805 143.074 213.829C144.225 209.853 143.793 205.585 141.867 201.92L109.867 146.496C108.816 144.675 107.417 143.079 105.75 141.799C104.082 140.518 102.179 139.579 100.148 139.034C98.1179 138.489 96 138.35 93.9156 138.624C91.8312 138.897 89.8211 139.579 88.0001 140.629C86.1791 141.68 84.5829 143.079 83.3025 144.747C82.0222 146.414 81.0828 148.317 80.538 150.348C79.9933 152.378 79.8538 154.496 80.1275 156.581C80.4012 158.665 81.0829 160.675 82.1334 162.496Z"
                              />
                            </svg>
                          </div>
                          <div className="backdrop-blur-sm px-1 bg-black/40 dark:bg-white/40 w-12 h-5"></div>
                          <div className="flex gap-1 bg-[#564bab] text-sm px-1 last:rounded-r bg-black/40 dark:bg-white/40 w-12 h-5"></div>

                          <div className="flex gap-1 bg-[#a34bab] w-12 text-sm px-1 last:rounded-r bg-black/40 dark:bg-white/40 h-5"></div>
                        </div>
                      </div>

                      <div className="font-normal text-[#7c7c7c] truncate text-sm opacity-100 bg-black/40 dark:bg-white/40 w-full h-12 rounded"></div>
                      <div className="overflow-scroll font-normal text-[#828282] w-full text-xs lg:text-sm text-justify bg-black/40 dark:bg-white/40 h-10 rounded"></div>
                    </div>
                  </div>
                </a>
                <a className="w-full flex flex-col 2xl:flex-row 2xl:gap-4 animate-pulse">
                  <div>
                    <div className="relative h-48 2xl:w-56 bg-black/40 dark:bg-white/40 2xl:rounded-l-lg"></div>
                  </div>
                  <div className="w-full">
                    <div className="gap-2 flex flex-col relative w-full dark:text-white">
                      <div className="font-bold text-lg lg:text-xl gap-2">
                        <div className="bg-black/40 dark:bg-white/40 w-full h-8 rounded"></div>

                        <div className="flex flex-row h-min text-sm text-white gap-0.5 mt-2 overflow-x-auto">
                          <div className="rounded-l flex flex-row w-12 items-center justify-center gap-1 h-5 backdrop-blur-sm px-1 bg-[#4BAB4D]">
                            <svg
                              className="h-3.5 fill-white"
                              viewBox="0 0 512 512"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M256 410.667C306.074 410.667 346.667 370.074 346.667 320.001C346.667 269.927 306.074 229.334 256 229.334C205.926 229.334 165.333 269.927 165.333 320.001C165.333 370.074 205.926 410.667 256 410.667Z" />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M480.64 276.693L448.341 244.373C342.101 138.155 169.899 138.155 63.6588 244.373L31.3601 276.693C29.8753 278.178 28.6975 279.941 27.8939 281.881C27.0904 283.821 26.6768 285.9 26.6768 288C26.6768 290.1 27.0904 292.179 27.8939 294.119C28.6975 296.059 29.8753 297.822 31.3601 299.307C32.8449 300.792 34.6077 301.969 36.5477 302.773C38.4877 303.577 40.567 303.99 42.6668 303.99C44.7666 303.99 46.8459 303.577 48.7859 302.773C50.7259 301.969 52.4887 300.792 53.9735 299.307L86.2935 267.008C180.011 173.269 331.989 173.269 425.707 267.008L458.027 299.307C461.026 302.306 465.093 303.99 469.333 303.99C473.574 303.99 477.641 302.306 480.64 299.307C483.639 296.308 485.323 292.241 485.323 288C485.323 283.759 483.639 279.692 480.64 276.693Z"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M402.133 146.496L370.133 201.92C368.208 205.585 367.775 209.853 368.926 213.829C370.078 217.805 372.724 221.182 376.309 223.251C379.895 225.319 384.143 225.92 388.161 224.926C392.18 223.932 395.658 221.422 397.867 217.92L429.867 162.496C431.988 158.818 432.562 154.449 431.462 150.348C430.362 146.247 427.678 142.751 424 140.629C420.322 138.508 415.953 137.934 411.852 139.034C407.751 140.134 404.255 142.818 402.133 146.496ZM240 117.333V181.333C240 185.577 241.686 189.647 244.686 192.647C247.687 195.648 251.757 197.333 256 197.333C260.244 197.333 264.313 195.648 267.314 192.647C270.314 189.647 272 185.577 272 181.333V117.333C272 113.09 270.314 109.02 267.314 106.02C264.313 103.019 260.244 101.333 256 101.333C251.757 101.333 247.687 103.019 244.686 106.02C241.686 109.02 240 113.09 240 117.333ZM82.1334 162.496L114.133 217.92C116.342 221.422 119.82 223.932 123.839 224.926C127.858 225.92 132.105 225.319 135.691 223.251C139.277 221.182 141.922 217.805 143.074 213.829C144.225 209.853 143.793 205.585 141.867 201.92L109.867 146.496C108.816 144.675 107.417 143.079 105.75 141.799C104.082 140.518 102.179 139.579 100.148 139.034C98.1179 138.489 96 138.35 93.9156 138.624C91.8312 138.897 89.8211 139.579 88.0001 140.629C86.1791 141.68 84.5829 143.079 83.3025 144.747C82.0222 146.414 81.0828 148.317 80.538 150.348C79.9933 152.378 79.8538 154.496 80.1275 156.581C80.4012 158.665 81.0829 160.675 82.1334 162.496Z"
                              />
                            </svg>
                          </div>
                          <div className="backdrop-blur-sm px-1 bg-black/40 dark:bg-white/40 w-12 h-5"></div>
                          <div className="flex gap-1 bg-[#564bab] text-sm px-1 last:rounded-r bg-black/40 dark:bg-white/40 w-12 h-5"></div>

                          <div className="flex gap-1 bg-[#a34bab] w-12 text-sm px-1 last:rounded-r bg-black/40 dark:bg-white/40 h-5"></div>
                        </div>
                      </div>

                      <div className="font-normal text-[#7c7c7c] truncate text-sm opacity-100 bg-black/40 dark:bg-white/40 w-full h-12 rounded"></div>
                      <div className="overflow-scroll font-normal text-[#828282] w-full text-xs lg:text-sm text-justify bg-black/40 dark:bg-white/40 h-10 rounded"></div>
                    </div>
                  </div>
                </a>
                <a className="w-full flex flex-col 2xl:flex-row 2xl:gap-4 animate-pulse">
                  <div>
                    <div className="relative h-48 2xl:w-56 bg-black/40 dark:bg-white/40 2xl:rounded-l-lg"></div>
                  </div>
                  <div className="w-full">
                    <div className="gap-2 flex flex-col relative w-full dark:text-white">
                      <div className="font-bold text-lg lg:text-xl gap-2">
                        <div className="bg-black/40 dark:bg-white/40 w-full h-8 rounded"></div>

                        <div className="flex flex-row h-min text-sm text-white gap-0.5 mt-2 overflow-x-auto">
                          <div className="rounded-l flex flex-row w-12 items-center justify-center gap-1 h-5 backdrop-blur-sm px-1 bg-[#4BAB4D]">
                            <svg
                              className="h-3.5 fill-white"
                              viewBox="0 0 512 512"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M256 410.667C306.074 410.667 346.667 370.074 346.667 320.001C346.667 269.927 306.074 229.334 256 229.334C205.926 229.334 165.333 269.927 165.333 320.001C165.333 370.074 205.926 410.667 256 410.667Z" />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M480.64 276.693L448.341 244.373C342.101 138.155 169.899 138.155 63.6588 244.373L31.3601 276.693C29.8753 278.178 28.6975 279.941 27.8939 281.881C27.0904 283.821 26.6768 285.9 26.6768 288C26.6768 290.1 27.0904 292.179 27.8939 294.119C28.6975 296.059 29.8753 297.822 31.3601 299.307C32.8449 300.792 34.6077 301.969 36.5477 302.773C38.4877 303.577 40.567 303.99 42.6668 303.99C44.7666 303.99 46.8459 303.577 48.7859 302.773C50.7259 301.969 52.4887 300.792 53.9735 299.307L86.2935 267.008C180.011 173.269 331.989 173.269 425.707 267.008L458.027 299.307C461.026 302.306 465.093 303.99 469.333 303.99C473.574 303.99 477.641 302.306 480.64 299.307C483.639 296.308 485.323 292.241 485.323 288C485.323 283.759 483.639 279.692 480.64 276.693Z"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M402.133 146.496L370.133 201.92C368.208 205.585 367.775 209.853 368.926 213.829C370.078 217.805 372.724 221.182 376.309 223.251C379.895 225.319 384.143 225.92 388.161 224.926C392.18 223.932 395.658 221.422 397.867 217.92L429.867 162.496C431.988 158.818 432.562 154.449 431.462 150.348C430.362 146.247 427.678 142.751 424 140.629C420.322 138.508 415.953 137.934 411.852 139.034C407.751 140.134 404.255 142.818 402.133 146.496ZM240 117.333V181.333C240 185.577 241.686 189.647 244.686 192.647C247.687 195.648 251.757 197.333 256 197.333C260.244 197.333 264.313 195.648 267.314 192.647C270.314 189.647 272 185.577 272 181.333V117.333C272 113.09 270.314 109.02 267.314 106.02C264.313 103.019 260.244 101.333 256 101.333C251.757 101.333 247.687 103.019 244.686 106.02C241.686 109.02 240 113.09 240 117.333ZM82.1334 162.496L114.133 217.92C116.342 221.422 119.82 223.932 123.839 224.926C127.858 225.92 132.105 225.319 135.691 223.251C139.277 221.182 141.922 217.805 143.074 213.829C144.225 209.853 143.793 205.585 141.867 201.92L109.867 146.496C108.816 144.675 107.417 143.079 105.75 141.799C104.082 140.518 102.179 139.579 100.148 139.034C98.1179 138.489 96 138.35 93.9156 138.624C91.8312 138.897 89.8211 139.579 88.0001 140.629C86.1791 141.68 84.5829 143.079 83.3025 144.747C82.0222 146.414 81.0828 148.317 80.538 150.348C79.9933 152.378 79.8538 154.496 80.1275 156.581C80.4012 158.665 81.0829 160.675 82.1334 162.496Z"
                              />
                            </svg>
                          </div>
                          <div className="backdrop-blur-sm px-1 bg-black/40 dark:bg-white/40 w-12 h-5"></div>
                          <div className="flex gap-1 bg-[#564bab] text-sm px-1 last:rounded-r bg-black/40 dark:bg-white/40 w-12 h-5"></div>

                          <div className="flex gap-1 bg-[#a34bab] w-12 text-sm px-1 last:rounded-r bg-black/40 dark:bg-white/40 h-5"></div>
                        </div>
                      </div>

                      <div className="font-normal text-[#7c7c7c] truncate text-sm opacity-100 bg-black/40 dark:bg-white/40 w-full h-12 rounded"></div>
                      <div className="overflow-scroll font-normal text-[#828282] w-full text-xs lg:text-sm text-justify bg-black/40 dark:bg-white/40 h-10 rounded"></div>
                    </div>
                  </div>
                </a>
              </div>
            )}
          </div>

          <nav class="flex items-center gap-x-1">
            <button
              type="button"
              class="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-1.5 text-sm rounded-lg text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10"
              disabled=""
            >
              <svg
                class="flex-shrink-0 size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="m15 18-6-6 6-6"></path>
              </svg>
              <span>Previous</span>
            </button>
            <div class="flex items-center gap-x-1">
              <button
                type="button"
                class="min-h-[38px] min-w-[38px] flex justify-center items-center bg-gray-200 text-gray-800 py-2 px-3 text-sm rounded-lg focus:outline-none focus:bg-gray-300 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-600 dark:text-white dark:focus:bg-neutral-500"
                aria-current="page"
              >
                1
              </button>
              <button
                type="button"
                class="min-h-[38px] min-w-[38px] flex justify-center items-center text-gray-800 hover:bg-gray-100 py-2 px-3 text-sm rounded-lg focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10"
              >
                2
              </button>
              <button
                type="button"
                class="min-h-[38px] min-w-[38px] flex justify-center items-center text-gray-800 hover:bg-gray-100 py-2 px-3 text-sm rounded-lg focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10"
              >
                3
              </button>
            </div>
            <button
              type="button"
              class="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-1.5 text-sm rounded-lg text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10"
            >
              <span>Next</span>
              <svg
                class="flex-shrink-0 size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="m9 18 6-6-6-6"></path>
              </svg>
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};
